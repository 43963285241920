import { initializeApp } from "firebase/app";
import { getAuth, signOut , signInWithEmailAndPassword, createUserWithEmailAndPassword, updatePassword} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
    apiKey: "AIzaSyC5e68K1eLNIoLLJ7T6pqZ2pC3wOCkvR94",
    authDomain: "setcast-login.firebaseapp.com",
    projectId: "setcast-login",
    storageBucket: "setcast-login.appspot.com",
    messagingSenderId: "692303962310",
    appId: "1:692303962310:web:04ef96f01dd76aa607f7c7",
    measurementId: "G-5NHG8RZ36X"
};



const createUser = async (email, password) => {
  try {
    return await createUserWithEmailAndPassword(auth, email, password);
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;  // propagate the error up to the caller
  }
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
// Initialize Firestore
const db = getFirestore(app);

export { auth, db, signOut, signInWithEmailAndPassword, createUser, updatePassword };

