


import React, { useState, useEffect } from 'react';
import '../../App.css';
import { collection, addDoc, getDocs } from "firebase/firestore";
import { db } from './../../firebaseConfig';

function AddCompany() {
  const [companyName, setCompanyName] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [companies, setCompanies] = useState([]);

  // Fetch companies when component mounts and reloads
  useEffect(() => {
    const fetchCompanies = async () => {
      const querySnapshot = await getDocs(collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/company"));
      setCompanies(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };

    fetchCompanies();
  }, []);

  const addCompany = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/company"), {
        name: companyName,
        address: companyAddress,
        email: companyEmail,
        phone_number: companyPhone
      });
      console.log("Company added with ID: ", docRef.id);
      alert("Company added successfully");

      // Reset input fields
      setCompanyName('');
      setCompanyAddress('');
      setCompanyEmail('');
      setCompanyPhone('');

      // Reload the list of companies
      setCompanies([...companies, {
        id: docRef.id,
        name: companyName,
        address: companyAddress,
        email: companyEmail,
        phone_number: companyPhone
      }]);
    } catch (e) {
      console.error("Error adding company: ", e);
      alert("Error adding company");
    }
  }

  return (
    <section>
      <div className="content-wrapper">
        <h1>Add New Company</h1>
        <div style={{ marginTop: '30px', marginBottom: '80px' }}>
          <form onSubmit={addCompany}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '400px',
            }}>
            <input className='inputtext' type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} required placeholder='Company Name'/>
            <input className='inputtext' type="text" value={companyAddress} onChange={(e) => setCompanyAddress(e.target.value)}  placeholder='Company Address'/>
            <input className='inputtext' type="text" value={companyEmail} onChange={(e) => setCompanyEmail(e.target.value)}  placeholder='Company Email'/>
            <input className='inputtext' type="text" value={companyPhone} onChange={(e) => setCompanyPhone(e.target.value)}  placeholder='Company Phone Number'/>
            <div className='button-wrapper'>
              <button style={{ marginTop: '10px' }} className='big-button' type="submit">Add Company</button>
            </div>
          </form>
        </div>
        </div>

        {/* List of companies */}
        <div className='content-wrapper'>
          <h2 style={{ marginBottom: '50px' }} className='h2'>List of Companies</h2>
        </div>
        <div className='content-wrapper content-wrapper-talent-list'>
          <div className='list-tasks-header'>
            <div style={{ flexBasis: '500px' }} >Name</div>
            <div style={{ flexBasis: '200px' }} >Email</div>
            <div style={{ flexBasis: '300px' }} >Address</div>
            <div style={{ flexBasis: '300px' }} >Phone</div>
          </div>

          {companies.map(company => (
            <div key={company.id} className='list-tasks-item'>
              <div style={{ flexBasis: '500px' }} >{company.name}</div>
              <div style={{ flexBasis: '200px' }} >{company.address}</div>
              <div style={{ flexBasis: '300px' }} >{company.email}</div>
              <div style={{ flexBasis: '300px' }} >{company.phone_number}</div>
            </div>
          ))}
        </div>
    </section>
  );
}

export default AddCompany;
