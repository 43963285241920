import React from "react";
import closeButton from "./../images/close.svg";
import closebuttonred from "./../images/closered.svg";
import onhold from "./../images/onhold.svg";
import swap from "./../images/swap.svg";
import confirm from "./../images/confirm.svg";
import decline from "./../images/decline.svg";
import notesicon from "./../images/notes.svg";
import plus from "./../images/add.svg";
import shiftsAvailability from "./../images/availability.svg";
const Legend = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button
          style={{ marginTop: "10px", marginRight: "5px" }}
          className="close-button"
          onClick={onClose}
        >
          <img src={closeButton} alt="Close" className="edit-container" />
        </button>

        <div>
          <h2 style={{ marginRight: "50px" }}>Legend</h2>
          <div
            style={{
       display:'flex',
             
              flexDirection: "row",
        
            }}
          >
            <div style={{display:'flex' }} className="legend-content">
              <div className="legend-list">
                <div
                  className="legend-cycle"
                  style={{ backgroundColor: "#ADD8E6" }}
                >
                  {" "}
                </div>
                <div>booked/lano task created</div>
              </div>

              <div className="legend-list">
                <div
                  className="legend-cycle"
                  style={{ backgroundColor: "#15a326" }}
                >
                  {" "}
                </div>
                <div>confirmed</div>
              </div>
              <div className="legend-list">
                <div
                  className="legend-cycle"
                  style={{ backgroundColor: "#ffffff" }}
                >
                  {" "}
                </div>
                <div>new order</div>
              </div>
              <div className="legend-list">
                <div
                  className="legend-cycle"
                  style={{ backgroundColor: "#FF8806" }}
                >
                  {" "}
                </div>
                <div>requested</div>
              </div>
              <div className="legend-list">
                <div
                  className="legend-cycle"
                  style={{ backgroundColor: "#0060ff" }}
                >
                  {" "}
                </div>
                <div>1.optioned</div>
              </div>
              <div className="legend-list">
                <div
                  className="legend-cycle"
                  style={{ backgroundColor: "#FFFF00" }}
                >
                  {" "}
                </div>
                <div>option - released</div>
              </div>
              <div className="legend-list">
                <div
                  className="legend-cycle"
                  style={{ backgroundColor: "#ff3c00" }}
                >
                  {" "}
                </div>
                <div>declined request</div>
              </div>
              <div className="legend-list">
                <div
                  className="legend-cycle"
                  style={{ backgroundColor: "#800080" }}
                >
                  {" "}
                </div>
                <div>hold</div>
              </div>

              <div className="legend-list">
                <div
                  style={{
                    marginLeft: "3px",
                    borderRadius: "10px",
                    width: "25px",
                    height: "25px",
                    border: "1px solid",
                    marginRight: "10px",
                  }}
                >
                  {" "}
                </div>
                <div style={{ paddingLeft: "8px" }}>On model</div>
              </div>
              <div className="legend-list" style={{ marginTop: "3px" }}>
                <div
                  style={{
                    marginLeft: "3px",
                    borderRadius: "0px",
                    width: "25px",
                    height: "25px",
                    border: "1px solid",
                    marginRight: "10px",
                  }}
                >
                  {" "}
                </div>
                <div style={{ paddingLeft: "8px" }}>On product</div>
              </div>
            </div>
            <div style={{display:'flex'}} className="legend-content">
              <div className="legend-list" style={{ marginTop: "3px" }}>
                <img
                  src={closebuttonred}
                  alt="Close"
                  className="edit-container"
                />
                <div style={{ paddingLeft: "8px" }}>Cancel Order</div>
              </div>
              <div className="legend-list" style={{ marginTop: "3px" }}>
                <img src={swap} alt="Close" className="edit-container" />
                <div style={{ paddingLeft: "8px" }}>
                  Ask Another talent for this order
                </div>
              </div>
              <div className="legend-list" style={{ marginTop: "3px" }}>
                <img src={onhold} alt="Close" className="edit-container" />
                <div style={{ paddingLeft: "8px" }}>Put this order on Hold</div>
              </div>
              <div className="legend-list" style={{ marginTop: "3px" }}>
                <img src={confirm} alt="Close" className="edit-container" />
                <div style={{ paddingLeft: "8px" }}>Confirm this order</div>
              </div>
              <div className="legend-list" style={{ marginTop: "3px" }}>
                <img src={decline} alt="Close" className="edit-container" />
                <div style={{ paddingLeft: "8px" }}>Decline this order</div>
              </div>
              <div className="legend-list" style={{ marginTop: "3px" }}>
              <div style={{ backgroundColor: "#2ACEF1", opacity: 1, width: '70px',height: '25px' }}></div>
              <div style={{ paddingLeft: "8px" }}>Talent Available</div>
              </div>
              <div className="legend-list" style={{ marginTop: "3px" }}>
              <div style={{ backgroundColor: "#F12A5D", opacity: 1, width: '70px',height: '25px' }}></div>
              <div style={{ paddingLeft: "8px" }}>Talent Not Available</div>
              </div>
              <div className="legend-list" style={{ marginTop: "3px" }}>
                <img src={notesicon} alt="Close" className="edit-container" />
                <div style={{ paddingLeft: "8px" }}>Add Note</div>
              </div>
              <div className="legend-list" style={{ marginTop: "3px" }}>
                <img src={shiftsAvailability} alt="Close" className="edit-container" />
                <div style={{ paddingLeft: "8px" }}>Add Availability</div>
              </div>
              <div className="legend-list" style={{ marginTop: "3px" }}>
                <img src={plus} alt="Close" className="edit-container" />
                <div style={{ paddingLeft: "8px" }}>Create Order</div>
              </div>
              
              
             
             
            </div>
            
          </div>
          <div className="legend-content" style={{ marginTop: "50px", textAlign: "left" }}>
                Location:
                <div style={{ paddingTop: "5px", textAlign: "left" }}>
                  {" "}
                  B28 = Behringstrasse 28a, 22765 Hamburg
                </div>
                <div style={{ paddingTop: "5px", textAlign: "left" }}>
                  {" "}
                  TR4 = Tarpenring 4, 22419 Hamburg
                </div>
                <div style={{ paddingTop: "5px", textAlign: "left" }}>
                  {" "}
                  M= Holy-Allee 3, Metzingen , am Empfang melden
                </div>
                <div style={{ paddingTop: "5px", textAlign: "left" }}>
                  {" "}
                  OSB= Am Rosenkothen 2, 40880 Ratingen
                </div>
              </div>
        </div>
      </div>
    </div>
  );
};

export default Legend;
