import React, { useState } from 'react';
import { db, createUser } from './../firebaseConfig'; // Ensure correct path
import { collection, getDocs, doc, setDoc, query, where } from "firebase/firestore";

const TalentUserCreationForm = () => {
  const [isProcessing, setIsProcessing] = useState(false);

  const fetchUniqueEmails = async () => {
    setIsProcessing(true);
    const talentsCollectionRef = collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data");
    const snapshot = await getDocs(talentsCollectionRef);
    const talents = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    // Next step: create users and user data
    for (const talent of talents) {
      const email = talent.E_Mail;
      const userExists = await checkUserExists(email);
      if (!userExists) {
        await createUserWithEmail(email);
      }

    }
    
    setIsProcessing(false);
  };

  const checkUserExists = async (email) => {
    const userDataCollectionRef = collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData");
    const q = query(userDataCollectionRef, where("email", "==", email));
    const snapshot = await getDocs(q);
    return !snapshot.empty;
  };


  const createUserWithEmail = async (email) => {
    try {
      const password = "123456"; // Define a secure default or generate one
      const userCredential = await createUser(email, password); // Assumed to be correctly implemented
      const user = userCredential.user;
  
      // After successful user creation, create user data document in Firestore
      await createUserData(user.uid, email);
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  const createUserData = async (userId, email) => {
    const userDataRef = doc(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData", userId);
    const talentsCollectionRef = collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data");
    const q = query(talentsCollectionRef, where("E_Mail", "==", email));
    const snapshot = await getDocs(q);
    const talentIds = snapshot.docs.map(doc => doc.id);
  
    const userData = {
      email: email,
      role: "talent",
      talentIds: talentIds
    };
  
    await setDoc(userDataRef, userData);
  };

  return (
    <div>
      <button onClick={fetchUniqueEmails}>Fetch and Create Users</button>
      {isProcessing ? <p>Processing...</p> : <p>Process completed.</p>}
    </div>
  );
};

export default TalentUserCreationForm;
