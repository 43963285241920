import React from 'react';
import { Link } from 'react-router-dom';

const Dropdown = ({ menuItems, onMouseLeave }) => (
  <ul className="dropdown" onMouseLeave={onMouseLeave}>
    {menuItems.map((item, index) => (
      <li key={index}>
        <Link to={item.path} className='dropdown-link'>
          {item.title}
        </Link>
      </li>
    ))}
  </ul>
);

export default Dropdown;
