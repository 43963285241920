import { getFirestore, doc, getDoc } from "firebase/firestore";
import { auth, db } from  "../firebaseConfig"; // Update the path to match the location of your firebaseConfig.js

export const getCredentials = async () => {
  const db = getFirestore(auth.app);
  const docRef = doc(db, "SetCast CTA GmbH", "bIETrNIclVpe0eINr1Z1");

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
   // console.log("Document data:", data.login);
    return data;
  } else {
    console.log("No such document!");
    return null;
  }
};

export const authenticateAndGetToken = async () => {
  const credentials = await getCredentials();

  if (!credentials) {
    console.error("Credentials not found");
    return null;
  }

  const { login, password } = credentials;
  //console.log(password);
  const requestOptions = {
  
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams({
      grant_type: "password",
      username: login,
     password: password,
    }),
    redirect: "manual",
  };

  try {
    const response = await fetch(
      "https://api.lano.io/oauth/token",
      requestOptions
    );
    const result = await response.json();
    //console.log(result);
    return result.access_token;
  } catch (error) {
    console.error("Error fetching token:", error);
 //   return null;
  }
};
