import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import edit from './../../images/edit.svg';
import closeButton from './../../images/close.svg';

export default function LanoIds({ authToken }) {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [prev, setPrev] = useState(null);
  const [editTaskId, setEditTaskId] = useState(null); // State to track editing task ID
  const [editFormData, setEditFormData] = useState({}); // State to track form data

  const loadUserData = (pageUrl, currentData) => {
    fetch(pageUrl)
      .then((response) => response.json())
      .then((responseJson) => {
        // Update the current page number only if the 'meta' property exists
        if (Object.prototype.hasOwnProperty.call(responseJson, "meta")) {
          setCurrentPage(responseJson["meta"]["currentPage"]);
        }

        console.log(responseJson["data"]);
        setPrev(responseJson['links'].prev);
        // Update the data
        setData([...(currentData || []), ...responseJson["data"]]);
      })
      .catch((error) => console.error(error));
  };

  const handleEditClick = (freelancer) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json; charset=utf-8");
    myHeaders.append("Authorization", `Bearer ${authToken}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`https://api.lano.io/api/v1/tasks/${freelancer.id}`, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(result => {
        setEditTaskId(freelancer.id);
        setEditFormData({ ...result.data['attributes'] });
      })
      .catch(error => {
        console.log('error', error);
        // Handle error (e.g., show error message)
      });
  };

  const handleEditFormChange = (e) => {
    setEditFormData({ ...editFormData, [e.target.name]: e.target.value });
  };

  const handleUpdateTask = () => {
    const updatedTaskData = {
      title: editFormData.title,
      description: editFormData.description,
      starts_on: editFormData.startsOn,
      ends_on: editFormData.endsOn,
      assignee_id: editFormData.assigneeId,
      rate: editFormData.rate,
      state: editFormData.state,
      amount: editFormData.amount,
      // ... map other fields similarly ...
    };

    const requestOptions = {
      method: 'PATCH',
      headers: {
        "Content-Type": "application/json",
        "Accept": "any",
        "Authorization": `Bearer ${authToken}`
      },
      body: JSON.stringify({ task: updatedTaskData }),
      redirect: 'follow'
    };

    fetch(`https://api.lano.io/api/v1/tasks/${editTaskId}`, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(result => {
        console.log(result);
        // Close the popup
        setEditTaskId(null);
        // Optionally, refresh the task list or update local state to reflect changes
      })
      .catch(error => {
        console.log('error', error);
        // Handle error (e.g., show error message)
      });
    setEditTaskId(null);
  };

  const loadLastPage = () => {
    fetch(
      'https://api.lano.io/api/v1/company_freelancers?access_token=' +
      authToken +
      '&page[size]=1500'
    )
      .then((response) => response.json())
      .then((responseJson) => {
        // Extract the last page URL
        const lastPageUrl = responseJson['links']['first'];

        // Fetch data from the last page
        loadUserData(lastPageUrl);
      })
      .catch((error) => console.error(error));
  };

  const loadMore = () => {
    loadUserData(prev, data);
  };

  useEffect(() => {
    loadLastPage();
  }, []);

 // Sort data by freelancer.id in descending order
 const sortedData = data.sort((a, b) => b.id - a.id);

 const freelancerItems = sortedData.map((freelancer) => (
   <div className='list-tasks-item' key={freelancer.id}>
     <div style={{ flexBasis: '200px' }}>{freelancer.id}</div>
     <div style={{ flexBasis: '200px' }}>{freelancer.attributes.fullName}</div>
     <div style={{ flexBasis: '300px' }}>{freelancer.attributes.email}</div>
     <div style={{ flexBasis: '200px' }}>{freelancer.attributes.companyName || 'N/A'}</div>
     <div style={{ flexBasis: '100px' }}>{freelancer.attributes.isVendor ? 'Yes' : 'No'}</div>
     <div style={{ flexBasis: '100px' }}>{freelancer.attributes.hidden ? 'Yes' : 'No'}</div>
   </div>
 ));

  return (
    <>
      <section>
        <div className='content-wrapper'>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '60px' }}>
            <h1>All Freelancers</h1>
            <button className='big-button' onClick={() => loadLastPage()}>Refresh</button>
          </div>
        </div>
        <div className='content-wrapper content-wrapper-talent-list'>
          <div className='list-tasks-header'>
          <div style={{ flexBasis: '200px' }}>Lano ID</div>
            <div style={{ flexBasis: '200px' }}>Full Name</div>
            <div style={{ flexBasis: '300px' }}>Email</div>
            <div style={{ flexBasis: '200px' }}>Company Name</div>
            <div style={{ flexBasis: '100px' }}>Is Vendor</div>
            <div style={{ flexBasis: '100px' }}>Hidden</div>
          </div>
          <div style={{ marginBottom: "30px" }}>
            {freelancerItems}
          </div>
        </div>
        <div className='content-wrapper'>
          <button className='big-button' onClick={() => loadMore()}>Load More</button>
        </div>
      </section>

      
    </>
  );
}
