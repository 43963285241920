import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from './../../firebaseConfig';
import '../../App.css';

function CompanyList() {

  const [search, setSearch] = useState(""); // New state for search term
  const [companies, setCompanies] = useState([]);

  const [sortOrder, setSortOrder] = useState("asc");
  const [currentCompany, setCurrentCompany] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");

  // Extend the state to include all the fields
  const [currentEmail, setCurrentEmail] = useState('');
  const [currentName, setCurrentName] = useState('');
  const [currentAddress, setCurrentAddress] = useState('');
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('');
  
  const fetchCompanies = async () => {
    const companySnap = await getDocs(collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/company"));
    const companyData = companySnap.docs.map(doc => ({
      id: doc.id, 
      ...doc.data()
    }));
    setCompanies(companyData);
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const deleteCompany = async (id) => {
    await deleteDoc(doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/company", id));
    fetchCompanies();
  };

  const editCompany = (company) => {
    setCurrentCompany(company);
    setCurrentEmail(company.email);
    setCurrentName(company.name);
    setCurrentAddress(company.address);
    setCurrentPhoneNumber(company.phone_number);
  };

  const updateCompany = async () => {
    const { id } = currentCompany;
    const newData = {
      email: currentEmail,
      name: currentName,
      address: currentAddress,
      phone_number: currentPhoneNumber,
    };
    await updateDoc(doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/company", id), newData);
    setCurrentCompany(null);
    fetchCompanies();
  };

  const sortByName = () => {
    setCompanies([...companies].sort((a, b) => {
      return sortOrder ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
    }));
    setSortOrder(!sortOrder);  // Switch the sort order for the next click
  };

  return (
    <div className="App">
      <h1 className='h1-left'>Company List</h1>
      {currentCompany && (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '400px',
            margin: '0 10%',
          }}>
          <input className='inputtext'  value={currentName} onChange={(e) => setCurrentName(e.target.value)} placeholder='Name'/>
          <input className='inputtext'  value={currentAddress} onChange={(e) => setCurrentAddress(e.target.value)} placeholder='Address'/>
          <input className='inputtext'  value={currentEmail} onChange={(e) => setCurrentEmail(e.target.value)} placeholder='Email'/>
          <input className='inputtext'  value={currentPhoneNumber} onChange={(e) => setCurrentPhoneNumber(e.target.value)} placeholder='Phone Number'/>
          <div className='button-wrapper'>
          <button className='button'  onClick={updateCompany}>Update</button> 
          </div>
        </div>
      )}
   
      <input
        type="text"
        placeholder="Search..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className='inputtext search wrapper'
        />
  
        <button onClick={sortByName}>
          Sort by name
        </button>
  
        {companies
          .filter(company => company.name.toLowerCase().includes(search.toLowerCase())) // Filter companies as per search term
          .map(company => (
            <div key={company.id} style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '80%',
              margin: '20px auto',
              padding: '20px',
              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.15)',
            }}>
              <div>
                <h2>{company.name}</h2>
                <p>Email: {company.email}</p>
                <p>Address: {company.address}</p>
                <p>Phone: {company.phone_number}</p>
              </div>
              <div>
                <button className='button' onClick={() => editCompany(company)}>Edit</button>
                <button className='button' onClick={() => deleteCompany(company.id)}>Delete</button>
              </div>
            </div>
          ))
        }
      </div>
    );
  }
  
  export default CompanyList;
  