import React, { useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import RoleContext from "./RoleContext";
import Contactus from "./../components/pages/ContactUs";
import AddTalent from "../components/pages/AddTalent";
import { Outlet, useNavigate } from "react-router-dom";

// This component is responsible for protecting routes.
const ProtectedRoute = ({ allowedRoles }) => {
  const navigate = useNavigate();
  const userRole = useContext(RoleContext);

  // Check if user's role is included in the allowed roles for this route
  useEffect(() => {
    console.log("&&*****************");
    console.log(userRole);
    console.log("&&*****************");
    if (!allowedRoles.includes(userRole)) {
      // If user is not allowed, navigate to the unauthorized page
      if (userRole === "superadmin" || userRole === "setCastEditor") {
        navigate("/admin");
      } else if (
        userRole === "clientService" ||
        userRole === "editor" ||
        userRole === "planning"
      ) {
        navigate("/cs");
      } else if (userRole === "talent") {
        navigate("/talent");
      } else {
        navigate("/np");
      }
    }
  }, [userRole, navigate, allowedRoles]);

  // If user is allowed, render the component for this route
  return <Outlet />;
};

export default ProtectedRoute;
