import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { getFirestore, doc, getDoc } from "firebase/firestore";
import '../../App.css';

const LanoCreateATask = ({authToken}) => {
    const [code, setCode] = useState('');
    const [message, setMeddage] = useState(" ");
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [ studios, setStudios] = useState('');
  const [set, setSet]=   useState('');
  const [loading, setLoading] = useState(true);

  const [title, setTitle] = useState(' ');
  const [description, setDescription] = useState('');
  const [photograf, setPhotograf] = useState('');
  const [starts_on, setStarts_on] = useState('');
  const [ends_on, setEnds_on] = useState('');
  const [period, setPeriod] = useState('');

  const [talent, setTalent] = useState("");
  const [talentId, setTalentId] = useState(' ');
  const [rate, setRate] = useState(' ');
  const [costumer, setCostumer] = useState(' ');
 
  const [nextbtnStyle, setnextbtnStyle]=useState("btn btn-danger");


  var [allflerancer, setAllfreelancer] = useState([]);
  const [freelancers, setFreelancers] = useState([]);
  const [firstfreelancer, setFristFreeleancer] = useState("");
  const [selectedFreelancers, setSelectedFreelancers] = useState([]);

  const options = freelancers.map((freelancer) => ({
    value: freelancer.id,
    label: freelancer.name,
  }));


  const customStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      border: 'none',
    }),
    control: (base) => ({
      ...base,
      width: '400px',
      borderColor: 'black',
      borderWidth: '1px',
      boxShadow: 'none',
      margin: '5px',
      borderRadius: '0px',
      color: 'black', // Your color
      fontWeight: "700",
      height: '50px',
      textTransform: 'uppercase',

    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'black', // Your color
      fontWeight: "700"
    }),
    input: (provided) => ({
      ...provided,
      color: 'black', // Your color
      fontWeight: "700"
    }),
  };


  const handleChange = (selectedOptions) => {
    setSelectedFreelancers(selectedOptions);
  };
  function test() {
    const queryString = window.location.search;
    console.log("qyerystrilng" + queryString);
    const urlParams = new URLSearchParams(queryString);
 
    let titles = urlParams.get('title');
   
    let desc = urlParams.get('description');
    let talent = urlParams.get("talent");
    let talentid = urlParams.get("talentid");
    let costumer = urlParams.get("costumer");
    let rate = urlParams.get("rate");
    let startson =urlParams.get("starton")
    let endon = urlParams.get('endon');
    let period = urlParams.get('period');

    setTalent(talent);
    setTalentId(talentid);
    setCostumer(costumer);
    setRate(rate);
    setStarts_on(startson);
    setEnds_on(endon);
    setPeriod(period)


    setTitle(titles);
    setDescription(desc);
  
  }


  const changetitle = event => {
    setTitle(event.target.value);
  }
  const changefrelancer = event => {
    setFristFreeleancer(event.target.value);
  }

  const changefoto = event => {
    setPhotograf(event.target.value);
  }
  const changedec = event => {
    setDescription(event.target.value);
  }

  const alerts = () => {
    alert('name: ' + title + ' \n photograph: ');
  }
  useEffect(() => {
    test();
    fetchFreelancers();

   

     

    document.title = 'Add new Task';

  }, []);



  const create = async () => {

    // add entity - POST

    var myHeaders = new Headers();
    myHeaders.append("Authorization", " Bearer " + authToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Access-Control-Allow-Origin', 'http://dev-setcast.mondula.com/');
    myHeaders.append('Access-Control-Allow-Credentials', 'true');
console.log(description);
    var raw = JSON.stringify({
      "task": {
        "title": title,
        "description": description,
        "details": description,
        "starts_on":  "2022-12-28",
        "ends_on": "2022-12-28",
        "rate": 0.1,
        "duration_in_time_units": 80,
        "currency": "EUR",
        "budget_setting": "set_budget",
        "assignee_id": null,
        "rate_type_id": 1,
        "creator_id": 216149,
        "state": "freelancer_requested"
      }
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'manual'
    };

    fetch("https://api.lano.io/api/v1/tasks", requestOptions)
      .then(response => response.text())
      .then(result => { console.log(result); setMeddage(result); })
      .catch(error => console.log('error', error));

    /**/

    /**/

  }
  const fetchAllFreelancers = async (url, requestOptions, allFreelancers = []) => {
    const response = await fetch(url, requestOptions);
    const result = await response.json();
    const freelancers = result.data.map((freelancer) => ({
      id: freelancer.id,
      name: freelancer.attributes.fullName,
    }));
  
    allFreelancers.push(...freelancers);
  
    const nextPageUrl = result.links.next;
  
    if (nextPageUrl) {
      return fetchAllFreelancers(nextPageUrl, requestOptions, allFreelancers);
    } else {
      return allFreelancers;
    }
  };
  
  const fetchFreelancers = async () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", " Bearer " + authToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "http://dev-setcast.mondula.com/");
    myHeaders.append("Access-Control-Allow-Credentials", "true");
  
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "manual",
    };
  
    // Fetch freelancers using the given API endpoint and requestOptions
    const url = "https://api.lano.io/api/v1/company_freelancers";
    const allFreelancers = await fetchAllFreelancers(url, requestOptions);
   
   // console.log(allFreelancers['attributes'].fullName);
    setFreelancers(allFreelancers);
    setLoading(false);
    //printall();
  //  printFreelancers(allFreelancers);
   
  };
  const printFreelancers = (freelancers) => {
    freelancers.forEach((freelancer) => {
      console.log(`ID: ${freelancer.id}, Full Name: ${freelancer.name}`);
    });
  };

  var choosen = {};
  var choosenzero = [];
  const arr = [];
  allflerancer.forEach(element => {
    arr.push({ value: element.id, label: element.attributes.fullName })
  });

  //arr.push({value: allflerancer(i).id, label: allflerancer(i).id});

  const onchange = (value) => {
    choosen.push(value);
  }
  return (
    <div className="content-wrapper">
        <div style={{width:'100%', height:'50px'}}></div>
        <button className='button'onClick={() => fetchFreelancers()}>refresh..</button>
        <div className="">
          <h1 className="h1">Create New Task</h1>
          {title}
        
          <form    style={{

          width: '400px',


        }}>
            <legend className="text-center"></legend>
            <label className='labelform' >
            Task Benennung:
              <input
                name="title"
                id="name"
                type="text"
                className="inputtext"
                value={title }
               onChange={changetitle}
                required
              />
            </label>
            <label htmlFor="freelancers" className="labelform">
              Freelancers:
              {loading ? (
               <input
               name="loading"
              
               type="text"
               className="inputtext"
               value={" Loading..." }
             //  onChange={changetitle}
             
             />
            ) : (
              <Select
                id="freelancers"
                options={options}
                isMulti
                onChange={handleChange}
                value={selectedFreelancers}
                styles={customStyles}
              />
            )}
            </label>
            <label className='labelform' >
            Name Talent:
            
              <input
                name="titile"
                id="name"
                type="text"
                className="inputtext"
                value={talent}
              //  onChange={changetitle}
                required
              />
            </label>
            <label className='labelform' >
            Talent ID:
              <input
                name="titile"
                id="name"
                type="text"
                className="inputtext"
                value={selectedFreelancers}
              //  onChange={changetitle}
                required
              />
            </label>
            <label className='labelform' >
            Tagessatz:
              <input
                name="titile"
                id="name"
                type="text"
                className="inputtext"
                value={rate}
                onChange={rate}
                required
              />
            </label>
            <label htmlFor="name">
            Funktion:
              <input
                name="description"
                id="name"
                type="text"
                className="inputtext"
                value={description}
               onChange={changedec}
                required
              />
            </label>
            <label htmlFor="starts_on">
            Kunde:
              <input
                name="starts_on"
                id="name"
                type="text"
                className="inputtext"
                value={costumer}
               // onChange={changefoto}
                required
              />
            </label>
            <label htmlFor="ends_on">
            Zeitraum:
              <input
                name="ends_on"
                id="name"
                type="text"
                className="inputtext"
                value={period}
                //onChange={starts_on}
                required
              />
            </label>
            <label htmlFor="ends_on">
            Start Datum
              <input
                name="ends_on"
                id="name"
                type="text"
                className="inputtext"
                value={starts_on}
                //onChange={starts_on}
                required
              />
            </label>
            <label htmlFor="ends_on">
            End Datum
              <input
                name="ends_on"
                id="name"
                type="text"
                className="inputtext"
                value={ends_on}
                //onChange={starts_on}
                required
              />
            </label>
           
         

           


          </form>
          <button className='button' type='button' onClick={create} >
        Create Task
      </button>
      {message}
        </div>

      
   
    </div>
  )
}

export default LanoCreateATask;
