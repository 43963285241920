import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdown from './../Dropdown';
import { signOut, auth } from './../../firebaseConfig';
import Legend from '../Legend';
import LegendTalents from '../LegendTalents';
import NotificationPanel from './../NotificationPanel';
import bell from './../../images/bell.svg'
import bellactive from './../../images/bell-active.svg'
import tobslogo from './../../images/tobs-txt.svg'

const bokking = [
  { title: 'New Booking', path: '/talent/contact-us' },
  // add more items here...
];

const talent = [
  { title: 'Talent List', path: '/talent/talent-list' },
  // add more items here...
];

const company = [
  { title: 'add Company', path: '/talent/add-company' },
  // add more items here...
];


function TalnetNavBar() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdowntalent, setDropdowntalent] = useState(false);
  const [dropdowncompany, setDropdowncompany] = useState(false);

  const toggleMobileMenu = () => setClick(!click);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        // ...
        localStorage.setItem("userRole", null); //
      })
      .catch((error) => {
        // An error occurred.
        console.error("Logout error:", error);
      });
  }
  const [showNotifications, setShowNotifications] = useState(false);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);


  const handleNotificationsRead = (areUnreadNotifications) => {
      setHasUnreadNotifications(areUnreadNotifications);
    };

  return (
    <>
      <div className='black-bg'>
        <div className='legend-button-area'>
          <button className='legend-button' onClick={() => setIsModalOpen(true)} style={{ cursor: "pointer", backgroundColor: "#121212", border: "none", margin: 0, paddingTop: "2px" }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 309.52 699.16" width="8px" height="auto">
            <path class="st0" d="M140.39,151.32c39.76,0,73.41-32.62,73.41-73.39S180.15,4.55,140.39,4.55c-39.73,0-73.36,32.62-73.36,73.39
                C67.02,118.7,100.66,151.32,140.39,151.32z" fill="#ffffff"/>
            <path class="st0" d="M254.55,625.26c-28.52,0-40.76-11.21-40.76-40.77V215.52H5.86v67.28h46.87c28.55,0,40.79,11.21,40.79,40.77
                v260.92c0,29.56-12.24,40.77-40.79,40.77H5.86v67.27h298.63v-67.27H254.55z" fill="#ffffff" /></svg>
            </button>
            <LegendTalents isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </div>
        <div className='content-wrapper'>
          <nav className='navbar' onMouseLeave={() => { setDropdowncompany(false); setDropdowntalent(false); setDropdown(false) }}>
            <Link to='/talent/' className='navbar-logo' onClick={closeMobileMenu}>
            <img style={{height:60}} src={tobslogo} />

            </Link>
            <div className='menu-icon' onClick={toggleMobileMenu}>
                  {click ?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.1 59.2" width="20px" height="auto">
                      <path d="m1.6,57.7c1,1,2.3,1.5,3.5,1.5s2.6-.5,3.5-1.5l21-21,21,21c1,1,2.3,1.5,3.5,1.5s2.6-.5,3.5-1.5c2-2,2-5.1,0-7.1l-21-21,21-21c2-2,2-5.1,0-7.1s-5.1-2-7.1,0l-21,21L8.6,1.7C6.6-.3,3.5-.3,1.5,1.7s-2,5.1,0,7.1l21,21L1.6,50.7c-2,1.9-2,5.1,0,7Z" fill="#ffffff"/>
                    </svg>
                  :
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.37 12.54" width="26px" height="auto">
                      <path d="M1.16,2.28h14c0.56,0,1-0.45,1-1c0-0.56-0.45-1-1-1h-14c-0.56,0-1,0.45-1,1C0.16,1.83,0.61,2.28,1.16,2.28z" fill="#ffffff"/>
                      <path d="M15.16,10.28h-14c-0.56,0-1,0.45-1,1c0,0.56,0.45,1,1,1h14c0.56,0,1-0.45,1-1C16.16,10.72,15.72,10.28,15.16,10.28z" fill="#ffffff"/>
                      <path d="M15.16,5.28h-14c-0.56,0-1,0.45-1,1c0,0.56,0.45,1,1,1h14c0.56,0,1-0.45,1-1C16.16,5.72,15.72,5.28,15.16,5.28z" fill="#ffffff"/>
                    </svg>
                  }
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              <li className='nav-item' style={{ marginRight: '25px' }}>
                <Link to='/talent/profile'>
                  <button onClick={closeMobileMenu} style={{ cursor: "pointer", padding: 5, backgroundColor: "#121212", border: "none", margin: 0, height: "24px", paddingTop: 2, fontSize: 18, fontWeight: 900 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.6 92.6" width="21px" height="auto"><circle cx="46.4" cy="35.9" r="16.7" transform="translate(-5.15 7.9) rotate(-9.22)" fill="#ffffff" stroke-width="0" /><path d="m46.3,0C20.8,0,0,20.7,0,46.3s20.8,46.3,46.3,46.3,46.3-20.8,46.3-46.3S71.8,0,46.3,0Zm27.2,75.5c-3.4-9.1-10.8-18.8-27.2-18.8s-23.8,9.7-27.2,18.8c-7.8-7.3-12.8-17.7-12.8-29.2C6.3,24.3,24.2,6.3,46.3,6.3s40,17.9,40,40c0,11.5-5,21.9-12.8,29.2Z" fill="#ffffff" stroke-width="0" /></svg>
                  </button>
                </Link>
                <p className='user-greeting' style={{ color: "#ffffff", margin: "0 5px" }}> Hello {localStorage.getItem("userEmail")}! </p>
                <button style={{ cursor: "pointer", padding: 5, backgroundColor: "#121212", border: "none", margin: 0, height: "24px", paddingTop: 2, fontSize: 18, fontWeight: 900 }} onClick={handleLogout}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198.4 198.5" width="20px" height="auto">
                    <path d="m197.8,102.2c.2-.7.4-1.4.4-2.1,0-.3.2-.5.2-.8s-.1-.5-.2-.8c-.1-.7-.2-1.4-.4-2.1-.2-.6-.4-1.2-.7-1.7-.2-.3-.2-.6-.4-.9l-19.8-29.8c-.2-.3-.4-.6-.6-.9-3.3-4.4-9.5-5.3-13.9-2-4.4,3.3-5.3,9.5-2,13.9l9.6,14.4h-60.9c-5.5,0-9.9,4.4-9.9,9.9s4.4,9.9,9.9,9.9h60.9l-9.6,14.4c-1.1,1.6-1.6,3.5-1.6,5.4,0,5.5,4.4,9.9,9.9,10,3.3,0,6.4-1.6,8.2-4.4l19.8-29.8v-.1c.2-.3.3-.6.4-.9.4-.6.6-1.1.7-1.6Z" fill="#ffffff" stroke-width="0" />
                    <path d="m138.9,125.9c-5.5,0-9.9,4.4-9.9,9.9v32.8c0,5.5-4.4,9.9-9.9,9.9H29.8c-5.5,0-9.9-4.4-9.9-9.9V29.8c0-5.5,4.4-9.9,9.9-9.9h89.3c5.5,0,9.9,4.4,9.9,9.9v27.9c0,5.5,4.4,9.9,9.9,9.9s9.9-4.4,9.9-9.9v-27.9c0-16.4-13.3-29.8-29.8-29.8H29.8C13.3,0,0,13.3,0,29.8v138.9c0,16.4,13.3,29.8,29.8,29.8h89.3c16.4,0,29.8-13.3,29.8-29.8v-32.8c-.1-5.6-4.5-10-10-10Z" fill="#ffffff" stroke-width="0" /></svg>
                </button>
              </li>

              <li className='nav-item'>
                <div style={{ display: 'inline-block' }} onClick={closeMobileMenu}>
                  <Link
                    to='/talent/orders'
                    className='nav-links'

                    style={{ display: 'flex', alignItems: 'center' }}
                  >

                    Projects
                  </Link>
                </div>

              </li>
              <li className='nav-item'>
                <div style={{ display: 'inline-block' }} onClick={closeMobileMenu}>
                  <Link
                    to='/talent/my-invitations'
                    className='nav-links'

                    style={{ display: 'flex', alignItems: 'center' }}
                  >

                    Requests
                  </Link>
                </div>

              </li>
              <li className='nav-item'>
                <div style={{ display: 'inline-block' }} onClick={closeMobileMenu}>
                  <Link
                    to='/talent/vacation'
                    className='nav-links'

                    style={{ display: 'flex', alignItems: 'center' }}
                  >

                    SCHEDULE
                  </Link>
                </div>
              </li>
            </ul>
            <div className='bell-container'>
            <button
                className='notification-bell'
                style={hasUnreadNotifications ? { width: '20px' } : { width: '17px' }}
                onClick={() => setShowNotifications(!showNotifications)}>
                <img src={hasUnreadNotifications ? bellactive : bell} alt="Notifications" />
            </button>

              <div className={`notification-canvas ${!showNotifications ? 'hidden' : ''}`}>
                <NotificationPanel userId={localStorage.getItem("userEmail")} userRole={localStorage.getItem("userRole")} onNotificationsRead={handleNotificationsRead} />

              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default TalnetNavBar;
