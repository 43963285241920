// styleUtils.js
// how to use : import { getBorderRadius, customStyles } from './../../helpers/StyleUtils';
//    const borderRadius = getBorderRadius(event.set);
// 


// Function to get border radius
export const getBorderRadius = (set) => {
    switch (set) {
        case 'On Model':
            return '10px';
        case 'on Produkt':
            return '0px';
        default:
            return 'default-radius'; // You can define a default value or return null
    }
};

// Custom styles for select components
export const customStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      border: 'none',
    }),
    control: (base) => ({
      ...base,
      maxWidth: '400px',
      borderColor: 'black',
      borderWidth: '1px',
      boxShadow: 'none',
      margin: '5px',
      borderRadius: '0px',
      color: 'black',
      fontWeight: "700",
      height: 'auto',
      textTransform: 'uppercase',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'black',
      fontWeight: "700"
    }),
    input: (provided) => ({
      ...provided,
      color: 'black',
      fontWeight: "700"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'black' : 'inherit',
      '&:hover': { backgroundColor: state.isSelected ? 'black' : '#F7F7F7' }
    }),
};

// Custom styles for select components with width
export const selector = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    border: 'none',
  }),
  control: (base) => ({
    ...base,
    width: '400px',
    borderColor: 'black',
    borderWidth: '1px',
    boxShadow: 'none',
    margin: '5px',
    borderRadius: '0px',
    color: 'black',
    fontWeight: "700",
    height: 'auto',
    textTransform: 'uppercase',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'black',
    fontWeight: "700"
  }),
  input: (provided) => ({
    ...provided,
    color: 'black',
    fontWeight: "700"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'black' : 'inherit',
    '&:hover': { backgroundColor: state.isSelected ? 'black' : '#F7F7F7' }
  }),
};
