import React, { useState, useMemo, useEffect } from 'react';
import { collection, getDocs, query, where, updateDoc, doc, getDoc, addDoc, Timestamp } from "firebase/firestore";
import { useLocation } from 'react-router-dom';
import Select, { components } from 'react-select';
import moment from 'moment';
import confirmIcon from '../../../images/confirm.svg';
import declineIcon from '../../../images/closered.svg';
import pauseIcon from '../../../images/onhold.svg';
import swapIcon from '../../../images/swap.svg';
import { auth, db } from './../../../firebaseConfig';
import { customStyles } from './../../../helpers/StyleUtils';
const ListViewProposed = ({ events, holdevents, authToken }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedKPI, setSelectedKPI] = useState("");
  const [selectedGrup, setSelectedGrup] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedSet, setSelectedSet] = useState("");
  const processedGroups = new Set();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  let userEmail=localStorage.getItem("userEmail");
  const [filterPreferences, setFilterPreferences] = useState([]);
  const [selectedPreferenceIndex, setSelectedPreferenceIndex] = useState(null); 
  const combinedEvents = [...events, ...holdevents];

  useEffect(() => {
      const fetchPreferences = async () => {
          if (!userEmail) return;

          const usersRef = collection(db, `/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData`);
          const q = query(usersRef, where("email", "==", userEmail));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
              const userData = querySnapshot.docs[0].data();
              const userPrefs = userData.filterPreferences;
              if (userPrefs) {
                  setFilterPreferences(userPrefs);
              }
          } else {
              console.log("No user or preferences found.");
          }
      };

      fetchPreferences();
  }, [userEmail]);
  const saveFilterPreference = async (preferenceIndex) => {
      const newPreference = {
          searchTerm,
     
          selectedClient,
          selectedPosition,
          selectedSet,
          selectedLocation,
          // Add other filters as needed
      };

      // Determine whether to update an existing preference or add a new one
      if (typeof preferenceIndex === 'number') {
          if (preferenceIndex < filterPreferences.length) {
              filterPreferences[preferenceIndex] = newPreference;
          }
      } else if (filterPreferences.length < 3) {
          filterPreferences.push(newPreference);
      } else {
          alert('You can only save up to 3 filter preferences.');
          return;
      }

      // Fetch the user's document reference again to ensure we have the correct document to update
      const usersRef = collection(db, `/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData`);
      const q = query(usersRef, where("email", "==", userEmail));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
          const userDocRef = querySnapshot.docs[0].ref; // Document reference
          // Update the document with the new or updated preferences
          try {
              await updateDoc(userDocRef, { filterPreferences: filterPreferences });
              setFilterPreferences([...filterPreferences]); // Update local state
              alert('Filter preferences saved successfully.');
          } catch (error) {
              console.error('Error saving preferences:', error);
              alert('Failed to save filter preferences.');
          }
      } else {
          console.log("User document not found.");
      }
  };
  const applyFilterPreference = (index) => {
      if (index < 0 || index >= filterPreferences.length) {
          console.log("Invalid filter preference index.");
          return;
      }

      const preference = filterPreferences[index];
      setSearchTerm(preference.searchTerm);
    
  
      setSelectedClient(preference.selectedClient);
      setSelectedPosition(preference.selectedPosition);
      setSelectedSet(preference.selectedSet);
      setSelectedLocation(preference.selectedLocation);
      // Apply other filters as needed
  };

  const preferenceOptions = filterPreferences.map((_, index) => ({ value: index, label: `Preference ${index + 1}` }));
  const handlePreferenceChange = selectedOption => {
      setSelectedPreferenceIndex(selectedOption ? selectedOption.value : null);
      if (selectedOption) {
          applyFilterPreference(selectedOption.value);
      } else {
          // Clear all filter preferences here
          setSearchTerm("");

      
          setSelectedClient(null);
          setSelectedPosition(null);
          setSelectedSet(null);
          setSelectedLocation(null);
          // Reset any other filter states back to their initial values here
      }
  };
  

  const saveOrUpdateFilterPreference = async () => {
      const newPreference = {
          searchTerm,
  
          selectedClient,
          selectedPosition,
          selectedSet,
          selectedLocation,
          // Add other filter settings as needed
      };
  
      if (selectedPreferenceIndex !== null) {
          // Update an existing preference
          filterPreferences[selectedPreferenceIndex] = newPreference;
      } else {
          // Save a new preference if there's room
          if (filterPreferences.length < 3) {
              filterPreferences.push(newPreference);
          } else {
              alert('You can only save up to 3 filter preferences.');
              return;
          }
      }
  
      // Fetch the user's document reference again to ensure we have the correct document to update
      const usersRef = collection(db, `/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData`);
      const q = query(usersRef, where("email", "==", userEmail));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
          const userDocRef = querySnapshot.docs[0].ref; // Document reference
          // Update the document with the new or updated preferences
          try {
              await updateDoc(userDocRef, { filterPreferences: filterPreferences });
              setFilterPreferences([...filterPreferences]); // Update local state
              alert('Filter preferences updated successfully.');
          } catch (error) {
              console.error('Error updating preferences:', error);
              alert('Failed to update filter preferences.');
          }
      } else {
          console.log("User document not found.");
      }
  };
  
  
  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  const fetchBooking = async (bookingId) => {
    const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", bookingId);
    const bookingDoc = await getDoc(bookingRef);

    if (!bookingDoc.exists) {
      console.error("Booking not found");
      return null;
    }

    return bookingDoc.data();
  };




  // Fetch talent based on talentId
  const fetchTalent = async (talentId) => {
    const talentRef = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data', talentId);
    const talentDoc = await getDoc(talentRef);

    if (!talentDoc.exists) {
      console.error("Talent not found");
      return null;
    }

    return talentDoc.data();
  };
  const acceptporposedtalent = async (bookingId, groupId) => {
    const userConfirmed = window.confirm("Are you sure you want to confirm?");

    const booking = await fetchBooking(bookingId);
    const talent = await fetchTalent(booking.talentId);

    if (userConfirmed) {
      const bookingsToUpdate = groupId ?
      combinedEvents.filter(event => event.group === groupId) :
      combinedEvents.filter(event => event.bookingId === bookingId);
console.log(bookingsToUpdate);
      for (const booking of bookingsToUpdate) {
        try {
          const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", booking.id);
          const bookinginfo = await fetchBooking(booking.id);
         await updateDoc(bookingRef, {
            status: 'confirmed',  // Please note: 'confirmed' seems like a typo, it should be 'confirmed'
          });
          await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications"),
            {
              userId: booking.proposedBy,
              message: `Planing has confirmed talent  ${talent.Name} for  ${bookinginfo.client.label}`,
              read: false,
              bookingId: bookingId,
              url: 'admin/detail?id=' + bookingId,
              createdAt: Timestamp.now()
            }

          )

          // createTask(booking, talent);
          //  confirmed(booking, talent);
          //confirmed(booking, talent);
        } catch (error) {
          console.error("Error updating booking: ", error);
        }
      }
      alert("Booking updated successfully.");
      window.location.reload();
    } else {
      console.log("You have cancelled the operation.");
    }
  
  }



  const hold = async (bookingId, groupId) => {
    const userConfirmed = window.confirm("Are you sure you want to Hold?");

    const booking = await fetchBooking(bookingId);
    const talent = await fetchTalent(booking.talentId);

    if (userConfirmed) {
      const bookingsToUpdate = groupId ?
      combinedEvents.filter(event => event.group === groupId) :
      combinedEvents.filter(event => event.bookingId === bookingId);

      for (const booking of bookingsToUpdate) {
        try {
          const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", booking.id);
          await updateDoc(bookingRef, {
            status: 'hold',  // Please note: 'confirmed' seems like a typo, it should be 'confirmed'
          });
          await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications"),
            {
              userId: booking.proposedBy,
              message: `Planing has decited to hold this order for`,
              read: false,
              bookingId: bookingId,
              url: 'admin/detail&id=' + bookingId,
              createdAt: Timestamp.now()
            }

          )         // createTask(booking, talent);
          //  confirmed(booking, talent);
          //confirmed(booking, talent);


        } catch (error) {
          console.error("Error updating booking: ", error);
        }
      }
      alert("Booking updated successfully.");
      window.location.reload();
    } else {
      console.log("You have cancelled the operation.");
    }
  }



    const askAnotherTalent = async (bookingId, groupId) => {
      const userConfirmed = window.confirm("Are you sure you want to ask for another talent?");
    
      if (userConfirmed) {
        const booking = await fetchBooking(bookingId);
        const talent = await fetchTalent(booking.talentId); // Assuming this is necessary for further logic not shown here.
        console.log(booking);
        console.log('thisis allbokingdata')
        const bookingsToUpdate = groupId
          ? combinedEvents.filter(event => event.group === groupId)
          : combinedEvents.filter(event => event.bookingId === bookingId);
    
        const updatePromises = bookingsToUpdate.map(async (booking) => {
          try {
            console.log(booking);
        console.log('thisis allbokingdata')
            const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", booking.id);
            await updateDoc(bookingRef, { status: 'new order', oldTalend:talent.Name, swap:true });
    
            await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"), {
              status: 'talentOnHold',
              talentId: booking.talentId,
              start_date: booking.start_date,
              end_date: booking.start_date,
              title: booking.title,
              kpi: booking.kpi,
              set: booking.set,
              position: {value: booking.position, label: booking.position },
              client: {value:'Set Cast 1. Option', label:'Set Cast 1. Option'},
              range: booking.range? booking.range : '', 
              costcenter1: ' ',
              costcenter2: ' ',
              option: booking.option,
              createdBy: booking.createdBy,
              proposedBy: booking.proposedBy,
              createdAt: Timestamp.now(),
              startTime: booking.startTime,
              endTime: booking.endTime,
              group:null,
            });
    
            await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications"), {
              userId: booking.proposedBy,
              message: `Planning has asked for another talent for ${booking.client.label}`,
              read: false,
              bookingId: bookingId,
              url: 'admin/detail?id=' + bookingId,
              createdAt: Timestamp.now(),
            });
          } catch (error) {
            console.error("Error updating booking: ", error);
            throw new Error("Failed to update all bookings"); // Rethrowing error to catch it in Promise.all catch block
          }
        });
    
        Promise.all(updatePromises)
          .then(() => {
            alert("Booking updated successfully.");
            window.location.reload();
          })
          .catch((error) => {
            console.error("Failed to update one or more bookings: ", error);
            // Handle the error appropriately here, maybe notify the user to try again.
          });
      } else {
        console.log("You have cancelled the operation.");
      }
    };
    




  const declineproposedtalent = async (bookingId, groupId) => {
    const userConfirmed = window.confirm("Are you sure you want to reject this order?");
    const booking = await fetchBooking(bookingId);
    const talent = await fetchTalent(booking.talentId);

    if (userConfirmed) {
      console.log('userconfrimed')
      const bookingsToUpdate = groupId ?
      combinedEvents.filter(event => event.group === groupId) :
      combinedEvents.filter(event => event.bookingId === bookingId);
 console.log(bookingsToUpdate);
      for (const booking of bookingsToUpdate) {
        // Update existing booking
        console.log('first for' +booking);
        try {
          const bookingRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings", booking.id);
          const bookinginfo = await fetchBooking(booking.id);
          await updateDoc(bookingRef, {

            status: 'optionSolved',
          });
          console.log('done');
          await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications"),
            {
              userId: booking.proposedBy,
              message: `Planing has canceled the talent ${talent.Name} for  ${bookinginfo.client.label}`,
              read: false,
              bookingId: bookingId,
              url: 'admin/detail?id=' + bookingId,
              createdAt: Timestamp.now()
            }

          )

         await createmaildeclined(bookinginfo, talent);


        } catch (error) {
          console.error("Error updating booking: ", error);
        }
      }

      alert("Booking declined successfully.");
      window.location.reload();
    } else {
      console.log("You have cancelled the operation.");
    }

  }

  const createmailcomfirmed = async (filteredBooking, talent, start) => {

    const msg = {
      to: 'booking@set-cast.com, talent@mondula.com,',
      message: {
        subject: 'Planning Orendt has accepted the booking ',
        text: ` ${JSON.stringify(filteredBooking)}`,
        html: `
      Dear  Team,
      
      
      <p>Planning Orendt has accepted the booking and the Order has been confirmed</p>
    
   
      <p>  Start Date:    <span>${new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  End Date:   <span>${new Date(filteredBooking.end_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  Freelancer:  ${talent.name} </p>
      <p>  Position:  ${filteredBooking.position.label} </p>
      <p>  kpi:  ${filteredBooking.kpi} </p>
   

      <p> 
      Check all the booking in <a href="https://dev.app.set-cast.com/">https://dev.app.set-cast.com/ </a>
     
      
      
      </p>`,
      },
    };

    await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
  };


  const createmailtalentcomfirmed = async (filteredBooking, talent, start) => {

    const msg = {
      to: ' booking@set-cast.com, talent@mondula.com,' ,
      message: {
        subject: 'Planning Orendt has accepted the booking ',
        text: `${JSON.stringify(filteredBooking)}`,
        html: `
      Dear  ${talent.Name} ,
      
      
      <p>Planning Orendt has accepted the booking and you are booked for:</p>
    
   
      <p>  Start Date:    <span>${new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  End Date:   <span>${new Date(filteredBooking.end_date.seconds * 1000).toLocaleDateString()}</span>
      <p>  Freelancer:  ${talent.Name} </p>
      <p>  Position:  ${filteredBooking.position.label} </p>
      <p>  kpi:  ${filteredBooking.kpi} </p>
   

      <p> 
      Check all the booking in <a href="https://dev.app.set-cast.com/">https://dev.app.set-cast.com/ </a>
     
      
      
      </p>`,
      },
    };

    await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
  };





  const createmaildeclined = async (filteredBooking, talent, start) => {

  // Convert Firebase Timestamps to Date objects
  const bookingStartDate = new Date(filteredBooking.start_date.toDate());
  const bookingEndDate = new Date(filteredBooking.end_date.toDate());
  // Format the dates
  const startDateFormatted = bookingStartDate.toLocaleDateString();
  const endDateFormatted = bookingEndDate.toLocaleDateString();
  
    const msg = {
      to:  ' booking@set-cast.com, talent@mondula.com' ,
      message: {
        subject: 'Planning Orendt has declined the booking ',
        text: `${JSON.stringify(filteredBooking)}`,
        html: `
      Dear  Team,
      
      
      <p>Planning Orendt has declined the booking!</p>
    
   
      <p>  Start Date:    <span>${startDateFormatted}</span>
      <p>  End Date:   <span>${endDateFormatted}</span>
      <p>  Freelancer:  ${talent.Name} </p>
      <p>  Position:  ${filteredBooking.position.label} </p>
      <p>  kpi:  ${filteredBooking.kpi} </p>
   

      <p> 
      Check all the booking in <a href="https://dev.app.set-cast.com/">https://dev.app.set-cast.com/ </a>
     
      
      
      </p>`,
      },
    };

    await addDoc(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/mail"), msg);
  };


  function getWeekNumber(d) {
    // Copy date so don't modify the original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));

    // Get first day of the year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);

    return weekNo;
  }

  const createTask = async (booking, talent) => {
    console.log('create called' + authToken)
    // add entity - POST
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + authToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Access-Control-Allow-Origin', 'http://dev-setcast.mondula.com/');
    myHeaders.append('Access-Control-Allow-Credentials', 'true');
    const kw = 'KW' + getWeekNumber(new Date(booking.start_date.seconds * 1000)) + " ";
    var titile = kw + '_' + booking.client.label + '_' + booking.position.label + '_' + new Date(booking.start_date.seconds * 1000).toLocaleDateString().split('T')[0] + '-' + new Date(booking.end_date.seconds * 1000).toLocaleDateString().split('T')[0];

    // Replace hardcoded data with the booking details
    var raw = JSON.stringify({
      "task": {
        "title": titile,
        "description": booking.description || ' ',
        "details": booking.description || ' ',
        "starts_on": new Date(booking.start_date.seconds * 1000).toLocaleDateString().split('T')[0],
        "ends_on": new Date(booking.end_date.seconds * 1000).toLocaleDateString().split('T')[0],
        "rate": talent.Tagessatz,
        "duration_in_time_units": booking.range,
        "currency": "EUR",

        "budget_setting": "set_budget",
        "assignee_id": talent.Lano_ID,
        "rate_type_id": 1,
        "creator_id": 216149,
        "state": "freelancer_assigned",
        "task_cost_centers_attributes": [
          {
            "cost_center_1_id": booking.costcenter1.id,
            "cost_center_2_id": booking.costcenter2.id
          }
        ]
      }
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'manual'
    };

    fetch("https://api.lano.io/api/v1/tasks", requestOptions)
      .then(response => response.text())
      .then(result => { console.log(result); /* setMeddage(result); */ }) // Typo fix: it should probably be setMessage
      .catch(error => console.log('error', error));
  }




  const renderButtons = (event) => {
    const isGroupProcessed = event.group && processedGroups.has(event.group);
    if (!isGroupProcessed) {
      if (event.group) {
        processedGroups.add(event.group);
      }

      return (<>
        <a className='action-button' onClick={() => acceptporposedtalent(event.bookingId, event.group)} style={{ marginRight: '10px' }}>
          <img src={confirmIcon} alt="Accept" title="Accept order" className="action-container" />
        </a>
        <a className='action-button' style={{marginLeft:20}} onClick={() => askAnotherTalent(event.bookingId, event.group)}>
          <img style={{ width: '26px' }} src={swapIcon} alt="requestAnotherTalent" title="Swap talent" className="action-container" />
        </a>
        <a className='action-button' onClick={() => hold(event.bookingId, event.group)} style={{ marginRight: '10px' }}>
          <img src={pauseIcon} alt="hold" title="Put on hold" className="action-container" />
        </a>
       
        <a  style={{paddingLeft:'10px'}} className='action-button' title="Cancel this order" onClick={() => declineproposedtalent(event.bookingId, event.group)} style={{ marginRight: '10px' }}>
          <img src={declineIcon} style={{color:'red'}} alt="CancelOrder" title="Decline order" className="action-container" />
        </a>

      </>);
    }
    return null;
  };


  const renderButtonsforHold = (event) => {
    const isGroupProcessed = event.group && processedGroups.has(event.group);
    if (!isGroupProcessed) {
      if (event.group) {
        processedGroups.add(event.group);
      }

      return (<>
        <a className='action-button' onClick={() => acceptporposedtalent(event.bookingId, event.group)} style={{ marginRight: '10px' }}>
          <img src={confirmIcon} alt="Accept" title="Accept order" className="action-container" />
        </a>
        
        <a className='action-button' style={{marginLeft:20}} onClick={() => askAnotherTalent(event.bookingId, event.group)} >
          <img style={{ width: '26px' }} src={swapIcon} alt="requestAnotherTalent" title="Swap talent" className="action-container" />
        </a>
        <a style={{paddingLeft:'10px'}} className='action-button' title="Cancel this order" onClick={() => declineproposedtalent(event.bookingId, event.group)} style={{ marginRight: '10px' }}>
          <img src={declineIcon} alt="CancelOrder" title="Decline order" className="action-container" />
        </a>

       

      </>);
    }
    return null;
  };

  const handleKPIChange = option => {
    setSelectedKPI(option.value);
  };
  const handleGroupChange = option => {
    setSelectedGrup(option.value);
  };


  const [selectedDateRange, setSelectedDateRange] = useState({ start: null, end: null });
  const getUniqueKPIs = () => {
    const kpis = new Set();
    events.forEach(event => {

      kpis.add(event.kpi);

    });
    return Array.from(kpis).map(kpi => ({ label: kpi, value: kpi }));
  };

  const gerUniqueGroup = () => {
    const groups = new Set();
    events.forEach(event => {

      groups.add(event.group);

    });
    return Array.from(groups).map(group => ({ label: group, value: group }));
  };

  const getClients = () => {
    const kpis = new Set();
    events.forEach(event => {

      kpis.add(event.client);

    });
    return Array.from(kpis).map(client => ({ label: client, value: client }));
  };

  const clientoption = getClients();
  const uniqueKPIs = getUniqueKPIs();
  const uniqueGroup=gerUniqueGroup();

  const set = [


    { value: 'On Model', label: 'On Model' },
    { value: 'On Produkt', label: 'On Produkt' },



  ];
  const locationOptions = [...new Set(events.map(event => event.location))].map(set => ({ value: set, label: set }));
  const positionOptions = [...new Set(events.map(event => event.position))].map(position => ({ value: position, label: position }));

  const filteredEvents = events.filter(event => {
    // Search term filter
    const matchesSearchTerm = event.boktitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.set.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.talent.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.company.toLowerCase().includes(searchTerm.toLowerCase());

    // KPI filter
    const matchesKPI = selectedKPI ? event.kpi === selectedKPI : true;
    const matchesGroup = selectedGrup ? event.group === selectedGrup : true;

    const matechesClient = selectedClient.value ? event.client === selectedClient.value : true;
    const matechesSet = selectedSet.value ? event.set === selectedSet.value : true;
    const matchesLocation = !selectedLocation || event.location === selectedLocation.value;
    const matchesPosition = !selectedPosition || event.position === selectedPosition.value;

    // Date range filter
    const eventDate = new Date(event.start);
    const matchesDateRange = selectedDateRange.start && selectedDateRange.end ?
      (eventDate >= selectedDateRange.start && eventDate <= selectedDateRange.end) :
      true;

    return matchesSearchTerm && matchesKPI && matchesDateRange && matechesClient && matechesSet&& matchesLocation &&matchesPosition && matchesGroup;
  });

  const filteredholdEvents = holdevents.filter(event => {
    // Search term filter
    const matchesSearchTerm = event.boktitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.set.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.talent.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.company.toLowerCase().includes(searchTerm.toLowerCase());

    // KPI filter
    const matchesKPI = selectedKPI ? event.kpi === selectedKPI : true;
    const matchesGroup = selectedGrup ? event.group === selectedGrup : true;
    const matechesClient = selectedClient.value ? event.client === selectedClient.value : true;
    const matechesSet = selectedSet.value ? event.set === selectedSet.value : true;
    const matchesLocation = !selectedLocation || event.location === selectedLocation.value;
    const matchesPosition = !selectedPosition || event.position === selectedPosition.value;

    // Date range filter
    const eventDate = new Date(event.start);
    const matchesDateRange = selectedDateRange.start && selectedDateRange.end ?
      (eventDate >= selectedDateRange.start && eventDate <= selectedDateRange.end) :
      true;

    return matchesSearchTerm && matchesKPI && matchesDateRange && matechesClient && matechesSet && matchesLocation &&matchesPosition && matchesGroup;
  });

  const groupedAndSortedEvents = useMemo(() => {
    const grouped = filteredEvents.reduce((acc, event) => {
      const groupKey = event.group || 'No Group';
      acc[groupKey] = acc[groupKey] || [];
      acc[groupKey].push(event);
      return acc;
    }, {});

    for (const group in grouped) {
      grouped[group].sort((a, b) => new Date(a.start) - new Date(b.start));
    }

    return grouped;
  }, [filteredEvents]);

  // Group and sort the hold events
  const groupedAndSortedHoldEvents = useMemo(() => {
    const grouped = filteredholdEvents.reduce((acc, event) => {
      const groupKey = event.group || 'No Group';
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(event);
      return acc;
    }, {});

    // Sort events within each group by start date
    for (const group in grouped) {
      grouped[group].sort((a, b) => new Date(a.start) - new Date(b.start));
    }

    return grouped;
  }, [filteredholdEvents]);


  return (
    <>
      
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={handleChange}
        className='inputtext search'
      />
            <div style={{ marginTop: '40px', marginBottom: '10px', display: 'flex', }}>
    
          
    <Select
            value={preferenceOptions.find(option => option.value === selectedPreferenceIndex)}
            onChange={handlePreferenceChange}
            options={preferenceOptions}
            isClearable
            placeholder="Select Preference"
            styles={customStyles}
        />
             <button className='button'  onClick={() => saveOrUpdateFilterPreference()}>Save/Update </button>
    </div> 
      <div style={{ marginTop: '40px', marginBottom: '60px', display: 'flex', columnGap: '10px' }}>

        <Select
          value={selectedKPI.value}
          onChange={handleKPIChange}
          options={uniqueKPIs}
          styles={customStyles}
          placeholder="KPI"
        />
             <Select
          value={selectedGrup.value}
          onChange={handleGroupChange}
          options={uniqueGroup}
          styles={customStyles}
          placeholder="Group"
        />
        <Select
          value={selectedClient}
          onChange={setSelectedClient}
          options={clientoption}
          styles={customStyles}
          placeholder="Client"

        />
        <Select
          value={selectedSet}
          onChange={setSelectedSet}
          options={set}
          styles={customStyles}
          placeholder="set"
        />

<Select
                    value={selectedPosition}
                    onChange={setSelectedPosition}
                    options={positionOptions}
                    isClearable
                    placeholder="Filter by Position"
           
                    styles={customStyles}
                />
                           <Select
                    value={selectedLocation}
                    onChange={setSelectedLocation}
                    options={locationOptions}
                    isClearable
                    placeholder="Filter by Location"
               
                    styles={customStyles}
                />

      </div>

      <div className='content-wrapper content-wrapper-talent-list'>
        <div className='list-tasks-header'>

          <div style={{ flexBasis: '80px' }}>group</div>
          <div style={{ flexBasis: '200px' }}>start/end</div>

          <div style={{ flexBasis: '100px' }}>set</div>
          <div style={{ flexBasis: "100px" }}>studio location</div>
          <div style={{ flexBasis: '50px' }}>kpi</div>
          <div style={{ flexBasis: '100px' }}>pos.</div>
          <div style={{ flexBasis: '100px' }}>option</div>
          <div style={{ flexBasis: '150px' }}>talent</div>
          <div style={{ flexBasis: '200px' }}>client</div>
          <div style={{ flexBasis: '100px' }}>S.Info</div>
          <div style={{ flexBasis: '400px' }}></div>
        </div>
        
      </div>

      {Object.entries(groupedAndSortedEvents).map(([group, events]) => (
        <div key={group}>
      
          {events.map(event => (

        <div className='list-tasks-item' key={event.id}>
          <div style={{ flexBasis: '100px' }}>
            {event.groupColor ? (
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: event.groupColor,
                color: '#ffffff',
                padding: '3px 3px',
                borderRadius: '10px',
                fontSize: '0.8em',
                margin: '2px 2px',
              


              }}>
                {event.group}
              </div>
            ) : <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              color: '#ffffff',
              padding: '3px 6px',
              borderRadius: '10px',
              fontSize: '0.8em',
              margin: '15px 10px',
              width: '80px',


            }}>

            </div>}
          </div>

          <div style={{ flexBasis: '200px' }}>{new Date(event.start).toLocaleDateString()} - {new Date(event.end).toLocaleDateString()}</div>
          <div style={{ flexBasis: '100px' }}>{event.set}</div>
          <div style={{ flexBasis: "100px" }}>
          {event.studio}/ {event.location}
        </div>
          <div style={{ flexBasis: '50px' }}>{event.kpi}</div>
          <div style={{ flexBasis: '100px' }}>{event.position}</div>
          <div style={{ flexBasis: '100px' }}>{event.option}</div>
          <div style={{ flexBasis: '150px' }}>{event.talent}</div>
          <div style={{ flexBasis: '200px' }}>{event.client}</div>
          <div style={{ flexBasis: '100px' }}>{event.SonstigeInfo}</div>
          <div style={{ marginLeft: 'auto',flexBasis: '400px' }}>
            {renderButtons(event)}
          </div>

          </div>
          ))}
        </div>
      ))}


  

       <h3 style={{backgroundColor:"#800080", color:'white', padding:'20px', }} >On Hold</h3>

       {Object.entries(groupedAndSortedHoldEvents).map(([group, events]) => (
        <div key={group}>
         
          {events.map(event => (

          <div className='list-tasks-item' key={event.id} >
            <div style={{ flexBasis: '100px' }}>
              {event.groupColor ? (
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: event.groupColor,
                  color: '#ffffff',
                  padding: '3px 3px',
                  borderRadius: '10px',
                  fontSize: '0.8em',
                  margin: '2px 2px',
                


                }}>
                  {event.group}
                </div>
              ) : <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
  
                color: '#ffffff',
                padding: '3px 6px',
                borderRadius: '10px',
                fontSize: '0.8em',
                margin: '15px 10px',
                width: '80px',
          


              }}>

              </div>}
            </div>

            <div style={{ flexBasis: '200px' }}>{new Date(event.start).toLocaleDateString()} - {new Date(event.end).toLocaleDateString()}</div>
            <div style={{ flexBasis: '100px' }}>{event.set}</div>
            <div style={{ flexBasis: "100px" }}>
          {event.studio}/ {event.location}
        </div>
            <div style={{ flexBasis: '50px' }}>{event.kpi}</div>
            <div style={{ flexBasis: '100px' }}>{event.position}</div>
            <div style={{ flexBasis: '100px' }}>{event.option}</div>
            <div style={{ flexBasis: '150px' }}>{event.talent}</div>
            <div style={{ flexBasis: '200px' }}>{event.client}</div>
            <div style={{ flexBasis: '100px' }}>{event.SonstigeInfo}</div>
            <div style={{ marginLeft: 'auto',flexBasis: '400px' }}>
              {renderButtonsforHold(event)}
            </div>
            
          </div>
          ))}
          </div>
        ))}
  
</>
);
};


export default ListViewProposed;
