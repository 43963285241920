import React, {useState, useEffect, useLayoutEffect}from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import { BrowserRouter as Router, Switch, Route, Routes } from 'react-router-dom';
import Edit from './Edit';
import { NonceProvider } from 'react-select';
import edit from './../../images/edit.svg';
import closeButton from './../../images/close.svg';
export default function Products({authToken}) {

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const[prev, setprev] = useState(null);
  const [editTaskId, setEditTaskId] = useState(null); // State to track editing task ID
  const [editFormData, setEditFormData] = useState({}); // State to track form data

  const loadUserData = (pageUrl, currentData) => {
    fetch(pageUrl)
      .then((response) => response.json())
      .then((responseJson) => {
        // Update the current page number only if the 'meta' property exists
        if (Object.prototype.hasOwnProperty.call(responseJson, "meta")) {
          setCurrentPage(responseJson["meta"]["currentPage"]);
        }
        
       console.log(responseJson["data"].prev);
       setprev(responseJson['links'].prev);
       // Update the data
      setData([ ...responseJson["data"], ...(currentData || [])]);

      })
      .catch((error) => console.error(error));
  };
  
  const handleEditClick = (task) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json; charset=utf-8");
    myHeaders.append("Authorization", `Bearer ${authToken}`);
  
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
  
    fetch(`https://api.lano.io/api/v1/tasks/${task.id}`, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(result => {
        setEditTaskId(task.id);
        setEditFormData({ ...result.data['attributes']});
      })
      .catch(error => {
        console.log('error', error);
        // Handle error (e.g., show error message)
      });
  };
  
  


  const handleEditFormChange = (e) => {
    setEditFormData({ ...editFormData, [e.target.name]: e.target.value });
  };


  const handleUpdateTask = () => {
    const updatedTaskData = {
      title: editFormData.title,
      description: editFormData.description,
      starts_on: editFormData.startsOn,
      ends_on: editFormData.endsOn,
      assignee_id:editFormData.assigneeId,
      rate:editFormData.rate,
      state:editFormData.state,
      amount:editFormData.amount,
      // ... map other fields similarly ...
    };
  

    const requestOptions = {
      method: 'PATCH',
      headers: {
        "Content-Type": "application/json",
        "Accept": "any",
        "Authorization": `Bearer ${authToken}`
      },
      body: JSON.stringify({ task: updatedTaskData }),
      redirect: 'follow'
    };

    fetch(`https://api.lano.io/api/v1/tasks/${editTaskId} `+
    authToken, requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(result => {
      console.log(result);
      // Close the popup
      setEditTaskId(null);
      // Optionally, refresh the task list or update local state to reflect changes
    })
    .catch(error => {
      console.log('error', error);
      // Handle error (e.g., show error message)
    });
    setEditTaskId(null);
  };

  const loadLastPage = () => {
    fetch(
      'https://api.lano.io/api/v1/tasks?access_token=' +
        authToken +
        '&page[size]=100'
    )
      .then((response) => response.json())
      .then((responseJson) => {
        // Extract the last page URL
        const lastPageUrl = responseJson['links']['last'];

        // Fetch data from the last page
        loadUserData(lastPageUrl);
      })
      .catch((error) => console.error(error));
  };

  const loadMore = () => {
    
    loadUserData(prev, data);
 

  };
  
  useEffect(() => {
    loadLastPage();

  }, []);




   const taskItems = data.map((taks, index) =>
   // Only do this if items have no stable IDs
   
   //<div>
   // <li key={index} 
   // style={{    
   // width: '70%',
   // boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.15)',
   // padding:20,
   // listStyle:"none",
   // marginTop:"30px"}}>
   //   <p>{taks.id}</p> 
   //   <p className='disflex'> <span className='talent-item'>TITLE: </span><span className='talent-item-header'>{taks['attributes'].title}</span></p> 
   //   <p className='disflex'> <span className='talent-item'>DESC: </span><span className='talent-item-header'>{taks['attributes'].description} </span> </p> 
   //   <p className='disflex'> <span className='talent-item'>Starts:</span><span className='detalent-item-headertail'>{taks['attributes'].startsOn}</span> </p> 
   //   <p className='disflex'> <span className='talent-item'>ENDS:</span><span className='talent-item-header'>{taks['attributes'].endsOn}</span></p> 
   //   <p className='disflex'> <span className='talent-item'>STATE:</span><span className='talent-item-header'>{taks['attributes'].state}</span></p> 
   //   <p className='disflex'> <span className='talent-item'>EMAIL:</span><span className='talent-item-header'>{taks['attributes'].creatorEmail}</span></p> 
   //   <p className='disflex'> <span className='talent-item'>RATE:</span><span className='talent-item-header'>{taks['attributes'].rate} {taks['attributes'].currency}</span></p> 
   //   <p className='disflex'> <span className='talent-item'>Creatorid:</span><span className='talent-item-header'> {taks['attributes'].creatorId}</span></p> 

   //   <div style={{
   //     display: 'flex',
   //     justifyContent: 'space-between',
   //     color:"#ffffff",
   //     width: '100%',
   //   }} >



   //   </div>
   // </li>
  //  </div>

  <div className='list-tasks-item'>
    <div style={{ flexBasis: '250px' }}>{taks['attributes'].title}</div>
    <div style={{ flexBasis: '300px' }}>{taks['attributes'].description}</div>
    <div style={{ flexBasis: '120px' }}>{taks['attributes'].startsOn}</div>
    <div style={{ flexBasis: '120px' }}>{taks['attributes'].endsOn}</div>
    <div style={{ flexBasis: '200px' }}>{taks['attributes'].state}</div>
    <div style={{ flexBasis: '200px' }}>{taks['attributes'].creatorEmail}</div>
    <div style={{ flexBasis: '100px' }}>{taks['attributes'].rate} {taks['attributes'].currency}</div>
    <div style={{ flexBasis: '80px' }}>{taks['attributes'].creatorId}</div>
    <button className='edit-button' onClick={() => handleEditClick(taks)}>  <img src={edit} alt="Edit" className="edit-container" /></button>
  </div>



 ).reverse();

  return (
    <>
      <section>
        <div className='content-wrapper'>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '60px' }}>
            <h1>all tasks</h1>
            <div style={{ display: 'flex', justifyContent: 'end',}}>
            <button className='big-button' onClick={() => loadLastPage()}>refresh</button>
            <a className='big-button' href='/admin/lanoids'>Lano Ids</a>
            </div>
          </div>
        </div>
        <div className='content-wrapper content-wrapper-talent-list'>
          <div className='list-tasks-header'>
            <div style={{ flexBasis: '250px' }}>title</div>
            <div style={{ flexBasis: '300px' }}>description</div>
            <div style={{ flexBasis: '120px' }}>start</div>
            <div style={{ flexBasis: '120px' }}>end</div>
            <div style={{ flexBasis: '200px' }}>state</div>
            <div style={{ flexBasis: '200px' }}>email</div>
            <div style={{ flexBasis: '100px' }}>rate</div>
            <div style={{ flexBasis: '80px' }}>id</div>
          </div>
          <div style={{ marginBottom: "30px" }}>
            {taskItems}
          </div>
        </div>
        <div className='content-wrapper'>
          <button className='big-button' onClick={() => loadMore()}>load more</button>
        </div>
      </section>

      {editTaskId && (
        <div className="edit-form-popup">
          <button className='edit-button'  style={{float:'right', height:"20px"}} onClick={() => setEditTaskId(null)}>
                          <img src={closeButton} alt="Close"  className="edit-container" />
                        </button>
          <div className="edit-form">
          <label>title</label>
          <input type="text" name="title" value={editFormData.title} onChange={handleEditFormChange} />
          <label>description</label>
          <input type="text" name="description" value={editFormData.description} onChange={handleEditFormChange} />
          <label>starts_on</label>
          <input type="text" name="startsOn" value={editFormData.startsOn} onChange={handleEditFormChange} />
          <label>ends_on</label>
          <input type="text" name="endsOn" value={editFormData.endsOn} onChange={handleEditFormChange} />
          <label>rate</label>
          <input type="text" name="rate" value={editFormData.rate} onChange={handleEditFormChange} />
          <label>amount</label>
          <input type="text" name="amount" value={editFormData.amount} onChange={handleEditFormChange} />
          <label>state</label>
          <input type="text" name="state" value={editFormData.state} onChange={handleEditFormChange} />
          <label>assignee_id</label>
          <input type="text" name="assigneeId" value={editFormData.assigneeId} onChange={handleEditFormChange} />
     
          <button className='button' onClick={handleUpdateTask}>Update</button>
          </div>
        </div>
      )}
    </>
  );
}