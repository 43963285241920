import React, { useState, useEffect } from "react";
import "../../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import { db } from "./../../firebaseConfig";
import {
  doc,
  getDoc,
  updateDoc,
  where,
  collection,
  query,
  getDocs,
} from "firebase/firestore";
import Select, { components } from "react-select";
import { customStyles } from "./../../helpers/StyleUtils";
function BookingDetailsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [bookingDetails, setBookingDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [status, setStatus] = useState("");
  const [propsoedBy, setpropsoedBy] = useState("");
  const searchParams = new URLSearchParams(location.search);
  const bookingId = new URLSearchParams(location.search).get("id");
  const [superAdminOptions, setSuperAdminOptions] = useState([]);

  const bookingStatusOptions = [
    { value: "Booked", label: "booked / Lano task created" },
    { value: "confirmed", label: "confirmed" },
    { value: "new order", label: "new order" },
    { value: "requested", label: "requested" },
    { value: "declined request", label: "declined request" },
    { value: "declined", label: "declined" },
    { value: "canceled", label: "canceled" },
    { value: "optioned", label: "optioned" },
    { value: "optionSolved", label: "option released" },
  

    // ... other statuses
  ];
  const fetchSuperAdmins = async () => {
    const superAdminQuery = query(
      collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData"),
      where("role", "==", "superadmin")
    );
    const querySnapshot = await getDocs(superAdminQuery);
    const superAdminEmails = querySnapshot.docs.map((doc) => ({
      value: doc.data().email, // Assuming the document contains an email field
      label: doc.data().email, // Displaying the email as both value and label for selection
    }));
    setSuperAdminOptions(superAdminEmails);
  };

  console.log("this is booking id " + bookingId);
  useEffect(() => {
    let active = true; // Flag to prevent state update if the component is unmounted

    const fetchSuperAdmins = async () => {
      const superAdminQuery = query(
        collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData"),
        where("role", "==", "superadmin")
      );
      const querySnapshot = await getDocs(superAdminQuery);
      const superAdminEmails = querySnapshot.docs.map((doc) => ({
        value: doc.data().email, // Assuming the document contains an email field
        label: doc.data().email, // Displaying the email as both value and label for selection
      }));
      if (active) {
        setSuperAdminOptions(superAdminEmails);
      }
    };

    const fetchBookingDetails = async () => {
      setLoading(true);
      setNotFound(false);

      if (bookingId) {
        try {
          const bookingRef = doc(
            db,
            "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings",
            bookingId
          );
          const bookingSnap = await getDoc(bookingRef);

          if (bookingSnap.exists() && active) {
            setBookingDetails(bookingSnap.data());
            setStatus(
              bookingStatusOptions.find(
                (option) => option.value === bookingSnap.data().status
              )
            );
            // Delay proposedBy setting until superAdminOptions are fetched
          } else if (active) {
            setNotFound(true);
          }
        } catch (error) {
          console.error("Error fetching booking: ", error);
          if (active) setNotFound(true);
        } finally {
          if (active) setLoading(false);
        }
      } else {
        if (active) {
          setNotFound(true);
          setLoading(false);
        }
      }
    };

    fetchSuperAdmins().then(fetchBookingDetails); // Ensure superAdmins are fetched before booking details

    return () => {
      active = false; // Prevent updates if the component is unmounted
    };
  }, [location.search]); // Run only when location.search changes

  // Use another useEffect to set proposedBy once superAdminOptions and bookingDetails are ready
  useEffect(() => {
    if (bookingDetails && superAdminOptions.length > 0) {
      setpropsoedBy(
        superAdminOptions.find(
          (option) => option.value === bookingDetails.proposedBy
        )
      );
    }
  }, [bookingDetails, superAdminOptions]); // Depend on bookingDetails and superAdminOptions

  const updateStatus = async () => {
    if (status) {
      const bookingRef = doc(
        db,
        "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings",
        bookingId
      );
      await updateDoc(bookingRef, { status: status.value });
      alert("Status updated successfully");
    } else {
      alert("Please select a status before updating");
    }
  };

  const updatePropsedBy = async () => {
    if (propsoedBy) {
      const bookingRef = doc(
        db,
        "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings",
        bookingId
      );
      await updateDoc(bookingRef, { proposedBy: propsoedBy.value });
      alert("Propsed By user updated successfully");
    } else {
      alert("Please select a user before updating");
    }
  };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption);
  };

  const poposeClick = async () => {
    const bookingRef = doc(
      db,
      "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings",
      bookingId
    );
    const bookingSnap = await getDoc(bookingRef);
    if (bookingSnap.exists() && !bookingSnap.data().isBeingProcessed) {
      // If the order is not being processed, update it to being processed
      await updateDoc(bookingRef, {
        isBeingProcessed: true,
        processingBy: localStorage.getItem("userEmail"),
      });
      navigate(
        `/admin/propose?bookingId=${bookingSnap.bookingId}&group=${bookingSnap.group}`
      );
    } else {
      alert(
        "This order is being processed by another user." +
          bookingRef.data().isBeingProcessed
      );
    }
  };
  // Render the booking details if available
  return (
    <section>
      {loading ? (
        <div className="content-wrapper">Loading Order details...</div>
      ) : notFound ? (
        <div className="content-wrapper">No such Order exists.</div>
      ) : bookingDetails ? (
        <div>
          <div className="content-wrapper">
            <h1 style={{ marginBottom: "50px" }}>Order Details</h1>
          </div>
          <div className="content-wrapper content-wrapper-talent-list">
            <div className="list-tasks-header">
              <div style={{ flexBasis: "350px" }}>Client</div>
              <div style={{ flexBasis: "150px" }}>Date</div>
              <div style={{ flexBasis: "150px" }}>option</div>
              <div style={{ flexBasis: "150px" }}>position</div>
              <div style={{ flexBasis: "150px" }}>set</div>
              <div style={{ flexBasis: "150px" }}>status</div>
              <div style={{ flexBasis: "150px" }}>start</div>
              <div style={{ flexBasis: "150px" }}>end</div>
            </div>
            <div className="list-tasks-item">
              <div style={{ flexBasis: "350px" }}>
                {bookingDetails.client.label}
              </div>
              <div style={{ flexBasis: "150px" }}>
                {
                  bookingDetails.end_date
                    .toDate()
                    .toLocaleDateString()
                    .split("T")[0]
                }
              </div>
              <div style={{ flexBasis: "150px" }}>{bookingDetails.option}</div>
              <div style={{ flexBasis: "150px" }}>
                {bookingDetails.position.label}
              </div>
              <div style={{ flexBasis: "150px" }}>{bookingDetails.set}</div>
              <div style={{ flexBasis: "150px" }}>{bookingDetails.status}</div>
              <div style={{ flexBasis: "150px" }}>
                {bookingDetails.startTime}
              </div>
              <div style={{ flexBasis: "150px" }}>{bookingDetails.endTime}</div>
            </div>
          </div>
          <div className="content-wrapper" style={{ marginTop: "30px" }}>
            <button
              className="button"
              onClick={() => navigate(`../propose?bookingId=${bookingId}&group=${bookingDetails.group}`)}
            >
              propose other talent
            </button>
            <button
              className="button"
              onClick={() => navigate(`../edit?bookingId=${bookingId}`)}
            >
              edit all details
            </button>
          </div>
          <div className="content-wrapper" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
    <div style={{ marginTop: "50px", width: "28%",}}> 
    <p style={{ padding:'10px'}}>Status:</p>
      <Select
        value={status}
        onChange={handleStatusChange}
        options={bookingStatusOptions}
        isClearable
        placeholder="Select Status"
        styles={customStyles}
      />
      </div>
      <div style={{ marginTop: "90px", width: "28%",}}> 
      <button className="button" onClick={updateStatus}>
        Update Status
      </button>
    </div>
    <div style={{ marginTop: "50px", width: "28%" }}> 
    <p style={{ padding:'10px'}}>Propose By:</p>
      <Select
        value={propsoedBy}
        onChange={setpropsoedBy}
        options={superAdminOptions}
        isClearable
        placeholder="Select Proposed user"
        styles={customStyles}
      />
      </div>
      <div style={{marginTop: "90px", width: "28%", }}> 
      <button className="button" onClick={updatePropsedBy}>
        Update Propose By
      </button>
    </div>
</div>

        </div>
      ) : null}
    </section>
  );
}
export default BookingDetailsPage;
