import React, { useState, useEffect, useContext } from 'react';
import '../../App.css';
import { useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select';
import RoleContext from './../../helpers/RoleContext';

import { collection, addDoc, getDocs, Timestamp } from "firebase/firestore";

import { auth, db  } from './../../firebaseConfig';

function AddTalent() {



  const [companies, setCompanies] = useState([]);
  const [talents, setTalents] = useState([]);
  const navigate= useNavigate ();
const [talentEmail, setTalentEmail]=useState('');
const [talentName, setTalentName]=useState('');
const [talentSurName, setTalentSurName]=useState('');
const [talentPhone, setTalentPhone]=useState('');
const [talentFunction, setTalentFunction]=useState('');
const [talentType, setTalentType]=useState('');
const [talentOtherFunction, setTalentOtherFunction]=useState('');
const [talentPrice, setTalentPrice]=useState('');
const [talentAP, setTalentAP]=useState('');
const [talentAchtung, setTalentAchtung]=useState('');
const [talentAgentur, setTalentAgentur]=useState('');
const [lanoid, setLanoid]=useState('');
const [talentNotiz, setTalentNotiz]=useState('');
const [talentWeb, setTalentWeb]=useState('');
const [talentVerifizierungv, setTalentVerifizierungv]=useState('');
const [locations, setLocations] = useState([]);
const [selectLocations, setSelectedLocations] = useState([]);

const [selectedOption, setSelectedOption] = React.useState(null);
const [selectedPosition, setselectedPosition] = React.useState(null);
const positions = [
  { value: 'Hair & Make-Up', label: 'Hair & Make-Up' },
  { value: 'Art Direktion', label: 'Art Direktion' },
  { value: 'Foto', label: 'Foto' },
  { value: 'Foto Assist', label: 'Foto Assist' },
  { value: 'Video', label: 'Video' },
  { value: 'Digi-OP', label: 'Digi-OP' },
  { value: 'Styling', label: 'Styling' },
  { value: 'Styling Assist', label: 'Styling Assist' },
  { value: "LOGISTIK / WARENAUFBEREITUNG", label: "LOGISTIK / WARENAUFBEREITUNG" },
  { value: "PRODUCER", label: "PRODUCER" },  
  { value: "Art Buying", label: "Art Buying" },  
  { value: "Account Management", label: "Account Management" },  
  

];

const set = [


  { value: 'On Model', label: 'On Model' },
  { value: 'On Produkt', label: 'On Produkt' },



];
const fetchlocations = async () => {
  const locationSnap = await getDocs(
    collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/locations")
  );
  const locationData = locationSnap.docs.map((doc) => doc.data().short);
  setLocations(locationData);
};
const locationOptions = locations
.map((locations) => ({
  value: locations,
  label: locations,
}))
.sort((a, b) => a.label.localeCompare(b.label));

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <div style={{ width: '16px', margin: '0' }}>
          <svg version="1.1" id="Isolationsmodus" x="0px" y="0px" viewBox="0 0 322.9 257.78" shapeRendering="geometricPrecision">
            <path xmlns="http://www.w3.org/2000/svg" d="M208.27,7.37c-4.69-4.79-11.12-7.4-17.72-7.81c-0.4-0.03-0.8-0.05-1.22-0.05l0,0h-35.34h-70.2H31.14h-4.27  C17.33-0.46,7.87,4.51,3.34,13.12C-1.19,21.65-1.3,32.3,4.19,40.47c0.85,1.3,1.74,2.56,2.6,3.82C17.3,59.73,27.77,75.2,38.27,90.64  c12.47,18.33,24.91,36.7,37.39,55.03c3.08,4.53,6.13,9.02,9.21,13.54c3.97,5.86,9.06,10.5,16.08,12.36  c6.87,1.86,14.85,0.93,20.9-2.93c3.79-2.45,6.24-5.12,8.8-8.79c8.06-11.54,15.82-23.3,23.76-34.96  c13.14-19.33,26.25-38.67,39.39-57.96c4.68-6.87,9.32-13.73,14-20.63c4.31-6.31,8.39-12.13,8.2-20.41  C215.85,18.95,213.1,12.34,208.27,7.37z" />
          </svg>
        </div>
      </components.DropdownIndicator>
    )
  );
};
const handlePostionchange = selectedPosition => {

  setselectedPosition(selectedPosition);
}

const customStyles = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    border: 'none',
  }),
  control: (base) => ({
    ...base,
    width: '500px',
    borderColor: 'black',
    borderWidth: '1px',
    boxShadow: 'none',
    margin: '0px',
    borderRadius: '0px',
    marginBottom:'5px',
    color: 'black',
    fontWeight: "700",
   height:"auto",
    textTransform: 'uppercase',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'black',
    fontWeight: "700"
  }),
  input: (provided) => ({
    ...provided,
    color: 'black',
    fontWeight: "700"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'black' : 'inherit',
    '&:hover': { backgroundColor: state.isSelected ? 'black' : '#f7f7f7' }
  }),
};

const handleOptionChange = selectedOption => {
    setSelectedOption(selectedOption);
}


  useEffect(() => {
      // Fetch companies and talents
      const fetchCompaniesAndTalents = async () => {
          const companySnap = await getDocs(collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/company"));
          const talentSnap = await getDocs(collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents"));

          const companyData = companySnap.docs.map(doc => ({
              value: doc.id, 
              label: doc.data().name  // assumes the company document has a 'name' field
          }));
          const talentData = talentSnap.docs.map(doc => ({
              value: doc.id, 
              label: doc.data().name,  // assumes the talent document has a 'name' field
              email: doc.data().email
          }));

          setCompanies(companyData);
          setTalents(talentData);
      };

      fetchCompaniesAndTalents();
      fetchlocations();
  }, []);




const uploadJSON = async () => {
  const fileInput = document.getElementById('jsonFileInput');
  const file = fileInput.files[0];
  if (file) {
    const reader = new FileReader();

    reader.onload = async (e) => {
      const jsonArray = JSON.parse(e.target.result);

      jsonArray.forEach(async (jsonObject) => {
        try {
          await addDoc(collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data"), jsonObject);
        } catch (error) {
          console.error("Error uploading JSON object: ", error);
        }
      });

      console.log("JSON Array Uploaded");
    };

    reader.readAsText(file);
  } else {
    console.log("No file selected");
  }
};

  
const addTalent = async (e) => {
  e.preventDefault();
  try {
      const docRef = await addDoc(collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data"), {
         // AP:talentAP,
          Agentur: talentAgentur,
          Bereich: talentType.label,
          Doppelfunktion: talentAchtung,
          E_Mail:talentEmail,
          Funktion:selectedPosition.label,
          Gage: talentPrice,
          Lano_ID:lanoid,
          Name: talentName,
          Notiz_Setcast:talentNotiz,
          Tagessatz:talentPrice,
          Telefon:talentPhone,
          Verifizierungv:"",
          Web:talentWeb,
          locations: selectLocations,
       
      });
      console.log("Talent added with ID: ", docRef.id);
      alert("Talent added successfully.");
         
      window.location.reload();
    
  } catch (e) {
      console.error("Error adding talent: ", e);
  }
}



  return (
    <div className="content-wrapper">
      <section>
        <h1 style={{ marginBottom: '40px' }}>Talent</h1>
        <h2 style={{ marginBottom: '40px' }}>Add Talent</h2>
        <form onSubmit={addTalent} style={{ maxWidth: '500px' }}>
          <input className='inputtext' type="text" value={talentName} onChange={(e) => setTalentName(e.target.value)} required placeholder='Name'/>
          <input className='inputtext'  type="text" value={talentEmail} onChange={(e) => setTalentEmail(e.target.value)} required placeholder='Email'/>
          <input className='inputtext'  type="text" value={talentPhone} onChange={(e) => setTalentPhone(e.target.value)} required placeholder='Phone number'/>
 
          <Select
           
           value={talentType}
           onChange={setTalentType}
           options={set}
           placeholder="SET/Bereich"
           isClearable
           required
           styles={customStyles}
         />
          <Select
            components={{ DropdownIndicator }}
            options={positions}
            onChange={handlePostionchange}
            placeholder="Function"
            value={selectedPosition}
            styles={customStyles}
            />
                   <Select
                      isMulti
                      value={selectLocations}
                      onChange={setSelectedLocations}
                      options={locationOptions}
                      isClearable
                      placeholder="Locations"
                      styles={customStyles}
                    />
          <input className='inputtext'  type="text" value={talentPrice} onChange={(e) => setTalentPrice(e.target.value)} required placeholder='Price Per Day'/>
     
       
          <input className='inputtext'  type="text" value={talentAgentur} onChange={(e) => setTalentAgentur(e.target.value)} required placeholder='Agency'/>
          <input className='inputtext'  type="text" value={lanoid} onChange={(e) => setLanoid(e.target.value)} required placeholder='Lano ID'/>
          <input className='inputtext'  type="text" value={talentNotiz} onChange={(e) => setTalentNotiz(e.target.value)} required placeholder='Notes SetCast'/>
          <input className='inputtext'  type="text" value={talentWeb} onChange={(e) => setTalentWeb(e.target.value)} required placeholder='WEB'/>
          <input className='inputtext'  type="text" value={talentVerifizierungv} onChange={(e) => setTalentVerifizierungv(e.target.value)} required placeholder='Verification'/>
          <button style={{marginTop: '20px'}} className='big-button' type="submit">Add Talent</button>
        </form>
      </section>
    </div>
  );
}

export default AddTalent;
/*
        <input className='inputfile' type="file" id="jsonFileInput" />
<button className='button' type="button" onClick={uploadJSON}>Upload JSON</button> */