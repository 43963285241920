import React, { useState, useEffect } from 'react';
import '../../App.css';

function NoPrivilages () {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000); // 1000 milliseconds = 1 second

        // cleanup function to clear the timeout if the component is unmounted before the timeout finishes
        return () => clearTimeout(timer);
    }, []);

    if (loading)
    {    
        return <div class="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>;
    }
    else
    { 
        return (
            <section>
                <div className='content-wrapper'>
                    <div className='alert'>
                        <h1>No privilages</h1>
                        <p>Please contact an administrator to configure your permissions.</p>
                        <p>Once this action has been completed, remember to refresh the page.</p> 
                    </div>
                </div>
            </section>
        );
    }
}

export default NoPrivilages;
