import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdown from './../Dropdown';
import { signOut, auth } from './../../firebaseConfig';

const bokking = [
  { title: 'New Booking', path: '/contact-us' },
  // add more items here...
];

const talent = [
  { title: 'Talent List', path: '/talent-list' },
  // add more items here...
];

const company = [
  { title: 'add Company', path: '/add-company' },
  // add more items here...
];


function Navbar() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdowntalent, setDropdowntalent] = useState(false);
  const [dropdowncompany, setDropdowncompany] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        // ...
        localStorage.setItem("userRole", null); //
      })
      .catch((error) => {
        // An error occurred.
        console.error("Logout error:", error);
      });
  }
  

  return (
    <> 
      <div className='black-bg'>
        <div className='content-wrapper'>
          <nav className='navbar'  onMouseLeave={() => {setDropdowncompany(false) ;setDropdowntalent(false); setDropdown(false)}}>
            <Link to='/np/' className='navbar-logo' onClick={closeMobileMenu}>
              <img src={require('./../../images/logo-setcast.png')} />
            </Link>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              <li className='nav-item' style={{ marginRight: '25px' }}>
                <button style={{ cursor: "pointer", padding: 5, backgroundColor: "#121212", border: "none", margin: 0, height: "24px", paddingTop: 2, fontSize: 18, fontWeight: 900 }} onClick={handleLogout}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198.4 198.5" width="20px" height="auto">
                  <path d="m197.8,102.2c.2-.7.4-1.4.4-2.1,0-.3.2-.5.2-.8s-.1-.5-.2-.8c-.1-.7-.2-1.4-.4-2.1-.2-.6-.4-1.2-.7-1.7-.2-.3-.2-.6-.4-.9l-19.8-29.8c-.2-.3-.4-.6-.6-.9-3.3-4.4-9.5-5.3-13.9-2-4.4,3.3-5.3,9.5-2,13.9l9.6,14.4h-60.9c-5.5,0-9.9,4.4-9.9,9.9s4.4,9.9,9.9,9.9h60.9l-9.6,14.4c-1.1,1.6-1.6,3.5-1.6,5.4,0,5.5,4.4,9.9,9.9,10,3.3,0,6.4-1.6,8.2-4.4l19.8-29.8v-.1c.2-.3.3-.6.4-.9.4-.6.6-1.1.7-1.6Z" fill="#ffffff" stroke-width="0"/>
                  <path d="m138.9,125.9c-5.5,0-9.9,4.4-9.9,9.9v32.8c0,5.5-4.4,9.9-9.9,9.9H29.8c-5.5,0-9.9-4.4-9.9-9.9V29.8c0-5.5,4.4-9.9,9.9-9.9h89.3c5.5,0,9.9,4.4,9.9,9.9v27.9c0,5.5,4.4,9.9,9.9,9.9s9.9-4.4,9.9-9.9v-27.9c0-16.4-13.3-29.8-29.8-29.8H29.8C13.3,0,0,13.3,0,29.8v138.9c0,16.4,13.3,29.8,29.8,29.8h89.3c16.4,0,29.8-13.3,29.8-29.8v-32.8c-.1-5.6-4.5-10-10-10Z" fill="#ffffff" stroke-width="0"/></svg>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Navbar;
