import React, { useState, useEffect } from "react";
import proposeTalent from "./../../images/propose-talent.svg";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  setDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import Select, { components } from "react-select";
import { customStyles } from "./../../helpers/StyleUtils";
import "../../App.css";
import moment from "moment";
import edit from './../../images/edit.svg';

const DeletedOrdersList = ({ events }) => {
  let userEmail = localStorage.getItem("userEmail");
  const [filterPreferences, setFilterPreferences] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCreatedAt, setSelectedCreatedAt] = useState(null);
  const [selectedstartDate, setSelectedstartDate] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedSet, setSelectedSet] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedPreferenceIndex, setSelectedPreferenceIndex] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState({});
  useEffect(() => {
    const fetchPreferences = async () => {
      if (!userEmail) return;

      const usersRef = collection(
        db,
        `/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData`
      );
      const q = query(usersRef, where("email", "==", userEmail));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        const userPrefs = userData.filterPreferences;
        if (userPrefs) {
          setFilterPreferences(userPrefs);
        }
      } else {
        console.log("No user or preferences found.");
      }
    };

    fetchPreferences();
  }, [userEmail]);

  const applyFilterPreference = (index) => {
    if (index < 0 || index >= filterPreferences.length) {
      console.log("Invalid filter preference index.");
      return;
    }

    const preference = filterPreferences[index];
    setSearchTerm(preference.searchTerm);
    setSelectedCreatedAt(preference.selectedCreatedAt);

    setSelectedClient(preference.selectedClient);
    setSelectedPosition(preference.selectedPosition);
    setSelectedSet(preference.selectedSet);
    setSelectedLocation(preference.selectedLocation);
    // Apply other filters as needed
  };

  const preferenceOptions = filterPreferences.map((_, index) => ({
    value: index,
    label: `Preference ${index + 1}`,
  }));
  const handlePreferenceChange = (selectedOption) => {
    setSelectedPreferenceIndex(selectedOption ? selectedOption.value : null);
    if (selectedOption) {
      applyFilterPreference(selectedOption.value);
    } else {
      // Clear all filter preferences here
      setSearchTerm("");
      setSelectedCreatedAt(null);

      setSelectedClient(null);
      setSelectedPosition(null);
      setSelectedSet(null);
      setSelectedLocation(null);
      // Reset any other filter states back to their initial values here
    }
  };

  const handleCheckboxChange = (index) => {
    setSelectedOrders((prevSelected) => ({
      ...prevSelected,
      [index]: !prevSelected[index],
    }));
  };
  const saveOrUpdateFilterPreference = async () => {
    const newPreference = {
      searchTerm,
      selectedCreatedAt,
      selectedClient,
      selectedPosition,
      selectedSet,
      selectedLocation,
      // Add other filter settings as needed
    };

    if (selectedPreferenceIndex !== null) {
      // Update an existing preference
      filterPreferences[selectedPreferenceIndex] = newPreference;
    } else {
      // Save a new preference if there's room
      if (filterPreferences.length < 3) {
        filterPreferences.push(newPreference);
      } else {
        alert("You can only save up to 3 filter preferences.");
        return;
      }
    }

    // Fetch the user's document reference again to ensure we have the correct document to update
    const usersRef = collection(
      db,
      `/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData`
    );
    const q = query(usersRef, where("email", "==", userEmail));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userDocRef = querySnapshot.docs[0].ref; // Document reference
      // Update the document with the new or updated preferences
      try {
        await updateDoc(userDocRef, { filterPreferences: filterPreferences });
        setFilterPreferences([...filterPreferences]); // Update local state
        alert("Filter preferences updated successfully.");
      } catch (error) {
        console.error("Error updating preferences:", error);
        alert("Failed to update filter preferences.");
      }
    } else {
      console.log("User document not found.");
    }
  };

  const markTheOrder = async () => {
    // Retrieve the email from local storage or another source
    const userEmail = localStorage.getItem("userEmail");

    // Filter out selected order IDs
    const selectedOrderIds = Object.keys(selectedOrders).filter(
      (id) => selectedOrders[id]
    );

    if (selectedOrderIds.length === 0) {
      console.log("No orders selected.");
    } else {
      console.log("Selected Orders:", selectedOrderIds);

      // Iterate over each selected order ID and update it in Firestore
      const updates = selectedOrderIds.map(async (orderId) => {
        const orderRef = doc(
          db,
          `SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings/${orderId}`
        );
        return updateDoc(orderRef, {
          proposedBy: userEmail,
         
        });
      });

      try {
        // Wait for all Firestore updates to complete
        await Promise.all(updates);
        alert("Booking marked successfully.");

        window.location.reload();
      } catch (error) {
        console.error("Error updating selected orders:", error);
      }
    }
  };

  const RestoreTheOrder = async () => {
    // Retrieve the email from local storage or another source
    const userEmail = localStorage.getItem("userEmail");

    // Filter out selected order IDs
    const selectedOrderIds = Object.keys(selectedOrders).filter(
      (id) => selectedOrders[id]
    );

    if (selectedOrderIds.length === 0) {
      console.log("No orders selected.");
    } else {
      console.log("Selected Orders:", selectedOrderIds);

      // Iterate over each selected order ID and update it in Firestore
      const updates = selectedOrderIds.map(async (orderId) => {
        const orderRef = doc(
          db,
          `SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings/${orderId}`
        );
        return updateDoc(orderRef, {
          proposedBy: userEmail,
          status:'new order',
          group:null,
        });
      });

      try {
        // Wait for all Firestore updates to complete
        await Promise.all(updates);
        alert("Bookings undo delete successfully.");

        window.location.reload();
      } catch (error) {
        console.error("Error updating selected orders:", error);
      }
    }
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div style={{ width: "16px", margin: "0" }}>
            <svg
              version="1.1"
              id="Isolationsmodus"
              x="0px"
              y="0px"
              viewBox="0 0 322.9 257.78"
              shapeRendering="geometricPrecision"
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M208.27,7.37c-4.69-4.79-11.12-7.4-17.72-7.81c-0.4-0.03-0.8-0.05-1.22-0.05l0,0h-35.34h-70.2H31.14h-4.27  C17.33-0.46,7.87,4.51,3.34,13.12C-1.19,21.65-1.3,32.3,4.19,40.47c0.85,1.3,1.74,2.56,2.6,3.82C17.3,59.73,27.77,75.2,38.27,90.64  c12.47,18.33,24.91,36.7,37.39,55.03c3.08,4.53,6.13,9.02,9.21,13.54c3.97,5.86,9.06,10.5,16.08,12.36  c6.87,1.86,14.85,0.93,20.9-2.93c3.79-2.45,6.24-5.12,8.8-8.79c8.06-11.54,15.82-23.3,23.76-34.96  c13.14-19.33,26.25-38.67,39.39-57.96c4.68-6.87,9.32-13.73,14-20.63c4.31-6.31,8.39-12.13,8.2-20.41  C215.85,18.95,213.1,12.34,208.27,7.37z"
              />
            </svg>
          </div>
        </components.DropdownIndicator>
      )
    );
  };
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const navigate = useNavigate();
  const poposeClick = async (event) => {
    const bookingId = event.bookingId;
    const orderRef = doc(
      db,
      "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings",
      bookingId
    );
    const orderSnap = await getDoc(orderRef);

    if (orderSnap.exists() && !orderSnap.data().isBeingProcessed) {
      // If the order is not being processed, update it to being processed
      await updateDoc(orderRef, {
        isBeingProcessed: true,
        processingBy: localStorage.getItem("userEmail"),
      });
      navigate(
        `/admin/propose?bookingId=${event.bookingId}&group=${event.group}`
      );
    } else {
      alert(
        "This order is being processed by another user." +
          orderSnap.data().isBeingProcessed
      );
    }
  };
  const processedGroups = new Set();

  function reformatDate(dateStr) {
    // Check for timestamp format (date and time)
    const timestampRegex = /^\d{4}-\d{2}-\d{2}(T| )\d{2}:\d{2}:\d{2}/;
    // Check for 'd.m.yyyy' or 'dd.mm.yyyy' format
    const dateRegex = /^\d{1,2}\.\d{1,2}\.\d{4}$/;

    if (timestampRegex.test(dateStr)) {
      // Extract the date part and convert to 'DD/MM/YYYY' format
      const datePart = dateStr.split(/T| /)[0];
      const [year, month, day] = datePart.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    } else if (dateRegex.test(dateStr)) {
      // Split the date, pad each part with leading zero if necessary, and join with slashes
      return dateStr
        .split(".")
        .map((part) => part.padStart(2, "0"))
        .join("/");
    } else {
      // If the date is not in the expected format, return it as is
      return dateStr;
    }
  }

  function extractAndFormatDate(dateTimeStr) {
    // Use moment.js to parse the date string
    const date = moment(dateTimeStr, "DD/MM/YYYY, HH:mm:ss"); // Adjust the format to match your input

    // Check if the date is valid
    if (date.isValid()) {
      // Format the date to a string, e.g., 'DD/MM/YYYY'
      return date.format("DD/MM/YYYY");
    } else {
      // If the date is invalid, handle accordingly
      return "Invalid Date";
    }
  }

  const createdAtOptions = events.map((event) => {
    // Split the string into date and time parts
    const [datePart, timePart] = event.createdAt.split(", ");

    // Further split the date into day, month, and year
    const [day, month, year] = datePart
      .split("/")
      .map((num) => parseInt(num, 10));

    // Create a new Date object using year, month, and day
    // Note: Months are 0-indexed in JavaScript Date (0 for January, 1 for February, etc.)
    // const date = new Date(year, month - 1, day);
    const date = event.createdAt;
    // Format the date to a string
    //const formatedDate = date.toISOString();
    const formattedDate = extractAndFormatDate(date);

    return { value: event.createdAt, label: formattedDate };
  });
  const startDate = [...new Set(events.map((event) => event.start))].map(
    (start) => {
      const formattedDate = start.toLocaleDateString(); // Formats the Date object into a readable date string

      return { value: formattedDate, label: formattedDate }; // Use the formatted date string for both value and label
    }
  );

  const clientOptions = [...new Set(events.map((event) => event.client))].map(
    (client) => ({ value: client, label: client })
  );
  const positionOptions = [
    ...new Set(events.map((event) => event.position)),
  ].map((position) => ({ value: position, label: position }));
  const setOption = [...new Set(events.map((event) => event.set))].map(
    (set) => ({ value: set, label: set })
  );
  const locationOptions = [
    ...new Set(events.map((event) => event.location)),
  ].map((set) => ({ value: set, label: set }));

  const sortedAndFilteredEvents = events
  .sort((a, b) => {
    const startDateA = new Date(a.start);
    const startDateB = new Date(b.start);
    return startDateB - startDateA; // Descending order by start date
  })
    .filter((event) => {
      const matchesSearchTerm =
      event.boktitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.set?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.talent?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.status?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.company?.toLowerCase().includes(searchTerm.toLowerCase());
  

      const matchesCreatedAt =
        !selectedCreatedAt || event.createdAt === selectedCreatedAt.value;
      const matchesstartdAt =
        !selectedstartDate ||
        event.start.toLocaleDateString() === selectedstartDate.value;

      const matchesClient =
        !selectedClient || event.client === selectedClient.value;
      const matchesPosition =
        !selectedPosition || event.position === selectedPosition.value;
      const matchesSet = !selectedSet || event.set === selectedSet.value;
      const matchesLocation =
        !selectedLocation || event.location === selectedLocation.value;

      return (
        matchesSearchTerm &&
        matchesCreatedAt &&
        matchesClient &&
        matchesPosition &&
        matchesstartdAt &&
        matchesSet &&
        matchesLocation
      );
    });

  const eventsByGroup = sortedAndFilteredEvents.reduce((acc, event) => {
    if (event.group) {
      acc[event.group] = acc[event.group] || [];
      acc[event.group].push(event);
    } else {
      acc["ungrouped"] = acc["ungrouped"] || [];
      acc["ungrouped"].push(event);
    }
    return acc;
  }, {});

  return (
    <div>
      <div style={{ marginTop: "0px",  display: "flex" }}>
        <Select
          value={preferenceOptions.find(
            (option) => option.value === selectedPreferenceIndex
          )}
          onChange={handlePreferenceChange}
          options={preferenceOptions}
          isClearable
          placeholder="Select Preference"
          styles={customStyles}
        />
        <button
          className="button"
          style={{ height: 38, padding:7,  marginTop:5,  }}
          onClick={() => saveOrUpdateFilterPreference()}
        >
          Save/Update{" "}
        </button>

        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleChange}
          className="inputtext search"
          style={{ height: 35, marginLeft:30, marginTop:5,  }}
        />
      </div>

      <div style={{ marginTop: "0px", marginBottom: "10px", display: "flex" }}>
        <div style={{ marginRight: "10px" }}>
          <Select
            value={selectedstartDate}
            onChange={setSelectedstartDate}
            options={startDate}
            isClearable
            placeholder="Filter by Date"
            components={{ DropdownIndicator }}
            styles={customStyles}
          />
        </div>

        <div style={{ marginRight: "10px" }}>
          <Select
            value={selectedClient}
            onChange={setSelectedClient}
            options={clientOptions}
            isClearable
            placeholder="Filter by Client"
            components={{ DropdownIndicator }}
            styles={customStyles}
          />
        </div>

        <Select
          value={selectedPosition}
          onChange={setSelectedPosition}
          options={positionOptions}
          isClearable
          placeholder="Filter by Position"
          components={{ DropdownIndicator }}
          styles={customStyles}
        />
        <Select
          value={selectedSet}
          onChange={setSelectedSet}
          options={setOption}
          isClearable
          placeholder="Filter by Set"
          components={{ DropdownIndicator }}
          styles={customStyles}
        />
        <Select
          value={selectedLocation}
          onChange={setSelectedLocation}
          options={locationOptions}
          isClearable
          placeholder="Filter by Location"
          components={{ DropdownIndicator }}
          styles={customStyles}
        />
      </div>
      <button className="button" onClick={markTheOrder}      style={{ height: 38, padding:7,  marginTop:5,  marginBottom: "10px" }}>
          mark the orders
        </button>
        <button className="button-red" onClick={RestoreTheOrder}      style={{ height: 38, padding:7,  marginTop:5,  marginBottom: "10px" }}>
        Restore Deleted Order
        </button>
        

        <div className="content-wrapper content-wrapper-talent-list">
  <div className="list-tasks-header" style={{ display: "flex" }}>
    <div style={{ flexBasis: "40px" }}></div>
    <div style={{ flexBasis: "150px" }}>groups / Processing by</div>
    <div style={{ flexBasis: "150px" }}>start/end</div>
    <div style={{ flexBasis: "100px" }}>client</div>
    <div style={{ flexBasis: "100px" }}>studio location</div>
    <div style={{ flexBasis: "100px" }}>set</div>
    <div style={{ flexBasis: "80px" }}>kpi</div>
    <div style={{ flexBasis: "150px" }}>position</div>
    <div style={{ flexBasis: "100px" }}>Model</div>
    <div style={{ flexBasis: "150px" }}>Created at</div>
    <div style={{ flexBasis: "150px" }}>S. Info</div>
    <div style={{ flexBasis: "150px" }}></div>
  </div>

  {sortedAndFilteredEvents.map((event) => {
    const [datePart, timePart] = event.createdAt.split(", ");
    const [day, month, year] = datePart.split("/").map((num) => parseInt(num, 10));
    const date = new Date(year, month - 1, day);
    const formattedDate = extractAndFormatDate(event.createdAt);
    const shouldDisplayIcon = !event.group || !processedGroups.has(event.group);
    if (event.group) {
      processedGroups.add(event.group);
    }
    return (
      <div className="list-tasks-item" style={{ display: "flex" }} key={event.id}>
        <div style={{ flexBasis: "40px" }} className="custom-checkbox">
          <input
            type="checkbox"
            id={`checkbox-${event.id}`}
            checked={!!selectedOrders[event.id]}
            onChange={() => handleCheckboxChange(event.id)}
          />
          <label htmlFor={`checkbox-${event.id}`}></label>
        </div>
        <div style={{ flexBasis: "150px" }}>
          {event.groupColor ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: event.groupColor,
                color: "#ffffff",
                padding: "5px 8px",
                borderRadius: "0",
                fontSize: "0.8em",
              }}
            >
              {event.group}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#ffffff",
                padding: "5px 8px",
                borderRadius: "0",
                fontSize: "0.8em",
              }}
            ></div>
          )}
          / {event.proposedBy}
        </div>
        <div style={{ flexBasis: "150px" }}>
          {new Date(event.start).toLocaleDateString()}
          <br /> {event.startTime}- {event.endTime}
        </div>
        <div style={{ flexBasis: "100px" }}>{event.client}</div>
        <div style={{ flexBasis: "100px" }}>
          {event.studio}/ {event.location}
        </div>
        <div style={{ flexBasis: "100px" }}>{event.set}</div>
        <div style={{ flexBasis: "80px" }}>{event.kpi}</div>
        <div style={{ flexBasis: "150px" }}>{event.position}</div>
        <div style={{ flexBasis: "100px" }}>{event.range}</div>
        <div style={{ flexBasis: "150px" }}>{formattedDate}</div>
        <div style={{ flexBasis: "150px" }}>{event.SonstigeInfo}</div>
        <div style={{ flexBasis: "150px" }}>
        <a href={`detail?id=${event.bookingId}`} style={{ color: 'blue', textDecoration: 'underline', paddingRight: 5 }}>
                        <img src={edit} alt="Edit" className="edit-container" />
                    </a>
        </div>
      </div>
    );
  })}
</div>


    
   
    </div>
  );
};

export default DeletedOrdersList;
