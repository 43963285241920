import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdown from './../Dropdown';
import { signOut, auth } from './../../firebaseConfig';
import Legend from '../Legend';
import NotificationPanel from './../NotificationPanel';
import bell from './../../images/bell.svg'
import bellactive from './../../images/bell-active.svg'
import tobslogo from './../../images/tobs-txt.svg'

const bokking = [
  { title: 'All Order', path: '/cs/allbooking' },
  { title: 'Create Order', path: '/cs/new' },
  { title: 'Proposed Orders', path: '/cs/proposed' },
  { title: 'Import', path: '/cs/upload' },
  { title: 'Group', path: '/cs/allgroups' },


  // add more items here...
];

const talent = [
  { title: 'Rate Talent', path: '/user/rate-talent' },
  { title: 'Talent Overview', path: '/user/rate-talent' },
  // add more items here...
];

const company = [
  { title: 'add Company', path: '/user/add-company' },
  // add more items here...
];



function ClinetServiceNavbar() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdowntalent, setDropdowntalent] = useState(false);
  const [dropdowncompany, setDropdowncompany] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const notificationRef = useRef(null); // Ref for the notification panel

  // Event handler for click
  const handleClickOutside = (event) => {
    if (notificationRef.current && !notificationRef.current.contains(event.target)) {
      setShowNotifications(false);
    }
  };

  // useEffect to add event listener
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleNotificationsRead = (areUnreadNotifications) => {
      setHasUnreadNotifications(areUnreadNotifications);
    };
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        // ...
        localStorage.setItem("userRole", null); //
      })
      .catch((error) => {
        // An error occurred.
        console.error("Logout error:", error);
      });
  }


  return (
    <>
      <div className='black-bg'>
        <div className='content-wrapper'>

          <nav className='navbar' onMouseLeave={() => { setDropdowncompany(false); setDropdowntalent(false); setDropdown(false) }}>
            <Link to='/cs/' className='navbar-logo' onClick={closeMobileMenu}>
            <img style={{height:60}} src={tobslogo} />

            </Link>
            <div className='menu-icon' onClick={handleClick}>
              <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              <li className='nav-item' style={{ marginRight: '25px' }}>
              <div className='legend-button-area'>
              <button className='legend-button' onClick={() => setIsModalOpen(true)} style={{ cursor: "pointer", backgroundColor: "#121212", border: "none", margin: 0, paddingTop: "2px" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 309.52 699.16" width="8px" height="auto">
                  <path class="st0" d="M140.39,151.32c39.76,0,73.41-32.62,73.41-73.39S180.15,4.55,140.39,4.55c-39.73,0-73.36,32.62-73.36,73.39
                      C67.02,118.7,100.66,151.32,140.39,151.32z" fill="#ffffff"/>
                  <path class="st0" d="M254.55,625.26c-28.52,0-40.76-11.21-40.76-40.77V215.52H5.86v67.28h46.87c28.55,0,40.79,11.21,40.79,40.77
                      v260.92c0,29.56-12.24,40.77-40.79,40.77H5.86v67.27h298.63v-67.27H254.55z" fill="#ffffff" /></svg>
                  </button>
                  <Legend isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
              </div>
              </li>
              <li className='nav-item' style={{ marginRight: '25px' }}>
                <Link to='/cs/profile'
                  onMouseEnter={() => setDropdown(false)}
                >
                  <button style={{ cursor: "pointer", padding: 5, backgroundColor: "#121212", border: "none", margin: 0, height: "24px", paddingTop: 2, fontSize: 18, fontWeight: 900 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.6 92.6" width="20px" height="auto"><circle cx="46.4" cy="35.9" r="16.7" transform="translate(-5.15 7.9) rotate(-9.22)" fill="#ffffff" stroke-width="0" /><path d="m46.3,0C20.8,0,0,20.7,0,46.3s20.8,46.3,46.3,46.3,46.3-20.8,46.3-46.3S71.8,0,46.3,0Zm27.2,75.5c-3.4-9.1-10.8-18.8-27.2-18.8s-23.8,9.7-27.2,18.8c-7.8-7.3-12.8-17.7-12.8-29.2C6.3,24.3,24.2,6.3,46.3,6.3s40,17.9,40,40c0,11.5-5,21.9-12.8,29.2Z" fill="#ffffff" stroke-width="0" /></svg>
                  </button>
                </Link>
                <p style={{ color: "#ffffff", margin: "0 5px" }}> Hello {localStorage.getItem("userEmail")}! </p>
                <button style={{ cursor: "pointer", padding: 5, backgroundColor: "#121212", border: "none", margin: 0, height: "24px", paddingTop: 2, fontSize: 18, fontWeight: 900 }} onClick={handleLogout} onMouseEnter={() => setDropdown(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198.4 198.5" width="20px" height="auto">
                    <path d="m197.8,102.2c.2-.7.4-1.4.4-2.1,0-.3.2-.5.2-.8s-.1-.5-.2-.8c-.1-.7-.2-1.4-.4-2.1-.2-.6-.4-1.2-.7-1.7-.2-.3-.2-.6-.4-.9l-19.8-29.8c-.2-.3-.4-.6-.6-.9-3.3-4.4-9.5-5.3-13.9-2-4.4,3.3-5.3,9.5-2,13.9l9.6,14.4h-60.9c-5.5,0-9.9,4.4-9.9,9.9s4.4,9.9,9.9,9.9h60.9l-9.6,14.4c-1.1,1.6-1.6,3.5-1.6,5.4,0,5.5,4.4,9.9,9.9,10,3.3,0,6.4-1.6,8.2-4.4l19.8-29.8v-.1c.2-.3.3-.6.4-.9.4-.6.6-1.1.7-1.6Z" fill="#ffffff" stroke-width="0" />
                    <path d="m138.9,125.9c-5.5,0-9.9,4.4-9.9,9.9v32.8c0,5.5-4.4,9.9-9.9,9.9H29.8c-5.5,0-9.9-4.4-9.9-9.9V29.8c0-5.5,4.4-9.9,9.9-9.9h89.3c5.5,0,9.9,4.4,9.9,9.9v27.9c0,5.5,4.4,9.9,9.9,9.9s9.9-4.4,9.9-9.9v-27.9c0-16.4-13.3-29.8-29.8-29.8H29.8C13.3,0,0,13.3,0,29.8v138.9c0,16.4,13.3,29.8,29.8,29.8h89.3c16.4,0,29.8-13.3,29.8-29.8v-32.8c-.1-5.6-4.5-10-10-10Z" fill="#ffffff" stroke-width="0" /></svg>
                </button>
              </li>

              <li className='nav-item' id='has-sub' >
                <div style={{ display: 'inline-block' }}>
                  <Link
                    to='/cs/allbooking'
                    className='nav-links'
                    onMouseEnter={() => setDropdown(true)}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div style={{ marginRight: '1px' }}>
                      <svg version="1.1" id="Isolationsmodus" x="0px" y="0px" viewBox="0 0 322.9 257.78" shapeRendering="geometricPrecision" style={{ width: '15px', height: '9px' }} className='custom-idrop' >
                        <path xmlns="http://www.w3.org/2000/svg" d="M208.27,7.37c-4.69-4.79-11.12-7.4-17.72-7.81c-0.4-0.03-0.8-0.05-1.22-0.05l0,0h-35.34h-70.2H31.14h-4.27  C17.33-0.46,7.87,4.51,3.34,13.12C-1.19,21.65-1.3,32.3,4.19,40.47c0.85,1.3,1.74,2.56,2.6,3.82C17.3,59.73,27.77,75.2,38.27,90.64  c12.47,18.33,24.91,36.7,37.39,55.03c3.08,4.53,6.13,9.02,9.21,13.54c3.97,5.86,9.06,10.5,16.08,12.36  c6.87,1.86,14.85,0.93,20.9-2.93c3.79-2.45,6.24-5.12,8.8-8.79c8.06-11.54,15.82-23.3,23.76-34.96  c13.14-19.33,26.25-38.67,39.39-57.96c4.68-6.87,9.32-13.73,14-20.63c4.31-6.31,8.39-12.13,8.2-20.41  C215.85,18.95,213.1,12.34,208.27,7.37z" />
                      </svg>
                    </div>
                    Orders & bookings
                  </Link>
                </div>
                {dropdown && <Dropdown menuItems={bokking} onMouseLeave={() => setDropdown(false)} />}
              </li>

              <li className='nav-item' id='has-sub'  nMouseEnter={() => setDropdown(false)} >
                <div style={{ display: 'inline-block' }}>
                  <Link
                    to='/cs/rate-talent'
                    className='nav-links'
                  
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                 
                    Rate Talent
                  </Link>
                </div>
                
              </li>

              <li className='nav-item' id='has-sub'  nMouseEnter={() => setDropdown(false)} >
                <div style={{ display: 'inline-block' }}>
                  <Link
                    to='/cs/talentoverview'
                    className='nav-links'
                  
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                 
                   Talent Overview
                  </Link>
                </div>
                
              </li>

              {/*<li className='nav-item'>
          <div style={{ display: 'inline-block' }}>
            <Link
              to='/cs/company-list'
              className='nav-links'
              onMouseEnter={() => setDropdowncompany(true)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
                        <div style={{ marginRight: '1px', marginTop: '2px' }}>
                          <svg version="1.1" id="Isolationsmodus" x="0px" y="0px" viewBox="0 0 322.9 257.78" shapeRendering="geometricPrecision" style={{ width: '15px', height: 'auto' }} className='custom-idrop' >
                            <path xmlns="http://www.w3.org/2000/svg" d="M208.27,7.37c-4.69-4.79-11.12-7.4-17.72-7.81c-0.4-0.03-0.8-0.05-1.22-0.05l0,0h-35.34h-70.2H31.14h-4.27  C17.33-0.46,7.87,4.51,3.34,13.12C-1.19,21.65-1.3,32.3,4.19,40.47c0.85,1.3,1.74,2.56,2.6,3.82C17.3,59.73,27.77,75.2,38.27,90.64  c12.47,18.33,24.91,36.7,37.39,55.03c3.08,4.53,6.13,9.02,9.21,13.54c3.97,5.86,9.06,10.5,16.08,12.36  c6.87,1.86,14.85,0.93,20.9-2.93c3.79-2.45,6.24-5.12,8.8-8.79c8.06-11.54,15.82-23.3,23.76-34.96  c13.14-19.33,26.25-38.67,39.39-57.96c4.68-6.87,9.32-13.73,14-20.63c4.31-6.31,8.39-12.13,8.2-20.41  C215.85,18.95,213.1,12.34,208.27,7.37z" />
                          </svg>
                        </div>
             company
            </Link>
            </div>
            {dropdowncompany && <Dropdown menuItems={company} onMouseLeave={() => setDropdowncompany(false)} />}
              </li>                 
              <li className='nav-item' id='has-sub' >
                <div style={{ display: 'inline-block' }}>
                    <Link
                        to='/cs/profile'
                        className='nav-links'
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                    Profile
                    </Link>
                </div>
              </li>*/}
            </ul>
            <div>
            <button
                className='notification-bell'
                style={hasUnreadNotifications ? { width: '20px' } : { width: '17px' }}
                onClick={() => setShowNotifications(!showNotifications)}>
                <img src={hasUnreadNotifications ? bellactive : bell} alt="Notifications" />
            </button>

  <div ref={notificationRef}  className={`notification-canvas ${!showNotifications ? 'hidden' : ''}`}>
    <NotificationPanel userId={localStorage.getItem("userEmail")} userRole={localStorage.getItem("userRole")} onNotificationsRead={handleNotificationsRead} />
           
  </div>
</div>
          </nav>
          
        </div>
      </div>
    </>
  );
}

export default ClinetServiceNavbar;