import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { db } from './../firebaseConfig'; // your Firebase config
import {
  
  collection,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
  orderBy,
  getDocs,
  writeBatch,
} from 'firebase/firestore';
import '../App.css';

function NotificationPanel({ userId, onNotificationsRead, userRole }) {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const notificationsCollection = collection(
      db,
      `SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications`
    );

    // Query for notifications targeted at the specific user
    const userQuery = query(
      notificationsCollection,
      where('userId', '==', userId),
      orderBy('createdAt', 'desc')
    );

    // Query for notifications targeted at the user's role
    const roleQuery = query(
      notificationsCollection,
      where('userId', '==', userRole),
      orderBy('createdAt', 'desc')
    );

    // Function to fetch and merge notifications from both queries
    const fetchNotifications = async () => {
      const [userSnapshot, roleSnapshot] = await Promise.all([
        getDocs(roleQuery),
        getDocs(userQuery),
      ]);

      // Merge the results, filtering out duplicates
      let fetchedNotifications = [
        ...userSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ...roleSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      ];
      // Remove duplicates if any doc appears in both user and role snapshots
      const uniqueNotifications = fetchedNotifications.filter(
        (v, i, a) => a.findIndex(t => (t.id === v.id)) === i
      );

      // Sort the merged notifications by createdAt in descending order
      uniqueNotifications.sort((a, b) => b.createdAt - a.createdAt);

      setNotifications(uniqueNotifications);
      // Trigger the callback with the status of unread notifications
      const areUnreadNotifications = fetchedNotifications.some(
        (notification) => !notification.read
      );
      onNotificationsRead(areUnreadNotifications);
      console.log('***************');
      console.log(areUnreadNotifications);
      console.log('***************');
    };

    fetchNotifications();

    // Since Firestore doesn't support real-time 'OR' queries, we subscribe to both queries separately
    const userUnsub = onSnapshot(userQuery, (snapshot) => {
      const fetchedNotifications = snapshot.docs
        .filter((doc) => doc.data().userId !== userRole)
        .map((doc) => ({ id: doc.id, ...doc.data() }));
      setNotifications((prev) => [...prev,]);
    });

    const roleUnsub = onSnapshot(roleQuery, (snapshot) => {
      const fetchedNotifications = snapshot.docs
        .filter((doc) => doc.data().userId !== userId)
        .map((doc) => ({ id: doc.id, ...doc.data() }));
      setNotifications((prev) => [...prev,]);
    });

    // Unsubscribe from both queries on cleanup
    return () => {
      userUnsub();
      roleUnsub();
    };
  }, [userId, userRole, onNotificationsRead]);

  const markAsRead = async (notificationId) => {
    const notificationDocRef = doc(
      db,
      `SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications`,
      notificationId
    );
    await updateDoc(notificationDocRef, { read: true }).then(() => {
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === notificationId
            ? { ...notification, read: true }
            : notification
        )
      );


    });
    // Calculate the unread status after marking as read
    const areUnreadNotifications = notifications.some(
      (notification) => !notification.read
    );

    onNotificationsRead(areUnreadNotifications); // Send the updated unread status


  };

  const deleteOldNotifications = async () => {
    const twoDaysAgo = new Date();
    twoDaysAgo.setDate(twoDaysAgo.getDate() - 1);

    const oldNotificationsQuery = query(
      collection(db, `/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/notifications`),
    //  where('createdAt', '<', twoDaysAgo)
    );

    const batch = writeBatch(db);
    const oldNotifications = await getDocs(oldNotificationsQuery);
    oldNotifications.forEach((doc) => {
      batch.delete(doc.ref);
    });

    await batch.commit();
    console.log('Old notifications deleted');
  };


  return (
    <div className="notification-panel">
      {notifications.map((notification) => (
        <div
          key={notification.id}
          className={`notification-item ${!notification.read ? 'unread' : ''}`}
          onClick={() => {
            !notification.read && markAsRead(notification.id);
            navigate(`/${notification.url}`);
          }} // Only mark as read if it is unread
        >
          <ul>
            <li>{notification.message}</li>
          </ul>

        </div>
      ))}
   {/*<button className='button' onClick={deleteOldNotifications} >delete all</button> */} 

    </div>
  );
}

export default NotificationPanel;
