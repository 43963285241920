import React, { useState, useEffect } from 'react';
import Navbar from './components/NavBars/Navbar';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Routes, useNavigate } from 'react-router-dom';
import Products from './components/pages/Products';
//import Form from './components/pages/oldForm';
import Contactus from './components/pages/ContactUs';
import { signInWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";
import tobslogo from './images/tobs-txt.svg'

import { collection, addDoc, getDocs, deleteDoc, getDoc, doc, updateDoc } from "firebase/firestore";
import { auth, db } from "./firebaseConfig";
import { authenticateAndGetToken } from "./helpers/auth";
import Edit from './components/pages/Edit';
import BookingCalendar from './components/pages/BookingCalendar';
import AddTalent from './components/pages/AddTalent';
import TalentList from './components/pages/TalentList';
import CompanyList from './components/pages/CompanyList';
import AddCompany from './components/pages/AddCompany';
import LanoCreateATask from './components/pages/LanoCreateATask';
import AppNavbar from './components/AppNavbar'
import ProtectedRoute from './helpers/ProtectedRoute'; 
import RoleContext from './helpers/RoleContext'; // import the context
import NoPrivilages from './components/pages/NoPrivilages';
import NewBookingCreationPage from './components/pages/ClientService/NewBookingCreationPage';
import ProposeTalentsBookingDetail from './components/SetCastUser/ProposeTalentsBookingDetail';
import NewBookingsPage from './components/SetCastUser/NewBookingsPage';
import MyBookingsPage from'./components/pages/Talent/MyBookingsPage'
function LoginForm() {
    const navigate = useNavigate();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [resetFeedback, setResetFeedback] = useState('');

    useEffect(() => {
      console.log(errorMessages);
  }, [errorMessages]);
  
  const renderErrorMessage = () => {
    if (errorMessages.name === "general") {
        return <p className="error">{errorMessages.message}</p>;
    }
    return null;
};

  
    const handleSubmit = async (event) => {
        event.preventDefault();
       
    
        var { uname, pass } = document.forms[0];
    
        try {
          const userCredential = await signInWithEmailAndPassword(auth, uname.value, pass.value);
          // Signed in
          var user = userCredential.user;
          localStorage.setItem("isAuthenticated", true);
          localStorage.setItem("userId", user.uid);
          setIsSubmitted(true);
    
          // Fetch user data from Firestore
          const userDocRef = doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData", user.uid);
          const userDocSnap = await getDoc(userDocRef);
    
          if (userDocSnap.exists()) {
           
            console.log("User role: ", userDocSnap.data().role); // This is the role of the user
            localStorage.setItem("userRole", userDocSnap.data().role); // Store the user's role in localStorage
            localStorage.setItem("userEmail", userDocSnap.data().email);
            localStorage.setItem("curentuser", userDocSnap.data());
      
            if((localStorage.getItem("userRole"))=='talent'){
            
            // localStorage.setItem("talentid", userDocSnap.data().talentID.split('/').pop());
          
             localStorage.setItem("currentTalentId", userDocSnap.data().talentID.id);
            
        
    
            }
    
            switch (localStorage.getItem("userRole")) {
              case 'superadmin':
              case 'setCastEditor':
                navigate('/admin');
                break;
              case 'editor':
                navigate('/user');
                break;
              case 'talent':
                navigate('/talent');
                break;
              case 'clientService':
                navigate('/cs');
                break;
              default:
                navigate('/np'); // or any default route
            }
    
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Firebase error:", error.message);
          setErrorMessages({ name: "general", message: "Incorrect email or password. Please try again." });
        }
      };
    
      const handlePasswordReset = async (email) => {
        try {
          await sendPasswordResetEmail(auth, email);

          setResetFeedback('Password reset email sent successfully.');
        } catch (error) {
          console.error('Error sending password reset email:', error);
          setResetFeedback('Error sending password reset email. Please try again later.');
        }
      };
      
    return (
      <div className="loginForm" >
        <form onSubmit={handleSubmit}>
          <div className="loginForm-container">
            <img style={{height:150}} src={tobslogo} />
            <label>
              <input type="text" name="uname" placeholder="email" required />
            </label>
    
            <label>
              <input type="password" name="pass" placeholder="password" required />
            </label>
              {renderErrorMessage()}
              <p className="password-reset-link" onClick={() => handlePasswordReset(document.forms[0].uname.value)}>Forgot your password?</p>
              <p className="password-reset-link" style={{ marginTop: '10px' }} >{resetFeedback}</p>
              <button type="submit" className="big-button">Login</button>

              
          </div>
        </form>
        <img style={{marginBottom: 50, height:50}} src={require('./images/logo-setcast.png')} />

      </div>
    );
  }
  

  export default LoginForm;