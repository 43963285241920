import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import './../../App.css';
import { collection, getDocs, doc, query, where, getDoc } from 'firebase/firestore';
import { db } from './../../firebaseConfig';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { NonceProvider } from 'react-select';
import CalendarEventStatusColor from './../CalendarEventStatusColor';
import CalendarToolbar from './../CalendarToolbar'
import edit from '../../images/edit.svg'
import 'moment/locale/de'  // or any locale that uses day-month-year format

import ListViewConfirmed from './ListViewConfirmed';

const localizer = momentLocalizer(moment);

class ConfirmedBooking extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            events: [],
            view: 'list',
        };
    }

    componentDidMount() {
        this.fetchBookings();
    }
    fetchTalent = async (talentId) => {
        const talentDoc = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data', talentId); // replace 'talents' with your actual talents collection name
        const talent = await getDoc(talentDoc);

        if (talent.exists) {
            return talent.data().Name; // assuming the talent document has a 'name' field
        } else {
            return 'New request';
        }
    }
    fetchCompany = async (companyID) => {
        const companyDoc = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1', companyID); // replace 'talents' with your actual talents collection name
        const company = await getDoc(companyDoc);

        if (company.exists) {
            return company.data().name; // assuming the talent document has a 'name' field
        } else {
            return 'deleted company';
        }
    }
    fetchBookings = async () => {
        const bookingCollection = collection(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings');
        const q = query(bookingCollection, where("status", "==", "confirmed")); // filter bookings by status directly from Firestore

        const bookingSnapshot = await getDocs(q);
        const bookings = bookingSnapshot.docs.map(bookingDoc => {
            const bookingData = bookingDoc.data();
            return {
                id: bookingDoc.id,
                ...bookingData,
            };
        });

        const events = await Promise.all(bookings.map(async (booking) => {
            const results = await Promise.allSettled([
                this.fetchTalent(booking.talentId),
                this.fetchCompany(booking.companyID)
            ]);
            const talentName = results[0].status === 'fulfilled' ? results[0].value : 'New request';
            const companyName = results[1].status === 'fulfilled' ? results[1].value : 'Deleted company';
            const clientName = booking.client ? booking.client.label : 'NULL';

            return {
                bookingId: booking.id,
                id: booking.id,
                boktitle: booking.title,
                set: booking.set,
                kpi: booking.kpi,
                talent: talentName,
                title: `Booking ${talentName} for ${companyName} (${booking.status})`,
                start: new Date(booking.start_date.seconds * 1000),
                end: new Date(booking.end_date.seconds * 1000),
                allDay: true,
                status: booking.status,
                group:booking.group,

                company: companyName,
                showDetail: false,
                client: clientName,
                position: booking.position.label,
                range: booking.range,
                segment: booking.segment,
                option: booking.option,
                startTime:booking.startTime,
                endTime:booking.endTime,
                customRate:booking.customRate,
                SonstigeInfo: booking.SonstigeInfo,
                location:booking.location
        
            };
        }));

        this.setState({ events });
    };


    handleSelectEvent = (event) => {
        const bookingId = event.bookingId;
        // Do something with the bookingId...
    };

    handleEventMouseEnter = (eventId) => {
        const updatedEvents = this.state.events.map((event) => {
            if (event.id === eventId) {
                return { ...event, showDetail: true }; // Set showDetail to true for the hovered event
            } else {
                return { ...event, showDetail: false }; // Set showDetail to false for all other events
            }
        });

        this.setState({ events: updatedEvents });
    };

    handleEventMouseLeave = (eventId) => {
        const updatedEvents = this.state.events.map((event) => {
            if (event.id === eventId) {
                return { ...event, showDetail: false }; // Set showDetail back to false for the event when mouse leaves
            }
            return event;
        });

        this.setState({ events: updatedEvents });
    };

    Event = ({ event }) => {

        let borderRadius;
        switch (event.set) {
            case 'On Model':
                borderRadius = '10px';
                break;
            case 'on Produkt':
                borderRadius = '0px';
                break;
        }
        return (
            <div className="my-div" style={{ cursor: 'auto', height: '100px', borderRadius: borderRadius }}

                onMouseEnter={() => this.handleEventMouseEnter(event.id)}
                onMouseLeave={() => this.handleEventMouseLeave(event.id)}
            >
                {event.showDetail && (
                    <div className='toltip'
                        style={{
                            position: 'absolute',
                            width: "600px",
                            height: "auto",
                            top: "10px",
                            left: "10px",
                            background: '#000000',
                            color: '#ffffff',
                            padding: '10px',
                            zIndex: 9999,
                            cursor: 'auto'
                        }}
                    >

                        <span style={{ position: 'relative', fontWeight: '600', color: '#fff', whiteSpace: 'pre-line' }}>
                            <div class="calendar-inner-cell-container">
                                <div>
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                                        {` Client `}
                                    </div>
                                    {` ${event.client} `}
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                                        {` Talent `}
                                    </div>
                                    {` ${event.talent} `}
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                                        {` Status `}
                                    </div>
                                    {` ${event.status} `}
                                </div>
                                <div>
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                                        {` Title `}
                                    </div>
                                    {` ${event.boktitle} `}
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                                        {` Set `}
                                    </div>
                                    {` ${event.set} `}
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                                        {` Kpi `}
                                    </div>
                                    {` ${event.kpi} `}
                                </div>
                                <div style={{ flexGrow: '1' }}>
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                                        {` Option `}
                                    </div>
                                    {` ${event.option} `}
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                                        {` Start time `}
                                    </div>
                                    {` ${moment(event.start).format('DD-MM-YYYY')} `}
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                                        {` End time `}
                                    </div>
                                    {` ${moment(event.end).format('DD-MM-YYYY')} `}
                                    <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                                        {` s.info `}
                                    </div>
                                    {` ${event.SonstigeInfo} `}
                                </div>
                            </div>
                        </span>

                    </div>
                )}

                <div>
                    <CalendarEventStatusColor status={event.status} event={event} />
                </div>
                <span style={{ position: 'relative', fontWeight: '600', color: '#000000', whiteSpace: 'pre-line' }}>
                <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.startTime}-  ${event.endTime}`}</span>


                    <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.client} `}</span>

                    <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.talent} `}</span>

                    <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.status} `}</span>
                </span>
            </div>
        );
    }

    switchView = (view) => {
        this.setState({ view });
    }

    render() {
        return (
            <section>
                <div className='content-wrapper'>

                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px' }}>
                            <h1>confirmed orders</h1>
                            <button className={this.state.view === 'calendar' ? 'big-button' : 'big-button-active'} onClick={() => this.switchView('list')} style={{ marginLeft: 'auto' }}>List View</button>
                            <button className={this.state.view === 'calendar' ? 'big-button-active' : 'big-button'} onClick={() => this.switchView('calendar')}>Calendar View</button>
                        </div>

                        {this.state.view === 'calendar' ? (
                            <Calendar
                                localizer={localizer}
                                events={this.state.events}
                                startAccessor="start"
                                endAccessor="end"
                                defaultView='week'
                                style={{   flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflow: 'visible',}} 
                                onSelectEvent={event => this.handleSelectEvent(event)}
                                components={{
                                    event: this.Event,
                                    toolbar: CalendarToolbar,  // use the custom toolbar
                                }}
                                className="my-calendar"
                            />

                        ) : (
                            <ListViewConfirmed events={this.state.events} authToken={this.props.authToken} />
                        )}
                    </div>
                </div>
            </section>
        );
    }
}
export default ConfirmedBooking;
