import React ,{useState} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import './../../App.css';
import Select, { components } from 'react-select';
import { collection, getDocs, doc, getDoc, Timestamp, query, where, } from 'firebase/firestore';
import { db } from './../../firebaseConfig';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/de';
import edit from './../../images/edit.svg';
import editWhite from './../../images/edit-white.svg';
import { getWeek, startOfWeek, endOfWeek , startOfMonth, endOfMonth} from 'date-fns';
import PropTypes from 'prop-types';
import ListViewAllBookings from './ListViewAllBookings';

const customStyles = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    border: 'none',
  }),
  control: (base) => ({
    ...base,
    width: '400px',
    borderColor: 'black',
    borderWidth: '1px',
    boxShadow: 'none',
    margin: '5px',
    borderRadius: '0px',
    color: 'black',
    fontWeight: "700",
    height: '50px',
    textTransform: 'uppercase',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'black',
    fontWeight: "700"
  }),
  input: (provided) => ({
    ...provided,
    color: 'black',
    fontWeight: "700"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'black' : 'inherit',
    '&:hover': { backgroundColor: state.isSelected ? 'black' : '#f7f7f7' }
  }),
};

// Month names map
const monthNames = {
  'JANUAR': 0,
  'FEBRUAR': 1,
  'MÄRZ': 2,
  'APRIL': 3,
  'MAI': 4,
  'JUNI': 5,
  'JULI': 6,
  'AUGUST': 7,
  'SEPTEMBER': 8,
  'OKTOBER': 9,
  'NOVEMBER': 10,
  'DEZEMBER': 11
};

moment.locale('de')



//import 'moment/locale/de'; // Import the locale you want. In this case, German (Deutschland).

//const localizer = momentLocalizer(moment.locale('de')); // Set the locale to 'de' (German).
class CustomToolbar extends React.Component {
  constructor(props) {
    super(props);
    // We don't need to maintain isWeekView in the state anymore
  }

  render() {
    let { label, currentView } = this.props;

    // Extract the month and start day from the label.
    const month = label.split(" ")[0].toUpperCase();
    const day = label.split(" ")[1].split("–")[0];

    // Construct the date using the extracted day and month.
    let date = new Date();
    date.setMonth(monthNames[month]);
    date.setDate(day);
    date = startOfWeek(date);

    // Get the week number using date-fns
    let weekNumber = getWeek(date);

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '40px' }}>
        <div>
          <button className='button' onClick={() => this.navigate('TODAY')}>today</button>
          <button className='button' onClick={() => this.navigate('PREV')}>Prev</button>
          <button className='button' onClick={() => this.navigate('NEXT')}>Next</button>
        </div>
        <div className='weeknumber-wrapper'>
          {currentView === 'week' ? (
            <label>Week {weekNumber}: <strong>{label}</strong></label>
          ) : (
            <label><strong>{label}</strong></label>
          )}
        </div>
        <div>
          <button className='button' onClick={() => this.view('week')}>Week</button>
          <button className='button' onClick={() => this.view('agenda')}>Day</button>
          <button className='button' onClick={() => this.view('month')}>Month</button>
        </div>
      </div>
    );
  }

  navigate = action => {
    const { date, currentView } = this.props;  // current date and view type from the Calendar's props
    let newDate;

    switch (action) {
      case 'NEXT':
        newDate = currentView=== 'month' ? moment(date).add(1, 'month') : moment(date).add(1, 'week');
      
        break;
      case 'PREV':
        newDate = currentView=== 'month' ? moment(date).subtract(1, 'month') : moment(date).subtract(1, 'week');
        break;
      
      case 'TODAY':
        newDate = moment();
        break;
      default:
        newDate = date;
    }

    // Notify the parent component
    this.props.onNavigate(action, newDate.toDate());

    // Notify about the week change
    if (currentView !== 'month') {
      // Notify about the week change only if the view is not 'month'

      this.props.onWeekChange(startOfWeek(newDate.toDate()), endOfWeek(newDate.toDate()));
    }
  }



  view = viewType => {
    this.props.onChangeView(viewType);
  }
  
}

const localizer = momentLocalizer(moment);

class AllBookings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      loading: false,
      startOfWeek: startOfWeek(new Date()), // default to current week
      endOfWeek: endOfWeek(new Date()),
      currentDate: new Date(), // This will represent the current week's start date
      selectedUserEmail: localStorage.getItem("userEmail"),

      userList: [],
      view: 'calendar',
      currentView: 'month', 
      selectedClinet: ' ',
      selectedBookingStatus: '',
      selectedSet:null,
      filteredBookings: [], 
      setSelectedOptions: [],
      selectedClient:null,
      locationOptions: [], // Holds the bundled location options
      selectedLocation: null, // Tracks the selected location
    
    };
    this.handleUserSelection = this.handleUserSelection.bind(this);
    this.handleBookingStatusSelection = this.handleBookingStatusSelection.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.handleClientSelection=this.handleClientSelection.bind(this);

  }
  componentDidUpdate(prevProps, prevState) {
    // Check if the week has changed
    if (prevState.startOfWeek !== this.state.startOfWeek || prevState.endOfWeek !== this.state.endOfWeek) {
      this.fetchBookings();

    }
  }
  

  componentDidMount() {
    this.fetchClientsAndSets();

    this.fetchBookings();
    this.fetchUserList();
    this.fetchLocations(); 
  }
  changeView = (viewType) => {
    this.setState({ currentView: viewType });
  }
  onNavigate = (action, newDate) => {
    // Check the current view to determine how to set the state
    if (this.state.currentView === 'month') {
      // If the current view is month, set the state to the start and end of the month
      this.setState({
        startOfWeek: startOfMonth(newDate),
        endOfWeek: endOfMonth(newDate),
        currentDate: newDate // Update the current date when navigating
      });
    } else {
      // For week view or other views, keep the original behavior
      this.setState({
        startOfWeek: startOfWeek(newDate),
        endOfWeek: endOfWeek(newDate),
        currentDate: newDate
      });
    }
  }
  


  onWeekChange = (start, end) => {
    this.setState({
      startOfWeek: start,
      endOfWeek: end
    }, this.fetchBookings);  // Fetch bookings after the week is changed
  }


  fetchTalent = async (talentId) => {
    const talentDoc = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data', talentId); // replace 'talents' with your actual talents collection name
    const talent = await getDoc(talentDoc);

    if (talent.exists) {
      return talent.data().Name; // assuming the talent document has a 'name' field
    } else {
      return 'no name';
    }
  }

  fetchCompany = async (companyID) => {
    const companyDoc = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1', companyID); // replace 'talents' with your actual talents collection name
    const company = await getDoc(companyDoc);

    if (company.exists) {
      return company.data().name; // assuming the talent document has a 'name' field
    } else {
      return 'deleted company';
    }
  }

  fetchUserList = async () => {
    // Assuming you have a users collection where user documents have a role field
    const userCollectionRef = collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData");

    // Query for superadmin
    const qSuperAdmin = query(userCollectionRef, where("role", "==", "superadmin"));
    const superAdminSnapshot = await getDocs(qSuperAdmin);

    // Query for setCastEditor
    const qSetCastEditor = query(userCollectionRef, where("role", "==", "setCastEditor"));
    const setCastEditorSnapshot = await getDocs(qSetCastEditor);

    // Merge the results
    const userList = [...superAdminSnapshot.docs, ...setCastEditorSnapshot.docs].map(doc => {
      const data = doc.data();
      return { email: data.email, name: data.email };  // Assuming each user has an email and name field
    });
    userList.unshift({ email: 'all', name: 'All' });

    this.setState({ userList });
  };

  fetchClientsAndSets = async () => {
    // Example fetch calls - adjust to your data sources
    const clientData = await getDocs(collection(db, '/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/company'));
    const setData = await getDocs(collection(db, 'sets'));

    const clientOptions = clientData.docs.map(doc => ({
        value: doc.name, // Assuming doc.id is the identifier for a client
        label: doc.data().name // Assuming doc.data().name is the name of the client
    }));

    const setOptions = [
      { value: "onmodel", label: "On Model" },
      { value: "Produkt", label: "On Produkt" },
    ];

    this.setState({ clientOptions, setOptions });
}

handleLocationSelection = (selectedOption) => {
  this.setState({ selectedLocation: selectedOption }, () => {
      this.applyFilters();
  });
};

  handleUserSelection(selectedUser) {
    this.setState({ selectedUserEmail: selectedUser.value }, () => {
      this.fetchBookings();
    });
  }
  handleBookingStatusSelection = (selectedStatus) => {
    this.setState({ selectedBookingStatus: selectedStatus ? selectedStatus.value : null }, () => {
      this.fetchBookings();
    });
  }
  handleClientSelection = selectedOption => {
    this.setState({ selectedClient: selectedOption }, () => {
        this.applyFilters();
    });
};


handleSetSelection = selectedOption => {
    this.setState({ selectedSet: selectedOption }, () => {
      this.applyFilters();
  });
};




  fetchBookings = async () => {
    this.setState({ loading: true });
    console.log("Fetching bookings...");

    const startTimestamp = Timestamp.fromDate(moment(this.state.startOfWeek).subtract(30, 'days').toDate());
    const endTimestamp = Timestamp.fromDate(this.state.endOfWeek);
    const { selectedUserEmail } = this.state;
    const { selectedBookingStatus } = this.state;
    const bookingCollectionRef = collection(doc(db, 'SetCast CTA GmbH', 'bIETrNIclVpe0eINr1Z1'), 'Bookings');

    // Query based on start_date and selected user email for processingBy

    let initialQuery;

    if (selectedUserEmail === 'all') {
      // If 'All' is selected, ignore the processingBy filter
      initialQuery = query(
        bookingCollectionRef,
        where("start_date", ">=", startTimestamp)
      );
    } else {
      // Otherwise, filter by processingBy
      initialQuery = query(
        bookingCollectionRef,
        where("start_date", ">=", startTimestamp),
        where("proposedBy", "==", selectedUserEmail)
      );
    }
    let q = initialQuery;
 // Extend query with status if selectedBookingStatus is neither null nor 'All'
 // Extend the query with the status filter only if selectedBookingStatus is not null or 'All'
 if (selectedBookingStatus && selectedBookingStatus !== 'All') {
  q = query(
    q,
    where("status", "==", selectedBookingStatus)
  );
}


    const processingBySnapshot = await getDocs(q);

    const filteredDocs = processingBySnapshot.docs
    .filter(doc => {
        const status = doc.data().status;
        return status !== 'canceled' && status !== 'deleted'  && status !== 'talentOnHold'; // Exclude both canceled and deleted events
    })
    .filter(doc => {
        const data = doc.data();
        console.log("Filtering data:", data);
        return data; // Assuming there is additional filtering logic you want to keep
    });


    const bookings = filteredDocs.map(doc => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
        group:data.group,
        groupColor:data.groupColor
      };
    });

    
    console.log("Processing bookings:", bookings);

    const events = await Promise.all(bookings.map(async (booking) => {
      const results = await Promise.allSettled([
        this.fetchTalent(booking.talentId),
        this.fetchCompany(booking.companyID)
      ]);

      const talentName = results[0].status === 'fulfilled' ? results[0].value : 'New request';
      const companyName = results[1].status === 'fulfilled' ? results[1].value : 'Deleted company';
      const start = booking.start_date ? moment(booking.start_date.toDate()) : null;
      const end = booking.end_date ? moment(booking.end_date.toDate()) : null;


      const clientName = booking.client ? booking.client.label : 'NULL';
      return {
        start: new Date(booking.start_date.seconds * 1000),
        end: new Date(booking.end_date.seconds * 1000),
        bookingId: booking.id,
        group:booking.group,
        groupColor:booking.groupColor,
        id: booking.id,
        boktitle: booking.title,
        set: booking.set,
        kpi: booking.kpi,
        talent: talentName,
        title: `Booking ${talentName} for ${companyName} (${booking.status})`,
        client: clientName,
        allDay: true,
        option: booking.option,
        status: booking.status,
        company: companyName,
        showDetail: false,
        proposedBy: booking.proposedBy,
        position: booking.position?.label ?? '',  // Using optional chaining and nullish coalescing
        positionwlabel: booking.position ?? {}, 
        startTime:booking.startTime,
        endTime:booking.endTime,
        location:booking.location,
        SonstigeInfo:booking.SonstigeInfo,
      };
    }));

    console.log("Final events:", events); // 4. After All Transformations

    this.setState({ events, loading: false }), this.applyFilters();


  };


  fetchLocations = async () => {
    const locationSnap = await getDocs(collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/locations"));
    const locationData = locationSnap.docs.map(doc => ({
        city: doc.data().city,
        short: doc.data().short
    }));
  
    const cityBundles = locationData.reduce((acc, loc) => {
        if (!acc[loc.city]) {
            acc[loc.city] = [];
        }
        acc[loc.city].push(loc.short);
        return acc;
    }, {});
  
    const locationOptions = Object.keys(cityBundles).map(city => ({
        value: cityBundles[city], // array of shorts
        label: city + ` (${cityBundles[city].join(', ')})`
    }));
  
    this.setState({ locationOptions });
  };
  
  
  applyFilters = () => {
    const { events, selectedBookingStatus, selectedClient, selectedSet,selectedLocation } = this.state;
    let filteredBookings = events;


    if (selectedClient) {
        filteredBookings = filteredBookings.filter(booking => booking.client === selectedClient.label);
    }
    if (selectedSet) {
        filteredBookings = filteredBookings.filter(booking => booking.set === selectedSet.label);
    }

   // Filter by location
   if (selectedLocation) {
    // Filter bookings where the location is one of the 'shorts' in the selected city bundle
    filteredBookings = filteredBookings.filter(booking =>
        selectedLocation.value.includes(booking.location)
    );


  }

  
    console.log("this are filtredbookings to populate")
console.log(filteredBookings);
console.log("this are filtredbookings to populate")

    this.setState({ filteredBookings });
};

  

  handleSelectEvent = (event) => {
    const bookingId = event.bookingId;
    // Do something with the bookingId...
  };

  handleEventMouseEnter = (eventId) => {
    const updatedBookings = this.state.filteredBookings.map((event) => {
      if (event.id === eventId) {
        return { ...event, showDetail: true };
      } else {
        return { ...event, showDetail: false };
      }
    });

    this.setState({ filteredBookings: updatedBookings });
  };

  handleEventMouseLeave = (eventId) => {
    const updatedBookings = this.state.filteredBookings.map((event) => {
      if (event.id === eventId) {
        return { ...event, showDetail: false };
      }
      return event;
    });

    this.setState({ filteredBookings: updatedBookings });
  };


  Event = ({ event }) => {
    let statusColor;
    switch (event.status) {
      case 'confirmed':
      statusColor = '#15a326';
      break;
    case 'new order':
      statusColor = '#ffffff';
      break;
      case 'requested':
      statusColor = '#FF8806';
      break;
    case 'talentaccepted':
      statusColor = '#3de651';
      break;
    case 'declined request':
      statusColor = '#ff3c00';
      break;
    case 'optioned':
      statusColor = '#0060ff';
      break;
      case 'optionSolved':
        statusColor = '#FFFF00';
        break;
    case 'hold':
      statusColor = '#800080';
      break;
    case 'lano':
      statusColor = '#116c11';
      break;
      case 'Booked':
        statusColor = '#ADD8E6';
        break;
    default:
      statusColor = '#606060';
    }

    const groupBadge = event.groupColor ? (
      <div style={{
        display: 'inline-block',
        backgroundColor: event.groupColor,
        color: '#ffffff',
        padding: '3px 6px',
        borderRadius: '10px',
        fontSize: '0.8em',
        margin: '0 4px',
        verticalAlign: 'middle'
      }}>
        {event.group}
      </div>
    ) : null;
    return (
      <div className="my-div"
      onMouseEnter={() => this.handleEventMouseEnter(event.id)}
      onMouseLeave={() => this.handleEventMouseLeave(event.id)}
  >
        {event.showDetail && (
          <div 
            style={{
              position: 'fixed',
              width: "500px",
              height: "500px",
              top: "-300px",
              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 5px 0px',
              left: "50px",
              background: '#000000',
              color: '#ffffff',
              padding: '10px',
              zIndex: 9999,
            }}
          >

            <p>Start Date: {moment(event.start).format('DD-MM-YYYY')}</p>
            <p>End Date: {moment(event.end).format('DD-MM-YYYY')}</p>
            <p>Client: {event.client} </p>
            <p>Kpi: {event.kpi} </p>
            <p>Option: {event.option} </p>
            <p>Segment: {event.segment} </p>
            {/* Add more details as needed */}
          </div>
        )}
        <div style={{ height: 20, width: '100%' }}>
          <div style={{
            height: '7px',
            width: '7px',
            padding: '10px',
            backgroundColor: statusColor,
            borderRadius: '50%',
            display: 'inline-block',
            float: 'right'
          }} />
        </div>
        <span style={{ marginTop: '30px', paddingTop: '60px', position: 'relative', color: 'black', height: 150, whiteSpace: 'pre-line' }}>
          {`${event.boktitle}\nClient: ${event.client}\nSet: ${event.set}\nKpi: ${event.kpi}\nTalent: ${event.talent} \nOption: ${event.option},\nStatus: ${event.status},`}
        </span>

        <div style={{ position: 'absolute', right: 0, bottom: 0, paddingRight: '10px', paddingBottom: '10px' }}>
{groupBadge}
          <a href={`detail?id=${event.bookingId}`} style={{ color: 'blue', textDecoration: 'underline' }}>

            <img src={edit} alt="Edit" className="edit-container" />
          </a>
        </div>
      </div>
    );
  }



  WeekEvent = ({ event }) => {
    let statusColor;
    switch (event.status) {
      case 'confirmed':
        statusColor = '#15a326';
        break;
      case 'new order':
        statusColor = '#ffffff';
        break;
        case 'requested':
        statusColor = '#FF8806';
        break;
      case 'talentaccepted':
        statusColor = '#3de651';
        break;
      case 'declined request':
        statusColor = '#ff3c00';
        break;
      case 'optioned':
        statusColor = '#0060ff';
        break;
        case 'optionSolved':
          statusColor = '#FFFF00';
          break;
      case 'lano':
        statusColor = '#116c11';
        break;
        case 'hold':
          statusColor = '#800080';
          break;
          case 'Booked':
            statusColor = '#ADD8E6';
            break;
      default:
        statusColor = '#606060';
    }
    let borderRadius;
    switch (event.set) {
      case 'On Model':
        borderRadius = '10px';
        break;
      case 'on Produkt':
        borderRadius = '0px';
        break;
    }
    const groupBadge = event.groupColor ? (
      <div style={{
        display: 'inline-block',
        backgroundColor: event.groupColor,
        color: '#ffffff',
        padding: '3px 6px',
        borderRadius: '10px',
        fontSize: '0.8em',
        margin: '0 4px',
        verticalAlign: 'middle'
      }}>
        {event.group}
      </div>
    ) : null;
    
    return (
      <div style={{ border: '1px solid black',margin:'3px',   padding: '10px', position: 'relative', color: '#000000', borderTopRightRadius: '10px',   minHeight:'110px'     , cursor: 'auto', borderRadius:borderRadius }}
        onClick={() => this.handleEventMouseEnter(event.id)}
        onMouseLeave={() => this.handleEventMouseLeave(event.id)}
      >
        {event.showDetail && (
          <div className='toltip'
            style={{
              position: 'absolute',
              width: "600px",
              height: "auto",
              top: "10px",
              left: "10px",
              background: '#000000',
              color: '#ffffff',
              padding: '10px',
              zIndex: 9999,
              cursor: 'auto'
            }}
          >
               

            <span style={{ position: 'relative', fontWeight: '600', color: '#fff', whiteSpace: 'pre-line' }}>
              <div class="calendar-inner-cell-container">
                <div>

                  {` ${event.client} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Talent `}
                  </div>
                  {` ${event.talent} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Status `}
                  </div>
                  {` ${event.status} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Proposed By: `}
                  </div>
                  {` ${event.proposedBy} `}
                </div>
                <div>
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                    {` Title `}
                  </div>
                  {` ${event.boktitle} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Set `}
                  </div>
                  {` ${event.set} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Kpi `}
                  </div>
                  {` ${event.kpi} `}
                </div>
                <div style={{ flexGrow: '1' }}>
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                    {` Option `}
                  </div>
                  {` ${event.option} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                    {` s.info `}
                  </div>
                  {` ${event.SonstigeInfo} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Start time `}
                  </div>
                  {` ${moment(event.start).format('DD-MM-YYYY')} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` End time `}
                  </div>
                  {` ${moment(event.end).format('DD-MM-YYYY')} `}

                </div>
              </div>
              <a href={`detail?id=${event.bookingId}`} style={{ color: 'blue', textDecoration: 'underline', float: 'right', }}>
                <img src={editWhite} alt="Edit" className="edit-container" />
              </a>
            </span>

          </div>
        )}
        <div>
          <span style={{
            height: '22px',
            width: '22px',
            backgroundColor: statusColor,
            borderRadius: '50%',
            display: 'inline-block',
            position: 'absolute',
            top: '10px',
            right: '10px'
          }} />
        </div> 
        <span style={{ position: 'relative', fontWeight: '600', color: '#000000', whiteSpace: 'pre-line' }}>

          <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.client} `}</span>

          <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.talent} `}</span>

          <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.proposedBy} `}</span>

        </span>
        
        <div style={{ position: 'absolute', right: 0, bottom: 0, paddingRight: '10px', paddingBottom: '10px' }}>
        {groupBadge}
       
        </div>

      </div>
    );
  }


  MonthEvent = ({ event }) => {
    let statusColor;
    switch (event.status) {
      case 'confirmed':
        statusColor = '#15a326';
        break;
      case 'new order':
        statusColor = '#ffffff';
        break;
        case 'requested':
        statusColor = '#FF8806';
        break;
      case 'talentaccepted':
        statusColor = '#3de651';
        break;
      case 'declined request':
        statusColor = '#ff3c00';
        break;
      case 'optioned':
        statusColor = '#0060ff';
        break;
        case 'optionSolved':
          statusColor = '#FFFF00';
          break;
      case 'lano':
        statusColor = '#116c11';
        break;
        case 'hold':
          statusColor = '#800080';
          break;
          case 'Booked':
            statusColor = '#ADD8E6';
            break;
      default:
        statusColor = '#606060';
    }
    let borderRadius;
    switch (event.set) {
      case 'On Model':
        borderRadius = '10px';
        break;
      case 'on Produkt':
        borderRadius = '0px';
        break;
    }
    const groupBadge = event.groupColor ? (
      <div style={{
        display: 'inline-block',
        backgroundColor: event.groupColor,
        color: '#ffffff',
        padding: '3px 6px',
        borderRadius: '10px',
        fontSize: '0.8em',
        margin: '0 4px',
        verticalAlign: 'middle'
      }}>        
        {event.group}
      </div>
    ) : null;
    
    return (
      <div style={{ border: '1px solid black', margin:'3px',  padding: '10px', position: 'relative', color: '#000000', borderTopRightRadius: '10px',   minHeight:'70px'     , cursor: 'auto', borderRadius:borderRadius }}
      onClick={() => this.handleEventMouseEnter(event.id)}
        onMouseLeave={() => this.handleEventMouseLeave(event.id)}
      >
        {event.showDetail && (
          <div className='toltip'
            style={{
              position: 'absolute',
              width: "600px",
              height: "auto",
              top: "10px",
              left: "10px",
              background: '#000000',
              color: '#ffffff',
              padding: '10px',
              zIndex: 9999,
              cursor: 'auto'
            }}
          >
                            


            <span style={{ position: 'relative', fontWeight: '600', color: '#fff', whiteSpace: 'pre-line' }}>
              <div class="calendar-inner-cell-container">
                <div>

                  {` ${event.client} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Talent `}
                  </div>
                  {` ${event.talent} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Status `}
                  </div>
                  {` ${event.status} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Proposed By: `}
                  </div>
                  {` ${event.proposedBy} `}
                </div>
                <div>
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                    {` Title `}
                  </div>
                  {` ${event.boktitle} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Set `}
                  </div>
                  {` ${event.set} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Kpi `}
                  </div>
                  {` ${event.kpi} `}
                </div>
                <div style={{ flexGrow: '1' }}>
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                    {` Option `}
                  </div>
                  {` ${event.option} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Start time `}
                  </div>
                  {` ${moment(event.start).format('DD-MM-YYYY')} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` End time `}
                  </div>
                  {` ${moment(event.end).format('DD-MM-YYYY')} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                    {` s.info `}
                  </div>
                  {` ${event.SonstigeInfo} `}
              
                 
                </div>
              </div>
              <a href={`detail?id=${event.bookingId}`} style={{ color: 'blue', textDecoration: 'underline', float: 'right', }}>
                <img src={editWhite} alt="Edit" className="edit-container" />
              </a>
            </span>

          </div>
        )}
        <div>
          <span style={{
            height: '22px',
            width: '22px',
            backgroundColor: statusColor,
            borderRadius: '50%',
            display: 'inline-block',
            position: 'absolute',
            top: '10px',
            right: '10px'
          }} />
        </div>
        <span style={{ position: 'relative', fontWeight: '600', color: '#000000', whiteSpace: 'pre-line' }}>
        <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.startTime} - ${event.endTime}`}</span>

          <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.client} `}</span>

          <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.talent} `}</span>

          <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.proposedBy} `}</span>

        </span>
        
        <div style={{ position: 'absolute', right: 0, bottom: 0, paddingRight: '10px', paddingBottom: '10px' }}>
        {groupBadge}
       
        </div>

      </div>
    );
  }

  AgendaEvent = ({ event }) => {
    let statusColor;
    switch (event.status) {
      case 'confirmed':
        statusColor = '#15a326';
        break;
      case 'new order':
        statusColor = '#ffffff';
        break;
        case 'requested':
        statusColor = '#FF8806';
        break;
      case 'talentaccepted':
        statusColor = '#3de651';
        break;
      case 'declined request':
        statusColor = '#ff3c00';
        break;
      case 'optioned':
        statusColor = '#0060ff';
        break;
        case 'optionSolved':
          statusColor = '#FFFF00';
          break;
      case 'lano':
        statusColor = '#116c11';
        break;
        case 'hold':
          statusColor = '#800080';
          break;
          case 'Booked':
            statusColor = '#ADD8E6';
            break;
      default:
        statusColor = '#606060';
    }

    return (
      <div style={{ border: '1px solid black', margin:'3px',  padding: '10px', position: 'relative', color: '#000000', borderTopRightRadius: '10px', minHeight: '103px', marginBottom: '10px' }}>
        <div>
          <span style={{
            height: '22px',
            width: '22px',
            backgroundColor: statusColor,
            borderRadius: '50%',
            display: 'inline-block',
            position: 'absolute',
            top: '10px',
            right: '10px'
          }} />
                       
        </div>
        <span style={{ position: 'relative', fontWeight: '600', color: '#000000', whiteSpace: 'pre-line' }}>
          <div class="calendar-inner-cell-container">
            <div>
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                {` Client `}
              </div>
              {` ${event.client} `}
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                {` Talent `}
              </div>
              {` ${event.talent} `}
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                {` Status `}
              </div>
              {` ${event.status} `}
            </div>
            <div>
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                {` Title `}
              </div>
              {` ${event.boktitle} `}
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                {` Set `}
              </div>
              {` ${event.set} `}
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                {` Kpi `}
              </div>
              {` ${event.kpi} `}
            </div>
            <div style={{ flexGrow: '1' }}>
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                {` Option `}
              </div>
              {` ${event.option} `}
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                {` Start time `}
              </div>
              {` ${moment(event.start).format('DD-MM-YYYY')} `}
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                {` End time `}
              </div>
              {` ${moment(event.end).format('DD-MM-YYYY')} `}
            </div>
          </div>
        </span>

      </div>
    );
  }

  switchView = (view) => {
    this.setState({ view });
}


  render() {
    console.log("Rendering with events:", this.state.events); // 5. Inside Rendering
    if (this.state.loading) {
      return <div class="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>;
    }
    const { userList, selectedUserEmail, loading, events } = this.state;
    const options = userList.map(user => ({
      value: user.email,
      label: user.name
    }));
    const optionsLocation = events.map(event => ({
      value: event.location,
      label: event.location,
    }));
    const bookingStatusOptions = [
      { value: 'Booked', label: 'booked / Lano task created' },
      { value: 'confirmed', label: 'confirmed' },
      { value: 'new order', label: 'new order' },
      { value: 'requested', label: 'requested' },
      { value: 'declined request', label: 'declined request' },
      { value: 'optioned', label: 'optioned' },
      { value: 'optionSolved', label: 'option released' },

      
      // ... other statuses
    ];




    return (
      <section style={{   display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'}}>
        <div class="content-wrapper" style={{  flex: 1,  overflowY: 'auto', paddingBottom: '30px' }}>
          <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '60px' }}>
            <h1>Orders & bookings</h1>
            <div>
            <a href = 'allgroups'><button className='big-button'>Groups</button></a>
            <button className={this.state.view === 'calendar' ? 'big-button' : 'big-button-active'} onClick={() => this.switchView('list')} style={{ marginLeft: 'auto' }}>List View</button>
            <button className={this.state.view === 'calendar' ? 'big-button-active' : 'big-button'} onClick={() => this.switchView('calendar')}>Calendar View</button>
            </div>        
      
          </div>

          {this.state.view === 'calendar' ? (
            <>
            <div className='input-container'>
            <div>
        <p>Filter by client</p>
        <Select
            value={this.state.selectedClient}
            onChange={this.handleClientSelection}
            options={this.state.clientOptions}
            styles={customStyles}
            isClearable
        />
    </div>
    <div>
        <p>Filter by set</p>
        <Select
            value={this.state.selectedSet}
            onChange={this.handleSetSelection}
            options={this.state.setOptions}
            styles={customStyles}
            isClearable
        />
    </div>
    <div>
  <p>Filter by Location</p>
  <Select
      value={this.state.selectedLocation}
      onChange={this.handleLocationSelection}
      options={this.state.locationOptions}
      styles={customStyles}
      isClearable
  />
</div>

    </div>
    <div className='input-container'>
              <div>
                <p style={{ marginBottom: '5px' }}>Filter by user</p>
                <Select
                  value={options.find(option => option.value === selectedUserEmail)}
                  onChange={this.handleUserSelection}
                  options={options}
                  styles={customStyles}
                  

                /></div><div>
                <p style={{ marginBottom: '5px' }}>Filter by status</p>
                <Select
                  value={bookingStatusOptions.find(option => option.value === this.state.selectedBookingStatus)}
                  onChange={this.handleBookingStatusSelection}
                  options={bookingStatusOptions}
                  styles={customStyles}
                  isClearable
              
 
                />
            </div></div>
            <Calendar
              localizer={localizer}
              events={this.state.filteredBookings} // Make sure this is correctly populated

              startAccessor="start"
             endAccessor="end"
              views={['month', 'week', 'agenda']}

              popup
              style={{   flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'visible', height:5800}} 
              view={this.state.currentView}
              onSelectEvent={event => this.handleSelectEvent(event)}
              components={{
                event: this.Event,
                week: {
                  event: this.WeekEvent
                },
                agenda: {
                  event: this.AgendaEvent
                },
                month: {
                  event: this.MonthEvent
                },
                
                toolbar: props => <CustomToolbar {...props} 
                date={this.state.date} 
                onNavigate={this.onNavigate}
                  onWeekChange={this.onWeekChange} 
                  date={this.state.currentDate}   
                  currentView={this.state.currentView}
                  onChangeView={this.changeView}
              
                  />

              }}
              onNavigate={this.onNavigate}
              date={this.state.currentDate}
            />
        </>
          ) : (
            < ListViewAllBookings events={this.state.events}  authToken={this.props.authToken} />
        )}
          </div>
        </div>
      </section>
    );
  }
}

export default AllBookings;