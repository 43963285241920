import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc, setDoc } from "firebase/firestore";
import { db, createUser } from './../../firebaseConfig';
import edit from './../../images/edit.svg';
import Select, { components } from 'react-select';

function UserDataForm() {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [talentIds, setTalentIds] = useState([]);

  const [users, setUsers] = useState([]);
  const [talents, setTalents] = useState([]);
  const [currentUserEditing, setCurrentUserEditing] = useState(null);
  const [editEmail, setEditEmail] = useState('');
  const [editRole, setEditRole] = useState('');
  const [editTalentIds, setEditTalentIds] = useState('');
  const [password, setPassword] = useState(''); // Add state to handle password
  const [confirmPassword, setConfirmPassword] = useState(''); //
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRoleFilter, setSelectedRoleFilter] = useState('');
  
  const [isEditMode, setIsEditMode] = useState(false); // new state to check edit mode
  const [editingUserId, setEditingUserId] = useState(null); // new state to store editing user's ID
  
  useEffect(() => {
    // Fetch users
    const fetchUsers = async () => {
      const usersCollection = collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData");
      const usersSnapshot = await getDocs(usersCollection);
      const usersList = usersSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setUsers(usersList);
    };



    // Fetch talents
    const fetchTalents = async () => {
      const talentsCollection = collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data");
      const talentsSnapshot = await getDocs(talentsCollection);
      const talentsList = talentsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setTalents(talentsList);
    };

    fetchUsers();
    fetchTalents();
  }, []);


  const handleEdit = (user) => {
    const currentTalentIds = isEditMode ? editTalentIds: talentIds;


    setEditEmail(user.email);
    setEditRole(user.role);
    setEditTalentIds(user.talentIds || []);
    setCurrentUserEditing(user.id); 
  };
  const handleCancelEdit = () => {
    setCurrentUserEditing(null); // Reset the user editing state
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentTalentIds =editTalentIds;

  
    const userData = {
      
      email: editEmail,
      role: editRole.value,
      talentIds: editTalentIds,
    };



    try {

        await updateDoc(doc(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData", currentUserEditing), userData);
        alert('Data updated successfully!');
        setIsEditMode(false);
        setEditingUserId(null);
       
      window.location.reload();
    
    } catch (error) {
      console.error('Error adding/updating data: ', error);
    }
  };


  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div style={{ width: '16px', margin: '0' }}>
            <svg version="1.1" id="Isolationsmodus" x="0px" y="0px" viewBox="0 0 322.9 257.78" shapeRendering="geometricPrecision">
              <path xmlns="http://www.w3.org/2000/svg" d="M208.27,7.37c-4.69-4.79-11.12-7.4-17.72-7.81c-0.4-0.03-0.8-0.05-1.22-0.05l0,0h-35.34h-70.2H31.14h-4.27  C17.33-0.46,7.87,4.51,3.34,13.12C-1.19,21.65-1.3,32.3,4.19,40.47c0.85,1.3,1.74,2.56,2.6,3.82C17.3,59.73,27.77,75.2,38.27,90.64  c12.47,18.33,24.91,36.7,37.39,55.03c3.08,4.53,6.13,9.02,9.21,13.54c3.97,5.86,9.06,10.5,16.08,12.36  c6.87,1.86,14.85,0.93,20.9-2.93c3.79-2.45,6.24-5.12,8.8-8.79c8.06-11.54,15.82-23.3,23.76-34.96  c13.14-19.33,26.25-38.67,39.39-57.96c4.68-6.87,9.32-13.73,14-20.63c4.31-6.31,8.39-12.13,8.2-20.41  C215.85,18.95,213.1,12.34,208.27,7.37z" />
            </svg>
          </div>
        </components.DropdownIndicator>
      )
    );
  };

  const handleRegister = async (e) => {
    e.preventDefault();
  
    if (password !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }
  
    try {
      // Create new user using Firebase Authentication
      const userCredential = await createUser(email, password);
      const user = userCredential.user;
      console.log("Registered User:", user);
      alert('User registered successfully!');
      const currentTalentIds = talentIds;

      // Once the user is registered successfully, add a new document to Firestore
      const userData = {
        email: isEditMode ? editEmail : email,
        role: isEditMode ? editRole : role,
        talentIds: currentTalentIds
      };
      
      const userDocRef = doc(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData", user.uid);
await setDoc(userDocRef, userData); // Here we use setDoc instead of addDoc

      alert('User registered and data added successfully!');
      window.location.reload();
  
    } catch (error) {
      console.error("Error registering user and adding data: ", error.message);
      alert(error.message);
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'black' : 'inherit',
      '&:hover': { 
        backgroundColor: state.isSelected ? 'black' : '#f7f7f7',
      }
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'black', // Your color
      fontWeight: "700"
    }),
  };
  
const userRoles =[    {label: 'talent',  value: 'talent'},
{label: 'Super Admin',  value: 'superadmin'},
{label: 'Planning Orendt',  value: 'planning'},
{label: 'Set Cast',  value: 'setCastEditor'},]

const handleSelectRole = (selectedOption) => {
  setEditRole(selectedOption);
};
const [adduser, setadduser]= useState(false)

const filteredUsers = users.filter((user) => {
  const matchesRole = selectedRoleFilter ? user.role === selectedRoleFilter.value : true;
  const matchesSearch = user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false;
  return matchesRole && matchesSearch;
});

  return (

      <section>
        <div className='content-wrapper'>
        <div style={{ justifyContent: 'space-between' , display: 'flex', marginBottom: '30px' }}>
          <h1>Users</h1>
          <button style={{marginLeft: "auto",}}  onClick={() =>{setadduser(!adduser)}} className='big-button' >Add New User</button>
          </div>
          {adduser ?
          <div>
          <form onSubmit={handleRegister} style={{ maxWidth: '500px' }}>
            <label>
              <input className='inputtext' type="email" placeholder="email" value={email} onChange={(e) => setEmail(e.target.value)} required autocomplete="off" />
            </label>
            <label>
            <Select
              //    isMulti
                  autocomplete="off"
                  placeholder="Choose role "
                  value={role.value}
                  onChange={(e) => setRole(e.value)}
                  options={userRoles}
                  styles={customStyles}
                />
             
            </label>
            <label>
              <input className='inputtext' type="password" placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)} required={!isEditMode} />
            </label>
      
              <>
                <label>
                  <input className='inputtext' type="password" placeholder="confirm password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                </label>
                <br />
              </>
         
            <div>
              <label>
              {role=='talent' ?<>
                              <div style={{ fontWeight: '500', fontSize: '1rem', lineHeight: '1.5', textTransform: 'none', marginTop: '10px', marginBottom: '5px' }}>Managed Talents</div>

                <Select
                  isMulti
                  autocomplete="off"
                  value={talentIds.map(id => ({ label: talents.find(talent => talent.id === id)?.Name, value: id }))}
                  onChange={(selectedOptions) => {
                    const selectedTalentIds = selectedOptions.map(option => option.value);
                    setTalentIds(selectedTalentIds);
                  }}
                  options={talents.map(talent => ({ label: talent.Name, value: talent.id }))}
                  styles={customStyles}
                /></>
                : <></>
              }
              </label>
            </div>

            <button style={{marginTop: '20px', marginBottom: '30px' }} className='big-button' type="submit">Submit</button>
          </form>
          </div>
          : <></>
          }
        </div>
        
        <div className='content-wrapper content-wrapper-talent-list'>

        <div style={{ display: 'flex', marginBottom: '20px' }} className='users-container'>
  <input
    type="text"
    className='inputtext'
    placeholder="Search by email..."
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    style={{ marginRight: '10px', padding: '10px', flexGrow: 1 }}
  />
  <Select
    placeholder="Filter by role"
    value={selectedRoleFilter}
    onChange={(selectedOption) => setSelectedRoleFilter(selectedOption)}
    options={userRoles}
    styles={customStyles}
    isClearable
  />
</div>


        <div style={{ marginTop: '30px' }} className='list-tasks-header'>
          
              <div style={{ flexBasis: '350px' }}>email</div>
              <div style={{ flexBasis: '300px' }}>managed talents</div>
              <div style={{ flexBasis: '150px' }}>role</div>
            </div>
          <div style={{ marginTop: '0' }} className='users-container'>
       


              {filteredUsers.map(user => (
                <div key={user.id}>

                <div className='list-tasks-item'>
                  <div style={{ flexBasis: '350px' }}>{user.email}</div>
                  <div style={{ flexBasis: '300px' }}>

                        {user.talentIds && user.talentIds.map(tid => 
                          <span style={{ display: 'block' }} key={tid}>
                            {talents.find(talent => talent.id === tid)?.Name || tid}
                          </span>
                        )}

                  </div>
                  <div style={{ flexBasis: '150px' }}>{user.role}</div>

                  <button className='edit-button' onClick={() => handleEdit(user)} style={{ marginRight: '20px' }}>
                    <img src={edit} alt="Edit" className="edit-container" />
                  </button>
                </div>

            {/* Show the edit form conditionally */}
            {currentUserEditing === user.id && (
            <div style={{ padding: '0 20px' }}>
              <form onSubmit={handleSubmit}  style={{ maxWidth: '500px' }}>

              <label>
                <div style={{ fontWeight: '500', fontSize: '1rem', lineHeight: '1.5', textTransform: 'none', marginTop: '10px', marginBottom: '5px' }}>User role</div>
                <div style={{ marginBottom: '20px' }}>
                  <Select
                    components={{ DropdownIndicator }}
                    options={userRoles}
                    onChange={handleSelectRole}
                    value={editRole}
                    styles={customStyles}
                  />
                </div>
              </label>

              <div style={{ fontWeight: '500', fontSize: '1rem', lineHeight: '1.5', textTransform: 'none', marginTop: '10px', marginBottom: '5px' }}>Managed Talents</div>
                <Select
                  isMulti
                  autocomplete="off"
                  value={editTalentIds.map(id => ({ label: talents.find(talent => talent.id === id)?.Name, value: id }))}
                  onChange={(selectedOptions) => {
                    const selectedTalentIds = selectedOptions.map(option => option.value);
                    setEditTalentIds(selectedTalentIds);
                  }}
                  options={talents.map(talent => ({ label: talent.Name, value: talent.id }))}
                  styles={customStyles}
                />

                <button style={{marginTop: '20px', marginBottom: '40px' }} className='big-button' type="submit">save</button>
                <button className='big-button' onClick={handleCancelEdit}>Cancel</button>
              </form>
               
              </div>
            )}
          </div>
        ))}
    </div>

       {/*   <div className='users-container'>
            <h2 style={{ marginLeft: '20px', marginBottom: '30px' }}>All Talents</h2>
              {talents.map(talent => (
                <div key={talent.id} style={{ display: 'flex', justifyContent: 'left',  alignItems: 'center' }}>
                  <div>
                    {talent.Name}
                  </div>
                  <div>
                    {talent.id}
                  </div>
                </div>
              ))}
          </div>
              */}

        </div>
      </section>
  );
}

export default UserDataForm;
