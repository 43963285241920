import React,{useState, useEffect, } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { BrowserRouter as Router, Switch, Route, Routes, useNavigate } from 'react-router-dom';
import moment from 'moment';
import '../../App.css';
import { collection, getDocs, doc, getDoc,updateDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { NonceProvider } from 'react-select';
import CalendarToolbar from '../CalendarToolbar';
import CalendarEventStatusColor from '../CalendarEventStatusColor';
import edit from '../../images/edit.svg'
import proposeTalent from '../../images/propose-talent.svg'
import proposeTalentWhite from '../../images/propose-talent-white.svg'
import 'moment/locale/de'  // or any locale that uses day-month-year format
import NewOrdersList from './NewOrdersList'; // adjust the path as needed

const localizer = momentLocalizer(moment);

const Event =  ({ event, handleEventMouseEnter, handleEventMouseLeave, handleSelectEvent }) => {
        
const navigate=useNavigate();

const groupBadge = event.groupColor ? (
  <div style={{
    display: 'inline-block',
    backgroundColor: event.groupColor,
    color: '#ffffff',
    padding: '3px 7px 5px 7px',
    fontSize: '0.8em',
    position: 'absolute',
    bottom: '7px',
    right: '35px'
  }}>
    {event.group}
  </div>
) : null;

  const poposeClick = async (event) => {
      
    const bookingId = event.bookingId;
    const orderRef = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings', bookingId);
    const orderSnap = await getDoc(orderRef);

    if (orderSnap.exists() && !orderSnap.data().isBeingProcessed) {
        // If the order is not being processed, update it to being processed
        await updateDoc(orderRef, { isBeingProcessed: true, processingBy: localStorage.getItem("userEmail")});
        navigate(`/admin/propose?bookingId=${event.bookingId}`);

    } else {
        alert('This order is being processed by another user.');
    }
};
let borderStyle;
switch (event.set) {
  case 'On Model':
    borderStyle = 'solid';
    break;
  case 'on Produkt':
    borderStyle = 'dashed';
    break;
}
let borderRadius;
switch (event.set) {
  case 'On Model':
    borderRadius = '10px';
    break;
  case 'on Produkt':
    borderRadius = '0px';
    break;
}

  return (
      <div className="my-div" style={{ cursor: 'auto', height:'110px', margin:'5px',borderRadius:borderRadius,  }}
      onMouseEnter={() => handleEventMouseEnter(event)}
      onMouseLeave={() => handleEventMouseLeave(event)}
      >
          {event.showDetail && (
              <div className='toltip'
                  style={{
                      position: 'absolute',
                      width: "600px",
                      height: "auto",
                      top: "10px",
                      left: "10px",
                      background: '#000000',
                      color: '#ffffff',
                      padding: '10px',
                      zIndex: 9999,
                      cursor: 'auto'
                  }}
              >

                  <span style={{ position: 'relative', fontWeight: '600', color: '#fff', whiteSpace: 'pre-line' }}>
                    <div class="calendar-inner-cell-container">
                      <div>
                        <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                          {` Client `}
                        </div>
                          {` ${event.client} `}
                        <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                          {` Talent `}
                        </div>
                          {` ${event.talent} `}
                        <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                          {` Status `}
                        </div>
                        {` ${event.status} `}
                      </div>
                      <div>
                        <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                          {` Title `}
                        </div>
                          {` ${event.boktitle} `}
                        <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                          {` Set `}
                        </div>
                          {` ${event.set} `}
                        <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                          {` Kpi `}
                        </div>
                        {` ${event.kpi} `}
                      </div>
                      <div style={{ flexGrow: '1' }}>
                        <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                          {` Option `}
                          </div>
                          {` ${event.option} `}
                          <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                          {` Start time `}
                          </div>
                          {` ${moment(event.start).format('DD-MM-YYYY')} `}
                          <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                          {` End time `}
                          </div>
                          {` ${moment(event.end).format('DD-MM-YYYY')} `}
                          <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
            {` s.info `}
            </div>
            {` ${event.SonstigeInfo} `}
                      </div>
                    </div>
                    <div>
                      <a className="my-a"  onClick={(e) => poposeClick(event)}> 
                          <img src={proposeTalentWhite} alt="Edit" className="edit-container" />
                      </a>
                  </div>
                  </span>

              </div>
          )}
 
    <div>
      <CalendarEventStatusColor status={event.status} event={event} />
    </div>
   
      <span style={{ position: 'relative', fontWeight: '600', color: '#000000', whiteSpace: 'pre-line' }}>
      <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.startTime} - ${event.endTime} `}</span>

    
          <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.client} `}</span>
     
          <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.position} `}</span>
     
        <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.option} `}</span>
      </span>
      
      <div className="my-div-absolute">
      {groupBadge}
          <a onClick={(e) => poposeClick(event)}>
              <img src={proposeTalent} alt="Edit" className="edit-container" />
          </a>
      </div>
  </div>
  );
}

const AgendaEvent = ({ event, handleEventMouseEnter, handleEventMouseLeave, handleSelectEvent }) => {
  const navigate=useNavigate();
  const poposeClick = async (event) => {
      
    const bookingId = event.bookingId;
    const orderRef = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings', bookingId);
    const orderSnap = await getDoc(orderRef);

    if (orderSnap.exists() && !orderSnap.data().isBeingProcessed) {
        // If the order is not being processed, update it to being processed
        await updateDoc(orderRef, { isBeingProcessed: true, processingBy: localStorage.getItem("userEmail")});
        navigate(`/admin/propose?bookingId=${event.bookingId}`);

    } else {
        alert('This order is being processed by another user.');
    }
};
let statusColor;
switch (event.status) {
  case 'confirmed':
    statusColor = '#15a326';
    break;
  case 'new order':
    statusColor = '#ffffff';
    break;
    case 'requested':
    statusColor = '#FF8806';
    break;
  case 'talentaccepted':
    statusColor = '#3de651';
    break;
  case 'declined request':
    statusColor = '#ff3c00';
    break;
  case 'optioned':
    statusColor = '#0060ff';
    break;
    case 'hold':
      statusColor = '#800080';
      break;
    case 'optionSolved':
      statusColor = '#FFFF00';
      break;
  case 'lano':
    statusColor = '#116c11';
    break;
    case 'Booked':
      statusColor = '#ADD8E6';
      break;
  default:
    statusColor = '#606060';
}
let borderRadius;
switch (event.set) {
  case 'On Model':
    borderRadius = '10px';
    break;
  case 'on Produkt':
    borderRadius = '0px';
    break;
}

return (
  <div style={{ border: '1px solid black',  margin:'3px', padding: '10px', position: 'relative', color: '#000000',  minHeight: '100px', marginBottom: '10px', borderRadius:borderRadius }}>
    <div>
      <span style={{
        height: '22px',
        width: '22px',
        backgroundColor: statusColor,
        borderRadius: '50%',
        display: 'inline-block',
        position: 'absolute',
        top: '10px',
        right: '10px'
      }} />
    </div>
    <span style={{ position: 'relative', fontWeight: '600', color: '#000000', whiteSpace: 'pre-line' }}>
      <div class="calendar-inner-cell-container">
        <div>
          <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
            {` Client `}
          </div>
            {` ${event.client} `}
          <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
            {` Talent `}
          </div>
            {` ${event.talent} `}
          <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
            {` Status `}
          </div>
          {` ${event.status} `}
        </div>
        <div>
          <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
            {` Title `}
          </div>
            {` ${event.boktitle} `}
          <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
            {` Set `}
          </div>
            {` ${event.set} `}
          <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
            {` Kpi `}
          </div>
          {` ${event.kpi} `}
        </div>
        <div style={{ flexGrow: '1' }}>
          <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
            {` Option `}
            </div>
            {` ${event.option} `}
            <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
            {` Start time `}
            </div>
            {` ${moment(event.start).format('DD-MM-YYYY')} `}
            <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
            {` End time `}
            </div>
            {` ${moment(event.end).format('DD-MM-YYYY')} `}
            <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
            {` s.info `}
            </div>
            {` ${event.SonstigeInfo} `}
        </div>
      </div>
    </span>

    <div style={{ position: 'absolute', right: 0, bottom: 0, paddingRight: '10px', paddingBottom: '10px' }}>
      <a  onClick={(e) => poposeClick(event)}>
      <img src={proposeTalent} alt="Edit" className="edit-container" />
      </a>
    </div>
  </div>
);
}

const NewBookingsPage = (props) => {
  const [events, setEvents] = useState([]);
  const [view, setView] = useState('list');

  const navigate = useNavigate();
  useEffect(() => {
    fetchBookings();
  }, []);
  const switchView = async (view)=> {

    setView({ view });
}
  const fetchTalent = async (talentId) => {
        const talentDoc = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data', talentId); // replace 'talents' with your actual talents collection name
        const talent = await getDoc(talentDoc);
    
        if (talent.exists) {
          return talent.data().Name; 
        } else {
          return 'no name';
        }
      }
      const fetchCompany = async (companyID) => {
        const companyDoc = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/company', companyID); // replace 'talents' with your actual talents collection name
        const company = await getDoc(companyDoc);

        if (company.exists) {
            return company.data().name; // assuming the talent document has a 'name' field
        } else {
            return 'deleted company';
        }
    }
    const fetchBookings = async () => {
        const bookingCollection = collection(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings');
        const bookingSnapshot = await getDocs(bookingCollection);
        const bookings = bookingSnapshot.docs.map(bookingDoc => {
            const bookingData = bookingDoc.data();
            return {
                id: bookingDoc.id,
                group:bookingDoc.group,
                groupColor:bookingDoc.groupColor,
                ...bookingData,
            };
        });
    
        const events = await Promise.all(bookings.map(async (booking) => {
            if(booking.status === 'new order'){ // add this line
                const results = await Promise.allSettled([
                    fetchTalent(booking.talentId),
                    fetchCompany(booking.companyID)
                ]);
                
                const talentName = results[0].status === 'fulfilled' ? results[0].value : 'New request';
                const companyName = results[1].status === 'fulfilled' ? results[1].value : 'Deleted company';
                const clientName = booking.client ? booking.client.label : 'NULL';
                const position = booking.position ? booking.position.label : 'NULL';


             
                return {
                    bookingId: booking.id,
                    group:booking.group,
                    groupColor:booking.groupColor,
                    id: booking.id,
                    boktitle: booking.title,
                    set: booking.set,
                    kpi: booking.kpi,
                    talent: talentName,
                    talentid: booking.talentId,
                    title: `Booking ${talentName} for ${companyName} (${booking.status})`,
                    start: new Date(booking.start_date.seconds * 1000),
                    end: new Date(booking.end_date.seconds * 1000),
                    allDay: true,
                    status: booking.status,
                    company: companyName,
                    showDetail: false, 
                    client: clientName,
                    position:position,
                    range:booking.range,
                    segment: booking.segment,
                    option: booking.option,
                    startTime: booking.startTime,
                    endTime:booking.endTime,
                    studio:booking.studio,
                    location:booking.location,
                    proposedBy: booking.proposedBy,
                    SonstigeInfo:booking.SonstigeInfo,
                  

                    createdAt: new Date(booking.createdAt.seconds * 1000).toLocaleString(),

                };
            }
        }));
    
        const filteredEvents = events.filter(event => event !== undefined) // filter out the undefined values
    
        setEvents(filteredEvents);// set the state with the filtered events
    };
    
    const handleSelectEvent = async (event) => {
      
      const bookingId = event.bookingId;
      const orderRef = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings', bookingId);
      const orderSnap = await getDoc(orderRef);
  
      if (orderSnap.exists() && !orderSnap.data().isBeingProcessed) {
          // If the order is not being processed, update it to being processed
          await updateDoc(orderRef, { isBeingProcessed: true, processingBy: localStorage.getItem("userEmail")});
          navigate(`propose?bookingId=${event.bookingId}`);

      } else {
          alert('This order is being processed by another user.');
      }
  };
  
  const handleEventMouseEnter = (event) => {
    const updatedEvents = events.map((currentEvent) => {
      if (currentEvent.id === event.id) {
        return { ...currentEvent, showDetail: true };
      } else {
        return { ...currentEvent, showDetail: false };
      }
    });
    setEvents(updatedEvents);
  };

  const handleEventMouseLeave = (event) => {
    const updatedEvents = events.map((currentEvent) => {
      if (currentEvent.id === event.id) {
        return { ...currentEvent, showDetail: false };
      }
      return currentEvent;
    });
    setEvents(updatedEvents);
  };


        return (
          <section>
            <div className="content-wrapper">
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '60px' }}>
                <h1>New Orders</h1>
                <div style={{ marginLeft: 'auto' }}>
                  <button style={{ marginRight: '20px'}} className={'big-button'}  onClick={() => navigate('../allgroups')} >Groups</button>
                  <button className={view === 'calendar' ? 'big-button' : 'big-button-active' } onClick={() => setView('list')} >List View</button>
                  <button className={view === 'calendar' ? 'big-button-active' : 'big-button' } onClick={() => setView('calendar')}>Calendar View</button>
                </div>
              </div>
                {view === 'calendar' ? (
                    <div>
                        
                        <Calendar
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
          
                            style={{   flex: 1,
                              display: 'flex',
                              flexDirection: 'column',
                              overflow: 'visible',}} 
                       
                   
                            components={{
                              event: (eventProps) => (
                                <Event 
                                    {...eventProps} 
                                    handleEventMouseEnter={handleEventMouseEnter}
                                    handleEventMouseLeave={handleEventMouseLeave}
                                    handleSelectEvent={handleSelectEvent}
                                />
                            ),
                                
                                toolbar: CalendarToolbar,  // use the custom toolbar
                                agenda: {
                             
                                  event: (eventProps) => (
                                    <AgendaEvent 
                                        {...eventProps} 
                                        handleEventMouseEnter={handleEventMouseEnter}
                                        handleEventMouseLeave={handleEventMouseLeave}
                                        handleSelectEvent={handleSelectEvent}
                                    /> )
                              },
                            }}
                            className="my-calendar"
                        />



                    </div>
                ) : (
                    <NewOrdersList events={events} />
                )}
            </div>
          </section>
        );
    
}
export default NewBookingsPage;
