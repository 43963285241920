import React from 'react';
import tobslogo from './../images/tobs-txt.svg';
import './../App.css';  // Ensure you create a CSS file for footer-specific styles

function Footer() {
    return (
        <div className="footer black-bg">
            <div className="footer-logo-container">
            <img style={{ height:50}} src={require('./../images/logo-setcast.png')} />
            </div>
        </div>
    );
}

export default Footer;
