import React from 'react';

function CalendarEventStatusColor({ status, event }) {
  let statusColor;
  switch (status) {
    case 'confirmed':
      statusColor = '#15a326';
      break;
    case 'new order':
      statusColor = '#ffffff';
      break;
      case 'requested':
      statusColor = '#FF8806';
      break;
    case 'talentaccepted':
      statusColor = '#3de651';
      break;
    case 'declined request':
      statusColor = '#ff3c00';
      break;
    case 'optioned':
      statusColor = '#0060ff';
      break;
    case 'hold':
      statusColor = '#800080';
      break;
      case 'optionSolved':
        statusColor = '#FFFF00';
        break;
    case 'lano':
      statusColor = '#116c11';
      break;
      case 'Booked':
        statusColor = '#ADD8E6';
        break;
    default:
      statusColor = '#606060';
  }
  let borderRadius;
  switch (event.set) {
    case 'On Model':
      borderRadius = '10px';
      break;
    case 'on Produkt':
      borderRadius = '0px';
      break;
  }

  return (
    <span style={{
      height: '22px',
      width: '22px',
      backgroundColor: statusColor,
      borderRadius: '50%',
      display: 'inline-block',
      position: 'absolute',
      top: '10px',
      right: '10px',
      border: '1px #dcdcdc solid'
    }} />
  );
}

export default CalendarEventStatusColor;
