import React, { useState, useEffect } from 'react';
import { auth, updatePassword } from './../../firebaseConfig';

function Profile() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const isValidPassword = (pass) => {
        return (
            pass.length >= 8 &&                      // Minimum 8 characters
            /[A-Z]/.test(pass) &&                    // At least one uppercase character
            /[^a-zA-Z]/.test(pass)                   // At least one non-letter character
        );
    }

    const updatePasswordFunction = () => {
        if (!isValidPassword(password)) {
            alert("Password must be at least 8 characters long, contain at least one uppercase letter and one non-letter character.");
            return;
        }
        if (password !== confirmPassword) {
            alert("Passwords do not match!");
            return;
        }
    
        const user = auth.currentUser;
    
        if (user) {
            updatePassword(user, password)
                .then(() => {
                    alert("Password updated successfully!");
                    setPassword('');
                    setConfirmPassword('');
                })
                .catch(error => {
                    console.error("Detailed error:", error);
                    alert("Error updating password: " + error.message);
                });
        } else {
            alert("No user is currently signed in!");
        }
    }
    
    return (
        <div className="content-wrapper">
            <section>
                <h1 style={{ marginBottom: '40px' }}>Profile</h1>
                <h2 style={{ marginBottom: '40px' }}>Update Password</h2>
                    <div style={{ maxWidth: '500px' }}>
                        <input  className='inputtext'
                        type="password"
                        placeholder="Enter new password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        />
                        <input  className='inputtext'
                            type="password"
                            placeholder="Confirm new password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <button style={{ marginTop: '20px' }}className='big-button' onClick={updatePasswordFunction}>Update Password</button>
                    </div>
            </section>
        </div>
    );
}

export default Profile;
