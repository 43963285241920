import React, { useState, createContext, useContext, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  getDoc,
  doc,
  Timestamp,
  addDoc
} from "firebase/firestore";
import { db } from "./../../../firebaseConfig"; // Update the path as per your project structure
import moment from "moment";
import "./../../../App.css";
import Select from "react-select";
import { customStyles, selector } from "./../../../helpers/StyleUtils";

import notesicon from "./../../../images/notes.svg";
import plus from "./../../../images/add.svg";
import shiftsAvailability from "./../../../images/availability.svg";
import BookingModal from './../BookingModal'; // Import the modal component

import Modal from "react-modal";

// Calendar Context
const CalendarContext = createContext();

const CalendarProvider = ({ children }) => {
  // Calculate the start of the week (Monday)
  const startOfWeek = moment.utc().add(2, "hours").startOf("isoWeek");

  const [dateRange, setDateRange] = useState({
    start: startOfWeek.toDate(),
    end: moment(startOfWeek).add(6, "days").toDate(), // Add 6 days to get to Sunday
  });

  const updateDateRange = (newRange) => {
    setDateRange(newRange);
  };
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const clearSelectedClient = () => setSelectedClient(null);

  return (
    <CalendarContext.Provider
      value={{
        dateRange,
        updateDateRange,
        selectedClient,
        setSelectedClient,
        clearSelectedClient,
        selectedStatus,
        setSelectedStatus,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

const useCalendar = () => useContext(CalendarContext);

const DateNavigator = () => {
  const { dateRange, updateDateRange, clearSelectedClient } = useCalendar();

  const navigate = (days) => {
    let newStart = moment(dateRange.start).add(days, "days");
    let newEnd = moment(newStart).add(6, "days");

    updateDateRange({ start: newStart.toDate(), end: newEnd.toDate() });
    clearSelectedClient();
  };

  const generateWeekGrid = () => {
    let grid = [];
    let currentDate = new Date(dateRange.start);

    for (let i = 0; i < 7; i++) {
      grid.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return grid;
  };

  const weekGrid = generateWeekGrid();

  return (
    <div
      style={{
        position: "sticky",
        top: "5px",
        padding: "20px 0",
        backgroundColor: "#ffffff",
        zIndex: "5",
      }}
    >
      <div className="tlistcal-week-grid">
        <div style={{ flexBasis: "400px" }}>
          <button className="button" onClick={() => navigate(-7)}>
            Prev
          </button>
          <button className="button" onClick={() => navigate(7)}>
            Next{" "}
          </button>
        </div>

        {weekGrid.map((day, index) => (
          <div key={index} className="tlistcal-day-column">
            {day.toDateString()}
          </div>
        ))}
      </div>
    </div>
  );
};

// Talent Component
const Talent = ({ talent }) => {
  const { dateRange } = useCalendar();
  const [showDetail, setshowDetail] = useState(false);
  const [tooltipStates, setTooltipStates] = useState({});
  const [availabilities, setAvailabilities] = useState([]);
  const [vacations, setVacations] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalNotesOpen, setIsModalNotesOpen] = useState(false);
  const [isModalBookingOpen, setIsModalBookingOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [notesForDay, setNotesForDay] = useState([]);
  const [newNote, setNewNote] = useState("");
  const [newAvailability, setNewAvailability] = useState({ startDate: '', endDate: '' });
  const [setCastTalentAvailabillity, setSetCastTalentAvailabillity] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTalentId, setSelectedTalentId] = useState('');
  const [selectedTalentPosition, setSelectedTalentPosition] = useState('');
  const [selectedTalentSet, setSelectedTalentSet] = useState('');
  const [availabilityStatus, setAvailabilityStatus] = useState('available'); // Add state for availability status
  const [selectedTalentName, setSelectedTalentName] = useState('');
  const [notes, setNotes] = useState([]); // Store notes for the week


  useEffect(() => {
    const fetchAvailabilityAndVacation = async (id) => {
      // Fetch availability
      const availabilityQuery = query(
        collection(
          db,
          "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talentAvailability"
        ),
        where("talentId", "==", id)
      );
      const availabilitySnap = await getDocs(availabilityQuery);
      const fetchedAvailabilities = availabilitySnap.docs.map((doc) => {
        const data = doc.data();
        return {
          startDate: data.startDate.toDate(),
          endDate: data.endDate.toDate(),
        };
      });
      setAvailabilities((prev) => [...prev, ...fetchedAvailabilities]);

      // Fetch vacations
      const vacationQuery = query(
        collection(
          db,
          "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talentVacations"
        ),
        where("talentId", "==", id)
      );
      const vacationSnap = await getDocs(vacationQuery);
      const fetchedVacations = vacationSnap.docs.map((doc) => {
        const data = doc.data();
        return {
          startDate: data.startDate.toDate(),
          endDate: data.endDate.toDate(),
        };
      });
      setVacations((prev) => [...prev, ...fetchedVacations]);
    };

    // Fetch for main talent
    fetchAvailabilityAndVacation(talent.id);

    if (Array.isArray(talent.relatedTalent)) {
      talent.relatedTalent.forEach((relatedTalentId) => {
        fetchAvailabilityAndVacation(relatedTalentId);
      });
    }
  }, [talent.id, talent.relatedTalent]);

  useEffect(() => {
    const fetchNotesForWeek = async () => {
      const formattedStart = moment(dateRange.start).format('YYYY-MM-DD');
      const formattedEnd = moment(dateRange.end).format('YYYY-MM-DD');
      const notesQuery = query(
        collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/SetCastNotes"),
        where("talentId", "==", talent.id),
        where("date", ">=", formattedStart),
        where("date", "<=", formattedEnd)
      );

      try {
        const notesSnap = await getDocs(notesQuery);
        const notesData = notesSnap.docs.map((doc) => ({
          date: doc.data().date,
          ...doc.data(),
        }));
        setNotes(notesData);
      } catch (error) {
        console.error("Error fetching notes: ", error);
      }
    };

    fetchNotesForWeek();
  }, [talent.id, dateRange.start, dateRange.end]);

  const isDateWithinRange = (date, ranges) => {
    return ranges.some(
      (range) => date >= range.startDate && date <= range.endDate
    );
  };

  const getDayStyle = (day) => {
    const isAvailable = isDateWithinRange(day, availabilities);
    const isOnVacation = isDateWithinRange(day, vacations);

    if (isAvailable) {
      return { backgroundColor: "#2ACEF1", opacity: 1, width: '100%' };
    } else if (isOnVacation) {
      return { backgroundColor: "#F12A5D", opacity: 1, width: '100%' };
    }
    return { backgroundColor: "white", opacity: 1, width: '100%' };
  };

  const renderNoteIcon = (day) => {
    const hasNotes = notes.some((note) => note.date === moment(day).format('YYYY-MM-DD'));

    const iconStyle = hasNotes
      ? { backgroundColor: "#F12A5D", padding: "1px" }
      : {};

    return (
      <div className="early-shifts-img-cont">
        <img
          src={notesicon}
          className="early-late-icon"
          style={iconStyle}
          onClick={() => openNotesModal(day)}
        />
      </div>
    );
  };

  const getBorderRadius = (set) => {
    switch (set) {
      case "On Model":
        return "10px";
      case "on Produkt":
        return "0px";
      default:
        return "5px"; // Default border radius
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case "confirmed":
        return "#15a326";
      case "new order":
        return "#ffffff";
      case "requested":
        return "#FF8806";
      case "talentaccepted":
        return "#3de651";
      case "declined request":
        return "#ff3c00";
      case "optioned":
        return "#0060ff";
      case "optionSolved":
        return "#FFFF00";
      case "hold":
        return "#800080";
      case "lano":
        return "#116c11";
      case "Booked":
        return "#ADD8E6";
      default:
        return "#606060";
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".toltip")) {
        setTooltipStates({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleTooltip = (bookingId) => {
    setTooltipStates((prevStates) => ({
      ...prevStates,
      [bookingId]: !prevStates[bookingId],
    }));
  };

  useEffect(() => {
    const fetchBookingsForTalent = async () => {
      const bookingsCollection = collection(
        db,
        "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"
      );

      // Fetch related talents first
      const talentDoc = await getDoc(
        doc(
          db,
          "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data",
          talent.id
        )
      );
      const talentData = talentDoc.data();
      const relatedTalents = talentData.relatedTalent
        ? talentData.relatedTalent
        : "";

      const talentIds = [talent.id, ...relatedTalents];

      const queries = talentIds.map((talentId) =>
        query(
          bookingsCollection,
          where("talentId", "==", talentId),
          where("start_date", ">=", dateRange.start)
        )
      );

      const bookingSnaps = await Promise.all(queries.map((q) => getDocs(q)));
      const allBookings = bookingSnaps.flatMap((snap) =>
        snap.docs.map((doc) => {
          const data = doc.data();
          data.start_date = data.start_date.toDate(); 
          return { id: doc.id, ...data };
        })
      );

      const filteredBookings = allBookings.filter(
        (booking) => booking.status !== "deleted"
      );

      setBookings(filteredBookings);
    };

    fetchBookingsForTalent();
  }, [talent.id, dateRange.start, dateRange.end]);

  const isEarlyShift = (booking) => {
    const [hours, minutes] = booking.startTime.split(":").map(Number);
    return hours < 12 || (hours === 12 && minutes === 0);
  };

  const generateWeekGrid = () => {
    let grid = [];
    let currentDate = new Date(dateRange.start);

    for (let i = 0; i < 7; i++) {
      grid.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return grid;
  };

  const weekGrid = generateWeekGrid();

  const openModal = (day) => {
    setSelectedDay(day);
    setNewAvailability({ startDate: moment(day).format('YYYY-MM-DD'), endDate: '' });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setNewAvailability({ startDate: '', endDate: '' });
  };

  const handleAvailabilityChange = (e) => {
    const { name, value } = e.target;
    setNewAvailability((prev) => ({ ...prev, [name]: value }));
  };

  const saveAvailability = async () => {
    const startDate = new Date(newAvailability.startDate);
    const endDate = new Date(newAvailability.endDate);

    if (startDate) {
      const newEntryData = {
        talentId: talent.id,
        startDate: Timestamp.fromDate(startDate),
        endDate: Timestamp.fromDate(startDate),
      };

      if (availabilityStatus === 'available') {
        await addDoc(
          collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talentAvailability"),
          newEntryData
        );
        setAvailabilities((prev) => [...prev, { ...newEntryData, startDate, endDate, status: availabilityStatus }]);
      } else {
        await addDoc(
          collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talentVacations"),
          newEntryData
        );
        setVacations((prev) => [...prev, { ...newEntryData, startDate, endDate, status: availabilityStatus }]);
      }

      closeModal();
    }
  };

  const openNotesModal = async (day) => {
    setSelectedDay(day);
    setIsModalNotesOpen(true);
    const formattedDay = moment(day).format('YYYY-MM-DD');
    const notesForSelectedDay = notes.filter(note => note.date === formattedDay);
    console.log('data',selectedDate)
    setNotesForDay(notesForSelectedDay);
  };

  const closeNotesModal = () => {
    setIsModalNotesOpen(false);
    setNotesForDay([]);
    setNewNote("");
  };

  const openBookingModal = (day, talentId, talentfunktion, talentName, talentSet) => {
    setSelectedDate(day);
    setSelectedTalentId(talentId);
    setSelectedTalentPosition(talentfunktion);
    setSelectedTalentName(talentName);
    setSelectedTalentSet(talentSet);
  };

  useEffect(() => {
    if (selectedDate && selectedTalentId && selectedTalentPosition && selectedTalentName &&selectedTalentSet) {
      console.log(selectedTalentSet);
      setIsModalBookingOpen(true);
    }
  }, [selectedDate, selectedTalentId, selectedTalentPosition, selectedTalentName, selectedTalentSet]);

  const closeBookingModal = () => {
    setIsModalBookingOpen(false);
    setSelectedDate('');
    setSelectedTalentId('');
    setSelectedTalentPosition('');
    setSelectedTalentName('');
    setSelectedTalentSet('');
  };

  const handleNoteChange = (e) => {
    setNewNote(e.target.value);
  };

  const saveNote = async () => {
    const formattedDay = moment(selectedDay).format('YYYY-MM-DD');
    const noteData = {
        notes: newNote,
        talentId: talent.id,
        date: formattedDay,
        time: Timestamp.fromDate(new Date()),
        user: localStorage.getItem("userEmail"),
    };
    try {
        await addDoc(
            collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/SetCastNotes"),
            noteData
        );

        // Update the notes state with the new note
        setNotes((prevNotes) => [...prevNotes, noteData]);

        // Also update notesForDay to reflect in the modal
        setNotesForDay((prevNotes) => [...prevNotes, noteData]);

        setNewNote(""); // Clear the text area after saving
    } catch (error) {
        console.error("Error saving note: ", error);
    }
};

  return (
    <div className="tlistcal-week-grid">
      <div style={{ flexBasis: "400px", fontWeight: "600", margin: "auto" }}>
        {talent.Name}{" "}
        <div style={{ fontWeight: "400", fontSize: "12px" }}>
          {talent.Funktion} 

        </div>
        <div style={{ fontWeight: "400", fontSize: "12px" }}>
 
          {talent.Bereich}
        </div>
      </div>

      {weekGrid.map((day, index) => (
        <div
          key={index}
          className="tlistcal-item tlistcal-c-column"
        >
          <div style={{ display: 'flex', justifyContent: 'start', maxHeight: '25px', height:'15px' }}>
            <div style={getDayStyle(day)}></div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
             

            
              
            </div>
          </div>

          <div className="early-shifts">
            <div style={{ minHeight: "20px" }}>
              {bookings
                .filter(
                  (booking) =>
                    new Date(booking.start_date).toDateString() ===
                      day.toDateString() && isEarlyShift(booking)
                )
                .map((filteredBooking) => (
                  <div
                    key={filteredBooking.id}
                    className="tlistcal-booking"
                    style={{
                      borderRadius: getBorderRadius(filteredBooking.set),
                    }}
                    onClick={() => toggleTooltip(filteredBooking.id)}
                  >
                    <div style={{
                      height: 15, width: "100%", fontSize: 12, overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}>
                      <div
                        style={{
                          height: "5px",
                          width: "5px",
                          padding: "7px",
                          backgroundColor: getStatus(filteredBooking.status),
                          borderRadius: "50%",
                          display: "inline-block",
                          float: "right",
                        }}
                      />
                      {filteredBooking.client.label}
                    </div>

                    {tooltipStates[filteredBooking.id] && (
                      <div
                        className="toltip"
                        style={{
                          position: "absolute",
                          width: "600px",
                          height: "auto",
                          top: "10px",
                          left: "10px",
                          background: "#000000",
                          color: "#ffffff",
                          padding: "10px",
                          zIndex: 9999,
                          cursor: "auto",
                        }}
                        onClick={() => setshowDetail(!showDetail)}
                      >
                        <span
                          style={{
                            position: "relative",
                            fontWeight: "600",
                            color: "#ffffff",
                            whiteSpace: "pre-line",
                          }}
                        >
                          <div className="calendar-inner-cell-container">
                            <div>
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginBottom: "2px",
                                }}
                              >
                                {` Client `}
                              </div>
                              {` ${filteredBooking.client.label} `}
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginTop: "5px",
                                  marginBottom: "2px",
                                }}
                              >
                                {` Talent `}
                              </div>
                              {` ${filteredBooking.kpi} `}
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginTop: "5px",
                                  marginBottom: "2px",
                                }}
                              >
                                {` Status `}
                              </div>
                              {` ${filteredBooking.status} `}
                            </div>
                            <div>
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginBottom: "2px",
                                }}
                              >
                                {` Title `}
                              </div>
                              {` ${filteredBooking.kpi} `}
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginTop: "5px",
                                  marginBottom: "2px",
                                }}
                              >
                                {` Set `}
                              </div>
                              {` ${filteredBooking.set} `}
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginTop: "5px",
                                  marginBottom: "2px",
                                }}
                              >
                                {` Kpi `}
                              </div>
                              {` ${filteredBooking.kpi} `}
                            </div>
                            <div style={{ flexGrow: "1" }}>
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginBottom: "2px",
                                }}
                              >
                                {` Option `}
                              </div>
                              {` ${filteredBooking.option} `}
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginTop: "5px",
                                  marginBottom: "2px",
                                }}
                              >
                                {` Start time `}
                              </div>
                              {` ${moment(filteredBooking.start_date).format(
                                "DD-MM-YYYY"
                              )} `}
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginBottom: "2px",
                                }}
                              >
                                {` S.Info `}
                              </div>
                              {` ${filteredBooking.SonstigeInfo} `}
                              <div
                                style={{
                                  fontWeight: "300",
                                  fontSize: ".8rem",
                                  marginBottom: "2px",
                                }}
                              >
                                {` Proposed by `}
                              </div>
                              {` ${filteredBooking.proposedBy} `}
                            </div>
                            
                          </div>
                        </span>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>

          <div className="late-shifts" style={{ minHeight: "25px" }}>
            {bookings
              .filter(
                (booking) =>
                  new Date(booking.start_date).toDateString() ===
                    day.toDateString() && !isEarlyShift(booking)
              )
              .map((filteredBooking) => (
                <div
                  key={filteredBooking.id}
                  className="tlistcal-booking"
                  style={{
                    borderRadius: getBorderRadius(filteredBooking.set),
                  }}
                  onClick={() => toggleTooltip(filteredBooking.id)}
                >
                  <div style={{ height: 20, width: "100%" }}>
                    <div
                      style={{
                        height: "7px",
                        width: "7px",
                        padding: "10px",
                        backgroundColor: getStatus(filteredBooking.status),
                        borderRadius: "50%",
                        display: "inline-block",
                        float: "right",
                      }}
                    />
                  </div>

                  {tooltipStates[filteredBooking.id] && (
                    <div
                      className="toltip"
                      style={{
                        position: "absolute",
                        width: "600px",
                        height: "auto",
                        top: "10px",
                        left: "10px",
                        background: "#000000",
                        color: "#ffffff",
                        padding: "10px",
                        zIndex: 9999,
                        cursor: "auto",
                      }}
                      onClick={() => setshowDetail(!showDetail)}
                    >
                      <span
                        style={{
                          position: "relative",
                          fontWeight: "600",
                          color: "#ffffff",
                          whiteSpace: "pre-line",
                        }}
                      >
                        <div className="calendar-inner-cell-container">
                          <div>
                            <div
                              style={{
                                fontWeight: "300",
                                fontSize: ".8rem",
                                marginBottom: "2px",
                              }}
                            >
                              {` Client `}
                            </div>
                            {` ${filteredBooking.client.label} `}
                            <div
                              style={{
                                fontWeight: "300",
                                fontSize: ".8rem",
                                marginTop: "5px",
                                marginBottom: "2px",
                              }}
                            >
                              {` Talent `}
                            </div>
                            {` ${filteredBooking.kpi} `}
                            <div
                              style={{
                                fontWeight: "300",
                                fontSize: ".8rem",
                                marginTop: "5px",
                                marginBottom: "2px",
                              }}
                            >
                              {` Status `}
                            </div>
                            {` ${filteredBooking.status} `}
                          </div>
                          <div>
                            <div
                              style={{
                                fontWeight: "300",
                                fontSize: ".8rem",
                                marginBottom: "2px",
                              }}
                            >
                              {` Title `}
                            </div>
                            {` ${filteredBooking.kpi} `}
                            <div
                              style={{
                                fontWeight: "300",
                                fontSize: ".8rem",
                                marginTop: "5px",
                                marginBottom: "2px",
                              }}
                            >
                              {` Set `}
                            </div>
                            {` ${filteredBooking.set} `}
                            <div
                              style={{
                                fontWeight: "300",
                                fontSize: ".8rem",
                                marginTop: "5px",
                                marginBottom: "2px",
                              }}
                            >
                              {` Kpi `}
                            </div>
                            {` ${filteredBooking.kpi} `}
                          </div>
                          <div style={{ flexGrow: "1" }}>
                            <div
                              style={{
                                fontWeight: "300",
                                fontSize: ".8rem",
                                marginBottom: "2px",
                              }}
                            >
                              {` Option `}
                            </div>
                            {` ${filteredBooking.option} `}
                            <div
                              style={{
                                fontWeight: "300",
                                fontSize: ".8rem",
                                marginTop: "5px",
                                marginBottom: "2px",
                              }}
                            >
                              {` Start time `}
                            </div>
                            {` ${moment(filteredBooking.start_date).format(
                              "DD-MM-YYYY"
                            )} `}
                            <div
                              style={{
                                fontWeight: "300",
                                fontSize: ".8rem",
                                marginBottom: "2px",
                              }}
                            >
                              {` S.Info `}
                            </div>
                            {` ${filteredBooking.SonstigeInfo} `}
                          </div>
                        </div>
                      </span>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      ))}
      <Modal
        isOpen={isModalNotesOpen}
        onRequestClose={closeNotesModal}
        contentLabel="Notes Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: 999,
          },
        }}
        
      >
        
        <h2>Notes for {selectedDay && selectedDay.toDateString()}</h2>
  <div>
    {notesForDay.map((note, index) => {
    // Convert Firebase timestamp to Date (assuming note.time is a Firebase timestamp)
    const noteDate = new Date(note.time.seconds * 1000);

    // Format the date as "dd.mm.yy"
    const formattedDate = noteDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });

    // Format the time as "hh.mm"
    const formattedTime = noteDate.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });

      return (
        <div key={index}>
          <strong>{note.user}:</strong> {note.notes} | {formattedDate}-{formattedTime}
        </div>
      );
    })}
  </div>
        <div className="textarea">
          <textarea
            value={newNote}
            onChange={handleNoteChange}
            placeholder="Add a note"
          />
        </div>
        <button className="button" onClick={saveNote}>Save Note</button>
        <button className="button" onClick={closeNotesModal}>Close</button>
      </Modal>
      <BookingModal
        onClose={closeBookingModal}
        selectedDate={selectedDate}
        selectedTalentId={selectedTalentId}
        selectedFunktion={selectedTalentPosition}
        selectedTalentName={selectedTalentName}
        selectedTalentSet={selectedTalentSet}
        isOpen={isModalBookingOpen}
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add Availability"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <div style={{ padding: '20px' }}>
          <h2>Add Availability for {selectedDay && selectedDay.toDateString()}</h2>
          <div style={{ display: "grid" }}>
            <input
              style={{ marginTop: '20px' }}
              type="date"
              name="startDate"
              value={newAvailability.startDate}
              onChange={handleAvailabilityChange}
            />

            <label>
              <input
                type="radio"
                name="status"
                value="available"
                checked={availabilityStatus === 'available'}
                onChange={(e) => setAvailabilityStatus(e.target.value)}
              />
              Available
            </label>
            <label>
              <input
                type="radio"
                name="status"
                value="not-available"
                checked={availabilityStatus === 'not-available'}
                onChange={(e) => setAvailabilityStatus(e.target.value)}
              />
              Not Available
            </label>
            <div style={{ display: 'flex', paddingTop: '20px' }}>
              <button className="button" onClick={saveAvailability}>Save Availability</button>
              <button className="button" onClick={closeModal}>Close</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

// Main Component
const TalentListCalendarmain = () => {
  const { dateRange, selectedClient, setSelectedClient, selectedStatus, setSelectedStatus } = useCalendar();

  const [talents, setTalents] = useState([]);

  const [selectedTalents, setSelectedTalents] = useState([]);
  const [clients, setClients] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [selectedSet, setSelectedSet] = useState("");
  const [selectedFunc, setSelectedFunc] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const bookingStatusOptions = [
    { value: 'Booked', label: 'booked / Lano task created' },
    { value: 'confirmed', label: 'confirmed' },
    { value: 'new order', label: 'new order' },
    { value: 'requested', label: 'requested' },
    { value: 'declined request', label: 'declined request' },
    { value: 'optioned', label: 'optioned' },
    { value: 'optionSolved', label: 'option released' },
    { value: 'talentOnHold', label: '1. Option' },

    
    // ... other statuses
  ];
  useEffect(() => {
    const fetchTalents = async () => {
      const talentSnap = await getDocs(
        collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data")
      );
      const talentData = talentSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      talentData.sort((a, b) =>
        a.Name.toLowerCase().localeCompare(b.Name.toLowerCase())
      );

      setTalents(talentData);
    };
    const fetchlocations = async () => {
      const locationSnap = await getDocs(
        collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/locations")
      );
      const locationData = locationSnap.docs.map((doc) => doc.data().short);
      setLocations(locationData);
    };

    const fetchClients = async () => {
      const talentSnap = await getDocs(
        collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/company")
      );
      const ClientData = talentSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClients(ClientData);
    };
    fetchClients();
    fetchTalents();
    fetchlocations();
  }, []);

  useEffect(() => {
    const fetchBookingsForTalent = async () => {
      const bookingsCollectionRef = collection(
        db,
        "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"
      );

      // Assuming dateRange.start and dateRange.end are JavaScript Date objects
      // If they're not, you'll need to convert them to Firestore Timestamps
      const startTimestamp = dateRange.start; // Convert to Firestore Timestamp if necessary
      const endTimestamp = dateRange.end; // Convert to Firestore Timestamp if necessary

      const bookingsQuery = query(
        bookingsCollectionRef,
        where("start_date", ">=", startTimestamp),
        where("start_date", "<=", endTimestamp)
      );

      try {
        const querySnapshot = await getDocs(bookingsQuery);
        const allBookings = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          // Assuming 'start_date' is stored as a Firestore Timestamp, convert it to a JavaScript Date object
          return { id: doc.id, ...data, start_date: data.start_date.toDate() };
        });

        setBookings(allBookings);
      } catch (error) {
        console.error("Error fetching bookings: ", error);
      }
    };

    fetchBookingsForTalent();
  }, [dateRange.start, dateRange.end]); // Add db to dependency array if it's a state/prop

  const handleSelectChange = (selectedOptions) => {
    setSelectedTalents(selectedOptions || []);
    setSelectedClient(null);
  };

  const handleSetChange = (selectedOptions) => {
    setSelectedSet(selectedOptions);
  };
  const handleFuncChange = (selectedOptions) => {
    setSelectedFunc(selectedOptions);
  };
  const handleLocations = (selectedOptions) => {
    setSelectedLocations(selectedOptions);
  };

  const handleClientSelectChange = (selectedOption) => {
    setSelectedClient(selectedOption);
    if (selectedOption == null) {
      setSelectedTalents([]);
    } else {
      // Filter talents based on the selected client in bookings
      const talentIdsForSelectedClient = bookings
        .filter((booking) => booking.client.label === selectedOption.label) // Ensure this matches how your data is structured
        .map((booking) => booking.talentId);

      // Now set a state or directly filter talents to pass to the rendering logic
      const filteredTalentsBasedOnClient = talents.filter((talent) =>
        talentIdsForSelectedClient.includes(talent.id)
      );
      const optionsForSelect = filteredTalentsBasedOnClient.map((talent) => ({
        value: talent.id,
        label: talent.Name, // Adjust if your talent object uses a different key for the name
      }));

      // Use this filtered list to update your UI
      setSelectedTalents(optionsForSelect);
      console.log(optionsForSelect);
    }
  };

  const handleSttatus = (selectedOption) => {
 

    if (selectedOption == null) {
      setSelectedTalents([]);
      setSelectedStatus('');
    } else {
      setSelectedStatus(selectedOption);
      // Filter talents based on the selected client in bookings
      const talentIdsForSelectedClient = bookings
        .filter((booking) => booking.status === selectedOption.value) // Ensure this matches how your data is structured
        .map((booking) => booking.talentId);

      // Now set a state or directly filter talents to pass to the rendering logic
      const filteredTalentsBasedOnClient = talents.filter((talent) =>
        talentIdsForSelectedClient.includes(talent.id)
      );
      const optionsForSelect = filteredTalentsBasedOnClient.map((talent) => ({
        value: talent.id,
        label: talent.Name, // Adjust if your talent object uses a different key for the name
      }));

      // Use this filtered list to update your UI
      setSelectedTalents(optionsForSelect);
      console.log(optionsForSelect);
    }
  };


  const selectedClientValue = selectedClient
    ? { value: selectedClient.name, label: selectedClient.value }
    : null;

  const talentOptions = talents.map((talent) => ({
    value: talent.id,
    label: talent.Name,
  }));
  const talentsetOptions = Array.from(
    new Set(talents.map((talent) => talent.Bereich))
  ).map((uniqueBereich) => ({
    value: uniqueBereich,
    label: uniqueBereich,
  }));
  const talentFuncOptions = Array.from(
    new Set(talents.map((talent) => talent.Funktion))
  ).map((uniqueBereich) => ({
    value: uniqueBereich,
    label: uniqueBereich,
  }));
  const locationOptions = locations
    .map((location) => ({
      value: location,
      label: location,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  // Filter the talents to display based on selection
  const filteredTalentss =
    selectedTalents.length > 0
      ? talents.filter((talent) =>
          selectedTalents.some((selected) => selected.value === talent.id)
        )
      : talents; // Show all talents if none is selected

  // Filter talents based on selected talents and bookings with selected clients
  const filteredTalents = talents.filter((talent) => {
    const isSelectedTalent =
      selectedTalents.length === 0 ||
      selectedTalents.some((selected) => selected.value === talent.id);

    // Find bookings for the current talent
    const talentBookings = bookings.filter(
      (booking) => booking.talentId === talent.id
    );

    if (!selectedClient) {
      return isSelectedTalent;
    }

    // Check if any of the talent's bookings match the selected client
    const hasBookingWithSelectedClient = bookings.filter(
      (booking) => selectedClient.value === booking.client.value // Assuming booking.client is an object with a label property
    );

     // Check if any of the talent's bookings match the selected status
     const hasbookingwithselectedStatus = bookings.filter(
      (booking) => selectedStatus.value === booking.status // Assuming booking.client is an object with a label property
    );


    return isSelectedTalent || hasBookingWithSelectedClient ||hasbookingwithselectedStatus ;
  });

   
  

  return (
    <div className="content-wrapper" style={{ marginTop: "80px" }}>
      <div className="input-container" style={{display:'inline'}}>
      <div style={{display:'flex'}}>
        <Select
          isMulti
          options={talentOptions}
          className="multi-select"
          classNamePrefix="select"
          value={selectedTalents}
          onChange={handleSelectChange}
          placeholder="Select Talents"
          isClearable
          styles={selector}
        />

        <Select
          isClearable
          value={selectedClientValue} // Control the value to reflect the current state
          options={clients.map((client) => ({
            value: client.name,
            label: client.name,
          }))}
          className="multi-select"
          classNamePrefix="select"
          onChange={handleClientSelectChange}
          placeholder="Select Client"
          styles={selector}
        />
                <Select
          isClearable
          value={selectedStatus} // Control the value to reflect the current state
          options={bookingStatusOptions}
          className="multi-select"
          classNamePrefix="select"
          onChange={handleSttatus}
          placeholder="Select status"
          styles={selector}
        />
      
       </div>
       <div style={{display:'flex'}}>
        <Select
          isClearable
          isMulti
          value={selectedFunc} // Control the value to reflect the current state
          options={talentFuncOptions}
          className="multi-select"
          classNamePrefix="select"
          onChange={handleFuncChange}
          placeholder="Select Function"
          styles={selector}
        />
        <Select
          isClearable
          value={selectedLocations} // Control the value to reflect the current state
          isMulti
          options={locationOptions}
          className="multi-select"
          classNamePrefix="select"
          onChange={handleLocations}
          placeholder="Select locations"
          styles={selector}
        />
          <Select
          isClearable
          value={selectedSet} // Control the value to reflect the current state
          options={talentsetOptions}
          className="multi-select"
          classNamePrefix="select"
          onChange={handleSetChange}
          placeholder="Select set"
          styles={selector}
        />
        </div>
      </div>
      <DateNavigator />
      <div style={{ marginTop: "30px" }}>
        {filteredTalentss
          .filter(
            (talent) => !selectedSet || talent.Bereich === selectedSet.value
          )
       
          .filter(
            (talent) =>
              selectedFunc &&
              Array.isArray(selectedFunc) &&
              selectedFunc.length > 0
                ? selectedFunc.some(
                    (func) => func.label === talent.Funktion
                  )
                : true
          )

          .filter((talent) =>
            selectedLocations &&
            Array.isArray(selectedLocations) &&
            selectedLocations.length > 0
              ? Array.isArray(talent.locations) &&
                talent.locations.length > 0 &&
                talent.locations.some((talentLocation) =>
                  selectedLocations.some(
                    (selectedLocation) =>
                      selectedLocation.label === talentLocation.label
                  )
                )
              : true
          )

          .map((talent) => (
            <Talent key={talent.id} talent={talent} />
          ))}
      </div>
    </div>
  );
};

const TalentOverview = () => {
  return (
    <CalendarProvider>
      <TalentListCalendarmain />
    </CalendarProvider>
  );
};
export default TalentOverview;
