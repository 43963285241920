import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import SuperAdminNavbar from "./NavBars/SuperAdminNavbar";
import RoleContext from './../helpers/RoleContext'; // import the context
import ClinetServiceNavbar from "./NavBars/ClinetServiceNavbar";
import SetCastNavBar from './NavBars/SetCastNavBar';
import Navbar from "./NavBars/Navbar";
import TalnetNavBar from './NavBars/TalnetNavBar';

const AppNavbar = () => {
    const userRole = localStorage.getItem("userRole");
  
    switch (userRole) {
      case 'superadmin':
        return <SuperAdminNavbar />;
      case 'clientService':
        return <ClinetServiceNavbar />;
      case 'setCastEditor':
        return <SetCastNavBar />;
      case 'planning':
        return <ClinetServiceNavbar />;
      case 'talent': 
        return <TalnetNavBar />;
      default:
        return <Navbar />;
    }
  };
  export default AppNavbar;