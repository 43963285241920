// UploadHistory.js

import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from "firebase/firestore";

function UploadHistory() {
    const [uploadHistory, setUploadHistory] = useState([]);

    useEffect(() => {
        const fetchUploadHistory = async () => {
            const firestore = getFirestore();
            const uploadCollection = collection(firestore, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/uploads");
            const querySnapshot = await getDocs(uploadCollection);
            const historyData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
            .sort((a, b) => b.date.toDate() - a.date.toDate()); // Sort by date in descending order

            setUploadHistory(historyData);
        };

        fetchUploadHistory();
    }, []);

    return (
        <section>
            <div className='content-wrapper'>
                <h1>Upload History</h1>
            </div>
            <div className='content-wrapper content-wrapper-talent-list'>
                <div style={{ marginTop: '50px' }} className='list-tasks-header'>
                    <div style={{ flexBasis: '300px' }} >Date</div>
                    <div style={{ flexBasis: '350px' }} >User Email</div>
                    <div style={{ flexBasis: '300px' }} ></div>
                </div>
                <div style={{ marginTop: '0' }} className='users-container'>
                    {uploadHistory.map((upload, index) => (
                    <div key={index}>
                        <div className='list-tasks-item'> 
                            <div style={{ flexBasis: '300px' }} >{upload.date.toDate().toLocaleString()}</div>
                            <div style={{ flexBasis: '350px' }} >{upload.userEmil}</div>
                            <div style={{ marginLeft: 'auto' }} > <a href={upload.fileURL} target="_blank" rel="noopener noreferrer"><button className='button'>View File</button></a></div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default UploadHistory;
