import React, { useState, useEffect } from "react";

import {
  query,
  where,
  onSnapshot,
  collection,
  doc,
  updateDoc,
  Timestamp,
  setDoc,
  getDoc,
} from "firebase/firestore";

import { db } from "./../../firebaseConfig"; // path to your firebase configuration
import DatePicker from "react-datepicker";
import "./../../App.css";
import Select, { components } from "react-select";
import { customStyles } from "./../../helpers/StyleUtils";
import "react-datepicker/dist/react-datepicker.css";

function AllFutureBookingGroups() {
  const [bookings, setBookings] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedTalent, setSelectedTalent] = useState(null);
  const [selectedSet, setSelectedSet] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({
    label: "new order",
    value: "new order",
  });
  const [selectedstartDate, setSelectedstartDate] = useState(null);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const colors = [
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    " #ff6600",
    "#0044cc",
    "#ff9933",
    "#339933",
    "#cc0099",
    "#66cc00",
    "#ff3366",
    "#0099cc",
    "#cc3300",
    "#669900",
    "#ffcc00",
    "#0033cc",
    "#cc6600",
    "#66cc33",
    // Add more colors as needed to reach the desired variety
  ];
  const [lastColorIndex, setLastColorIndex] = useState(0);
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div style={{ width: "16px", margin: "0" }}>
            <svg
              version="1.1"
              id="Isolationsmodus"
              x="0px"
              y="0px"
              viewBox="0 0 322.9 257.78"
              shapeRendering="geometricPrecision"
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M208.27,7.37c-4.69-4.79-11.12-7.4-17.72-7.81c-0.4-0.03-0.8-0.05-1.22-0.05l0,0h-35.34h-70.2H31.14h-4.27  C17.33-0.46,7.87,4.51,3.34,13.12C-1.19,21.65-1.3,32.3,4.19,40.47c0.85,1.3,1.74,2.56,2.6,3.82C17.3,59.73,27.77,75.2,38.27,90.64  c12.47,18.33,24.91,36.7,37.39,55.03c3.08,4.53,6.13,9.02,9.21,13.54c3.97,5.86,9.06,10.5,16.08,12.36  c6.87,1.86,14.85,0.93,20.9-2.93c3.79-2.45,6.24-5.12,8.8-8.79c8.06-11.54,15.82-23.3,23.76-34.96  c13.14-19.33,26.25-38.67,39.39-57.96c4.68-6.87,9.32-13.73,14-20.63c4.31-6.31,8.39-12.13,8.2-20.41  C215.85,18.95,213.1,12.34,208.27,7.37z"
              />
            </svg>
          </div>
        </components.DropdownIndicator>
      )
    );
  };
  useEffect(() => {
    const today = new Date();
    var yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
    const statusOptions = ["new order", "optioned", "requested", "confirmed"];
    const day = Timestamp.fromDate(yesterday);
    const bookingsRef = collection(
      db,
      "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"
    );
    const q = query(bookingsRef, where("status", "in", statusOptions));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const bookingsArray = querySnapshot.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        .sort((a, b) => {
          if (a.group < b.group) return -1;
          if (a.group > b.group) return 1;
          // If the groups are the same, sort by start_date (you can change this to any other criteria)
          if (a.start_date < b.start_date) return -1;
          if (a.start_date > b.start_date) return 1;
          return 0;
        });
      setBookings(bookingsArray);
    });

    return () => unsubscribe();
  }, []);
  const checkForGroupingErrors = (selectedBookingsArray) => {
    let lastClient = null;
    let lastEndTime = new Date(0); // Initialize with a date in the far past

    // Sort bookings by start time
    const sortedBookings = selectedBookingsArray
      .map((bookingId) => bookings.find((b) => b.id === bookingId))
      .sort((a, b) => a.start_date.seconds - b.start_date.seconds);

    for (const booking of sortedBookings) {
      // Check if all selected bookings have the same client
      /* if (lastClient && booking.client.label !== lastClient) {
            return `Error: Not all selected bookings have the same client.`;
          } */
      lastClient = booking.client.label;

      // Check for overlapping times
      const startTime = new Date(booking.start_date.seconds * 1000);
      const endTime = new Date(booking.end_date.seconds * 1000); // Assuming there is an end_date similar to start_date

      /*  if (startTime < lastEndTime) {
            return `Error: There are overlapping times in the selected bookings.`;
          }*/

      lastEndTime = endTime;
    }

    // If we reach here, there are no errors
    return null;
  };

  const toggleBookingSelection = (booking) => {
    if (selectedBookings.includes(booking.id)) {
      setSelectedBookings((prev) => prev.filter((id) => id !== booking.id));
    } else {
      setSelectedBookings((prev) => [...prev, booking.id]);
    }
  };

  const getCurrentCounterFromDatabase = async () => {
    const counterRef = doc(
      db,
      "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Counters",
      "GroupCounter"
    );
    const counterDoc = await getDoc(counterRef);

    if (counterDoc.exists()) {
      const data = counterDoc.data();
      // Check if the year in the document is the current year
      if (data.year === new Date().getFullYear()) {
        return data.current;
      }
    }
    // If the document doesn't exist or the year is not the current year,
    // start with 0 since it will be incremented before use.
    return 0;
  };

  const updateCounterInDatabase = async (newCounter) => {
    const counterRef = doc(
      db,
      "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Counters",
      "GroupCounter"
    );
    await setDoc(
      counterRef,
      {
        current: newCounter,
        year: new Date().getFullYear(),
      },
      { merge: true }
    );
  };

  const groupBookings = async () => {
    const error = checkForGroupingErrors(selectedBookings);
    if (error) {
      alert(error); // Display the error message to the user
      return; // Exit the function to prevent grouping
    }
    const currentCounter = await getCurrentCounterFromDatabase();
    let newCounter = currentCounter + 1;
    // Check if the counter has reached its maximum value for the year
    if (newCounter > 9999) {
      newCounter = 1; // Reset for the new year
    }

    // Update the counter in the database
    await updateCounterInDatabase(newCounter);
    const groupId = `${new Date()
      .getFullYear()
      .toString()
      .slice(-2)}${newCounter.toString().padStart(4, "0")}`;
    const groupColor = colors[newCounter % colors.length];

    for (let bookingId of selectedBookings) {
      const bookingRef = doc(
        db,
        "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings",
        bookingId
      );
      await updateDoc(bookingRef, { group: groupId, groupColor: groupColor });
      setSelectedBookings([]);
    }
    // Update lastColorIndex to use the next color next time
    setLastColorIndex((lastColorIndex + 1) % colors.length);
  };

  const separateBooking = async (bookingId) => {
    const bookingRef = doc(
      db,
      "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings",
      bookingId
    );
    await updateDoc(bookingRef, { group: null, groupColor: null });
  };

  const separateGroup = async (groupId) => {
    const bookingsToSeparate = bookings.filter(
      (booking) => booking.group === groupId
    );
    for (let booking of bookingsToSeparate) {
      const bookingRef = doc(
        db,
        "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings",
        booking.id
      );
      await updateDoc(bookingRef, { group: null, groupColor: null });
    }
  };
  const clientOptions = [
    ...new Set(bookings.map((booking) => booking.client.label)),
  ].map((client) => ({ value: client, label: client }));
  const positionOptions = [
    ...new Set(bookings.map((booking) => booking.position.label)),
  ].map((position) => ({ value: position, label: position }));
  const talentOption = [
    ...new Set(bookings.map((booking) => booking.talent)),
  ].map((talent) => ({ value: talent, label: talent }));
  const uniqueFormattedDates = new Set(
    bookings.map((booking) => {
      const date = booking.start_date.toDate(); // Convert the Firebase Timestamp to Date
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`; // Format the date and return it as a string
    })
  );

  const startDate = Array.from(uniqueFormattedDates).map((formattedDate) => ({
    value: formattedDate,
    label: formattedDate,
  }));

  const DatesOptin = [
    ...new Set(bookings.map((booking) => booking.start_date)),
  ].map((start_date) => ({ value: start_date, label: start_date }));
  const setOption = [...new Set(bookings.map((booking) => booking.set))].map(
    (set) => ({ value: set, label: set })
  );
  const locationOptions = [
    ...new Set(bookings.map((booking) => booking.location)),
  ].map((set) => ({ value: set, label: set }));
  const statusOption = [
    ...new Set(bookings.map((booking) => booking.status)),
  ].map((status) => ({ value: status, label: status }));

  const filteredEvents = bookings.filter((event) => {
    const matchesClient =
      !selectedClient || event.client.value === selectedClient.value;
    const matchesPosition =
      !selectedPosition || event.position.label === selectedPosition.label;
    const selectedDateValue = selectedstartDate
      ? selectedstartDate.value
      : null;

    const matchesStartAt = bookings.filter((booking) => {
      // Convert booking.start_date (Firebase Timestamp) to Date and then to "dd/mm/yyyy" format
      const date = booking.start_date.toDate();
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;

      // Compare the formatted date with the selected date's value
      return !selectedDateValue || formattedDate === selectedDateValue;
    });

    const matchesSet = !selectedSet || event.set === selectedSet.value;
    const matchesStatus =
      !selectedStatus || event.status === selectedStatus.value;
    const matchesLocation =
      !selectedLocation || event.location === selectedLocation.value;

    return (
      matchesClient &&
      matchesPosition &&
      matchesSet &&
      matchesPosition &&
      matchesLocation &&
      matchesStartAt &&
      matchesStatus
    );
  });
  return (
    <section>
      <div className="content-wrapper">
        <h1>Orders to Group</h1>
        <div className="future-booking">
          <div
            style={{ marginTop: "0px", marginBottom: "60px", display: "flex" }}
          >
            <div style={{ marginRight: "10px" }}>
              <Select
                value={selectedClient}
                onChange={setSelectedClient}
                options={clientOptions}
                isClearable
                placeholder="Filter by Client"
                components={{ DropdownIndicator }}
                styles={customStyles}
              />
            </div>

            <Select
              value={selectedPosition}
              onChange={setSelectedPosition}
              options={positionOptions}
              isClearable
              placeholder="Filter by Function"
              components={{ DropdownIndicator }}
              styles={customStyles}
            />

            <Select
              value={selectedSet}
              onChange={setSelectedSet}
              options={setOption}
              isClearable
              placeholder="Filter by Set"
              components={{ DropdownIndicator }}
              styles={customStyles}
            />
            {/*<Select
                        value={selectedstartDate}
                        onChange={setSelectedstartDate}
                        options={startDate}
                        isClearable
                        placeholder="Filter by Date"
                        components={{ DropdownIndicator }}
                        styles={customStyles}
                    /> */}

            <Select
              value={selectedLocation}
              onChange={setSelectedLocation}
              options={locationOptions}
              isClearable
              placeholder="Filter by Location"
              components={{ DropdownIndicator }}
              styles={customStyles}
            />
            <Select
              value={selectedStatus}
              onChange={setSelectedStatus}
              options={statusOption}
              isClearable
              placeholder="Filter by Status"
              components={{ DropdownIndicator }}
              styles={customStyles}
            />
          </div>
          {filteredEvents.map((booking, index) => {
            const isGrouped = booking.group;
            const groupSize = bookings.filter(
              (b) => b.group === booking.group
            ).length;

            const isFirstInGroup =
              isGrouped &&
              (index === 0 || bookings[index - 1].group !== booking.group);
            const isLastInGroup =
              isGrouped &&
              (index === bookings.length - 1 ||
                bookings[index + 1].group !== booking.group);

            return (
              <div className="content-wrapper content-wrapper-talent-list">
                <div
                  className="list-tasks-item grouplist"
                  style={{ maxHeight: "120px", padding: "0px", margin: "0px" }}
                >
                  <div
                    key={booking.id}
                    className={[
                      isGrouped ? "groupedBooking" : "singleBooking",
                      isFirstInGroup ? "firstInGroup" : "",
                      isLastInGroup ? "lastInGroup" : "",
                    ].join(" ")}
                    style={isGrouped ? { borderColor: booking.groupColor } : {}}
                  > 
                    <div
                      className="custom-checkbox"
                      style={{ marginLeft: "22px" }}
                    >
                      <div
                        className="list-tasks-item"
                        style={{
                          maxHeight: "110px",
                          padding: "0px",
                          margin: "0px",
                          overflow: "visible",
                        }}
                      >
                        <input
                          type="checkbox"
                          id={`checkbox-${index}`}
                          checked={selectedBookings.includes(booking.id)}
                          onChange={() => toggleBookingSelection(booking)}
                        />
                        <label htmlFor={`checkbox-${index}`}></label>
                        <div
                          style={{
                            flexBasis: "300px",
                            minWidth: "300px",
                            padding: "0px",
                          }}
                        >
                          {" "}
                          {booking.client.label}
                        </div>
                        <div
                          style={{
                            flexBasis: "200px",
                            minWidth: "250px",
                            padding: "0px",
                          }}
                        >
                          {" "}
                          {new Date(
                            booking.start_date.seconds * 1000
                          ).toLocaleDateString()}{" "}
                          {booking.startTime} - {booking.endTime}
                        </div>
                        <div
                          style={{
                            flexBasis: "150px",
                            minWidth: "150px",
                            padding: "0px",
                          }}
                        >
                          {booking.position.label}
                        </div>
                        <div
                          style={{
                            flexBasis: "150px",
                            minWidth: "100px",
                            padding: "0px",
                          }}
                        >
                          {booking.studio} {booking.location}
                        </div>
                        <div
                          style={{
                            flexBasis: "200px",
                            minWidth: "100px",
                            padding: "0px",
                          }}
                        >
                          {booking.status}
                        </div>
                        <div
                          style={{
                            flexBasis: "150px",
                            minWidth: "150px",
                            padding: "0px",
                          }}
                        >
                          {isGrouped &&
                            (groupSize > 2 ? (
                              <button
                                className="button"
                                style={{ margin: "0px" }}
                                onClick={() => separateBooking(booking.id)}
                              >
                                Separate
                              </button>
                            ) : (
                              isFirstInGroup && (
                                <button
                                  className="button"
                                  style={{ margin: "0px" }}
                                  onClick={() => separateGroup(booking.group)}
                                >
                                  Separate
                                </button>
                              )
                            ))}{" "}
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <button
          style={{ marginTop: "30px" }}
          className="big-button"
          onClick={groupBookings}
        >
          Create/Edit Group
        </button>
      </div>
    </section>
  );
}

export default AllFutureBookingGroups;
