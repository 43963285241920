import React, { useState, useEffect } from 'react';
import '../../../App.css';

import { collection, getDocs, query, where, updateDoc, doc, getDoc, addDoc, Timestamp } from "firebase/firestore";
import { db } from './../../../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { ta } from 'date-fns/locale';

const TalentOrdersList=({ authToken })=> {
    const navigate=useNavigate();
  const [talentIds, setTalentIds] = useState([]);
  const [talentData, setTalentData] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [groupedBookings, setGroupedBookings] = useState({});
  const today = new Date();
  var yesterday = new Date((new Date()).valueOf() - 1000*60*60*24);
  const todayAMonthAgo = new Date(today.setMonth(today.getMonth() - 1));

  const day = Timestamp.fromDate(todayAMonthAgo)
useEffect(() => {
    const fetchUserTalents = async () => {
      const userDoc = await getDoc(doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData", localStorage.getItem("userId")));
      const userRole = userDoc.data().role; // assuming role is stored as "role" in UserData
      const fetchedTalentIds = userRole === 'superadmin' ? [] : userDoc.data().talentIds; // if superadmin, set to empty array
      setTalentIds(fetchedTalentIds);

      const talentDocs = await getDocs(collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data"));
      let talents = talentDocs.docs.map(doc => ({ id: doc.id, name: doc.data().Name, email: doc.data().E_Mail, rate: doc.data().Tagessatz, lanoid: doc.data().Lano_ID, Funktion: doc.data().Funktion }))
        .filter(talent => userRole === 'superadmin' ? true : fetchedTalentIds.includes(talent.id)); // if superadmin, include all talents
        talents = talents.sort((a, b) => a.name.localeCompare(b.name));
      setTalentData(talents);

      // Fetch bookings after setting talents
      fetchBookingsForTalents(userRole === 'superadmin' ? talents.map(talent => talent.id) : fetchedTalentIds);
    };


    const fetchBookingsForTalents = async (ids) => {
      const allBookings = [];

      for (const id of ids) {
        const bookingsQuery = query(
          collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/Bookings"),
          where("talentId", "==", id),
           where("start_date", ">=",day)
        );

        const bookingsSnapshot = await getDocs(bookingsQuery);
        let fetchedBookings = bookingsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

        // Fetch all company names concurrently
        const companyNames = await Promise.all(fetchedBookings.map(booking => fetchCompany(booking.companyID)));

        // Assign the company name to each booking
        fetchedBookings.forEach((booking, index) => {
          booking.companyName = companyNames[index];
        });

        allBookings.push(...fetchedBookings);
      }

      setBookings(allBookings);
      setGroupedBookings(groupBookings(allBookings));
    }

    fetchUserTalents();

  }, []);

  const groupBookings = (bookingsList) => {
    const grouped = {};

    // Group bookings by their group ID
    bookingsList.forEach((booking) => {
      if (!grouped[booking.group]) {
        grouped[booking.group] = [];
      }
      grouped[booking.group].push(booking);
    });

    // Sort bookings in each group and mark the last one
    Object.keys(grouped).forEach((groupId) => {
      const sortedBookings = grouped[groupId].sort((a, b) => a.start_date.seconds - b.start_date.seconds);
      sortedBookings[sortedBookings.length - 1].isLast = true; // Mark the last booking
    });

    return grouped;
  };


  const fetchCompany = async (companyID) => {
    if (!companyID) {
      console.error("Undefined companyID found!");
      return 'Unknown Company';
    }
    const companyDoc = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1', companyID);
    const company = await getDoc(companyDoc);

    if (company.exists) {
      return company.data().name; // assuming the talent document has a 'name' field
    } else {
      return 'deleted company';
    }
  }
return(

<div>
<section>
      <div className="content-wrapper">
        <div className='h1-container' style={{ justifyContent: 'space-between' , display: 'flex' }}>
          <h1>Projects</h1>
          <button className={'big-button'  }  onClick={() => navigate('../my-bookings-page')} >calendar</button>
        </div>
      </div>
      <div>
        {talentData.map(talent => (
        <div key={talent.id}>
          <div className="content-wrapper">
            <h2 style={{ marginTop:'70px',  marginBottom: '50px' }}>{talent.name}- {talent.Funktion}</h2>
          </div>
              <div className='content-wrapper content-wrapper-talent-list'>
                <div className='list-tasks-header'>
                  <div style={{ flexBasis: '150px' }}>Client</div>
                  <div style={{ flexBasis: '150px' }}>Status</div>
                  <div style={{ flexBasis: '150px' }}>Date</div>
                  <div style={{ flexBasis: '150px' }}>Start-End</div>
                  <div style={{ flexBasis: '150px' }}>KPI</div>
                  <div style={{ flexBasis: '150px' }}>Set</div>
                  <div style={{ flexBasis: '150px' }}>Rate</div>
               
                </div>
                  {Object.entries(groupedBookings).map(([groupId, groupedBooking]) => {
                    // Filter bookings for the current talent only
                    const bookingsForTalent = groupedBooking.filter(booking => booking.talentId === talent.id);
                    
                    return bookingsForTalent.map((filteredBooking) => (
                
                      <div key={filteredBooking.id}  className='list-tasks-item'>
                        <div className='mobile-table-heading'>Client</div>
                        <div style={{ flexBasis: '150px' }}>{filteredBooking.client.label}</div>
                        <div className='mobile-table-heading'>Status</div>
                        <div style={{ flexBasis: '150px' }}>{filteredBooking.status}</div>
                        <div className='mobile-table-heading'>Date</div>
                        <div style={{ flexBasis: '150px' }}>{new Date(filteredBooking.start_date.seconds * 1000).toLocaleDateString()}</div>
                        <div className='mobile-table-heading'>Start-End</div>
                        <div style={{ flexBasis: '150px' }}>{filteredBooking.startTime}- {filteredBooking.endTime}</div>
                        <div className='mobile-table-heading'>KPI</div>
                        <div style={{ flexBasis: '150px' }}>{filteredBooking.kpi}</div>
                        <div className='mobile-table-heading'>Set</div>
                        <div style={{ flexBasis: '150px' }}>{filteredBooking.set}</div>
                        <div className='mobile-table-heading'>Rate</div>
                        <div style={{ flexBasis: '150px' }}>{talent.rate}</div>
                     
                  </div>
                ));
              })}
            </div>
          </div>
        ))}
      </div>
  </section>
</div>



);  


}

  export default TalentOrdersList;
  