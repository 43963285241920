import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import '../App.css';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import 'react-big-calendar/lib/css/react-big-calendar.css';


import 'moment/locale/de'  // or any locale that uses day-month-year format
import { getWeek, startOfWeek } from 'date-fns'

// Month names map
const monthNames = {
  'JANUAR': 0,
  'FEBRUAR': 1,
  'MÄRZ': 2,
  'APRIL': 3,
  'MAI': 4,
  'JUNI': 5,
  'JULI': 6,
  'AUGUST': 7,
  'SEPTEMBER': 8,
  'OKTOBER': 9,
  'NOVEMBER': 10,
  'DEZEMBER': 11
};

moment.locale('de')


class CalendarToolbar extends React.Component {
    
  navigate = action => {
    this.props.onNavigate(action);
};

    render() {
      let { label, view } = this.props;
  
      // Extract the month and start day from the label.
      const month = label.split(" ")[0].toUpperCase();
      const day = label.split(" ")[1].split("–")[0];
  
      // Construct the date using the extracted day and month.
      let date = new Date();
      date.setMonth(monthNames[month]);
      date.setDate(day);
      date = startOfWeek(date);
  
      // Get the week number using date-fns
      let weekNumber = getWeek(date);
  
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '40px' }}>
          <div>
            <button className='button' onClick={() => this.navigate('TODAY')}>today</button>
            <button className='button' onClick={() => this.navigate('PREV')}>Prev</button>
            <button className='button' onClick={() => this.navigate('NEXT')}>Next</button>
          </div>
          <div className='weeknumber-wrapper'>
            {view === 'week' ? (
              <label>Week {weekNumber}: <strong>{label}</strong></label>
            ) : (
              <label><strong>{label}</strong></label>
            )}
          </div>
          <div>
 
            <button className='button' onClick={() => this.view('week')}>Week</button>
  
            <button className='button' onClick={() => this.view('agenda')}>DAY</button>
            <button className='button' onClick={() => this.view('month')}>month</button>
       

          </div>
        </div>
      );
    }

  
    view = view => {
      this.props.onView(view)
    }
  }

  export default CalendarToolbar;
