import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import '../../App.css';

import { collection, getDocs, doc, getDoc, Timestamp,query, where,  } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/de';
import { getWeek, startOfWeek, endOfWeek } from 'date-fns';
import PropTypes from 'prop-types';

// Month names map
const monthNames = {
  'JANUAR': 0,
  'FEBRUAR': 1,
  'MÄRZ': 2,
  'APRIL': 3,
  'MAI': 4,
  'JUNI': 5,
  'JULI': 6,
  'AUGUST': 7,
  'SEPTEMBER': 8,
  'OKTOBER': 9,
  'NOVEMBER': 10,
  'DEZEMBER': 11
};

moment.locale('de')



//import 'moment/locale/de'; // Import the locale you want. In this case, German (Deutschland).

//const localizer = momentLocalizer(moment.locale('de')); // Set the locale to 'de' (German).
class CustomToolbar extends React.Component {
  render() {
    let { label } = this.props;

    // Extract the month and start day from the label.
    const month = label.split(" ")[0].toUpperCase();
    const day = label.split(" ")[1].split("–")[0];

    // Construct the date using the extracted day and month.
    let date = new Date();
    date.setMonth(monthNames[month]);
    date.setDate(day);
    date = startOfWeek(date);

    // Get the week number using date-fns
    let weekNumber = getWeek(date);

    return (
      <div style={{ display: 'flex', paddingTop: '20px', paddingBottom: '20px' }}>
        <div>
          <button className='button' onClick={() => this.navigate('TODAY')}>today</button>
          <button className='button' onClick={() => this.navigate('PREV')}>Prev</button>
          <button className='button' onClick={() => this.navigate('NEXT')}>Next</button>
        </div>
        <div className='weeknumber-wrapper'>
        <label>Week {weekNumber}: <strong>{label}</strong></label>
        </div>
        <div>
          <button className='button' onClick={() => this.view('month')}>Month</button>
          <button className='button' onClick={() => this.view('week')}>Week</button>

          <button className='button' onClick={() => this.view('agenda')}>Agenda</button>
        </div>
      </div>
    );
  }

  navigate = action => {
    const { date } = this.props;  // current date from the Calendar's props
    let newDate;
  
    switch(action) {
      case 'PREV':
        newDate = moment(date).subtract(1, 'week');
        break;
      case 'NEXT':
        newDate = moment(date).add(1, 'week');
        break;
      case 'TODAY':
        newDate = moment();
        break;
      default:
        newDate = date;
    }
  
    // Notify the parent component
    this.props.onNavigate(action, newDate.toDate());
  
    // Notify about the week change
    this.props.onWeekChange(startOfWeek(newDate.toDate()), endOfWeek(newDate.toDate()));
  }
  
 

  view = view => {
    this.props.onView(view)
  }
}

const localizer = momentLocalizer(moment);

class BookingCalendar extends React.Component {
  constructor(props) {
    super(props);
  
    this.state = {
      events: [],
      loading: false,
      startOfWeek: startOfWeek(new Date()), // default to current week
      endOfWeek: endOfWeek(new Date()),
      currentDate: new Date() // This will represent the current week's start date
    };
  }
  componentDidUpdate(prevProps, prevState) {
    // Check if the week has changed
    if (prevState.startOfWeek !== this.state.startOfWeek || prevState.endOfWeek !== this.state.endOfWeek) {
      this.fetchBookings();
    }
}

  componentDidMount() {
    this.fetchBookings();
  }

  onNavigate = (action, newDate) => {
    switch(action) {
      case 'PREV':
      case 'NEXT':
      case 'TODAY':
        this.setState({
          startOfWeek: startOfWeek(newDate),
          endOfWeek: endOfWeek(newDate),
          currentDate: newDate // Update the current date when navigating
        });
        break;
      default:
        break;
    }
  }
  

  onWeekChange = (start, end) => {
    this.setState({
       startOfWeek: start,
       endOfWeek: end
    }, this.fetchBookings);  // Fetch bookings after the week is changed
 }
 

  fetchTalent = async (talentId) => {
    const talentDoc = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data', talentId); // replace 'talents' with your actual talents collection name
    const talent = await getDoc(talentDoc);

    if (talent.exists) {
      return talent.data().Name; // assuming the talent document has a 'name' field
    } else {
      return 'no name';
    }
  }

  fetchCompany = async (companyID) => {
    const companyDoc = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1', companyID); // replace 'talents' with your actual talents collection name
    const company = await getDoc(companyDoc);

    if (company.exists) {
      return company.data().name; // assuming the talent document has a 'name' field
    } else {
      return 'deleted company';
    }
  }

  


  fetchBookings = async () => {
    this.setState({ loading: true });
    console.log("Fetching bookings..."); // 1. Before Making the Query to Firebase

    const startTimestamp = Timestamp.fromDate(this.state.startOfWeek);
    const endTimestamp = Timestamp.fromDate(this.state.endOfWeek);

    const bookingCollectionRef = collection(doc(db, 'SetCast CTA GmbH', 'bIETrNIclVpe0eINr1Z1'), 'Bookings');
    
    // Query based on start_date 
    const q = query(
        bookingCollectionRef,
        where("start_date", ">=", startTimestamp)
    );

    const bookingSnapshot = await getDocs(q);

    const filteredDocs = bookingSnapshot.docs.filter(doc => {
      const data = doc.data();
      console.log("Filtering data:", data); // 3. Inside the Filter Function
  
      // Ensure end_date exists and is a Timestamp object before filtering
      return data.end_date && typeof data.end_date.toDate === "function" && data.end_date.toDate() <= endTimestamp.toDate();
  });

  const bookings = filteredDocs.map(doc => {
    const data = doc.data();
    return {
        ...data,
        id: doc.id,
    };
});
    // Now filter results client-side based on end_date
    /*const bookings = bookingSnapshot.docs.filter(doc => {
        const data = doc.data();
        console.log("Filtering data:", data); // 3. Inside the Filter Function
        return data.end_date && data.end_date.toDate() <= endTimestamp.toDate();
    }); */
    console.log("Processing bookings:", bookings);

    const events = await Promise.all(bookings.map(async (booking) => {
      const results = await Promise.allSettled([
        this.fetchTalent(booking.talentId),
        this.fetchCompany(booking.companyID)
      ]);

      const talentName = results[0].status === 'fulfilled' ? results[0].value : 'New request';
      const companyName = results[1].status === 'fulfilled' ? results[1].value : 'Deleted company';
      const start = booking.start_date ? moment(booking.start_date.toDate()) : null;
      const end = booking.end_date ? moment(booking.end_date.toDate()) : null;
      
    
      
      return {
        start: new Date(booking.start_date.seconds * 1000),
        end: new Date(booking.end_date.seconds * 1000),
        bookingId: booking.id,
        id: booking.id,
        boktitle:booking.title,
        set: booking.set,
        kpi:booking.kpi,
        talent: talentName,
        title: `Booking ${talentName} for ${companyName} (${booking.status})`,
   
        allDay: true,
      
        status: booking.status,
        company: companyName,
      };
    }));

    console.log("Final events:", events); // 4. After All Transformations

      this.setState({ events, loading: false });
  
  
}; 
  
  handleSelectEvent = (event) => {
    const bookingId = event.bookingId;
    // Do something with the bookingId...
  };

  Event = ({ event }) => {
    let statusColor;
    switch (event.status) {
      case 'confirmed':
        statusColor = '#15a326';
        break;
      case 'new order':
        statusColor = '#ffffff';
        break;
        case 'requested':
        statusColor = '#FF8806';
        break;
      case 'talentaccepted':
        statusColor = '#3de651';
        break;
      case 'declined request':
        statusColor = '#ff3c00';
        break;
      case 'optioned':
        statusColor = '#0060ff';
        break;
        case 'hold':
          statusColor = '#800080';
          break;
        case 'optionSolved':
          statusColor = '#FFFF00';
          break;
          
      case 'lano':
        statusColor = '#116c11';
        break;
        case 'Booked':
          statusColor = '#ADD8E6';
          break;
      default:
        statusColor = '#606060';
    }

    return (
      <div style={{ border: '1px solid black', margin:'3px',  backgroundColor: 'white', padding: '10px', position: 'relative', color: '#000000', height: 150, borderTopRightRadius: '10px' }}>
        <div style={{ height: 20, width: '100%', alignContent: 'end', alignItems: 'end' }}>
          <span style={{
            height: '7px',
            width: '7px',
            padding: '10px',
            backgroundColor: statusColor,
            borderRadius: '50%',
            display: 'inline-block',
            float: 'right',

            alignSelf: 'flex-end'


          }} /> </div>
        <span style={{ marginTop: '30px', paddingTop: '60px', position: 'relative', color: 'black', height: 100, whiteSpace: 'pre-line' }}>
        {`${event.boktitle},\nSet: ${event.set},\nKpi: ${event.kpi}\nTalent: ${event.talent}\nstatus: ${event.status},`}



        </span>

        <div style={{ position: 'absolute', right: 0, bottom: 0, paddingRight: '10px', paddingBottom: '10px' }}>
      
        <a href={`/admin/contact-us?bookingId=${event.bookingId}`} style={{ color: 'blue', textDecoration: 'underline', float: 'right', }}>

            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Ebene_1" x="0px" y="0px" viewBox="0 0 402.04 392.38" style={{ enableBackground: 'new 0 0 402.04 392.38', width: '20px', height: 'auto' }}>
              <path d="M388.6,83.84l-72.62-70.88c-7.43-7.14-17.34-11.13-27.65-11.13c-10.31,0-20.22,3.99-27.65,11.13L49.63,218.93  c-1.67,1.9-2.98,4.1-3.85,6.48L3.43,350.89c-1.85,5.34-2.16,11.09-0.89,16.61c1.27,5.51,4.06,10.55,8.07,14.54  c4.09,4.08,9.22,6.97,14.83,8.36c5.61,1.39,11.49,1.22,17.02-0.48l128.1-40.95c1.87-0.64,3.6-1.66,5.07-2.98h1.4l211.58-207.02  c7.47-7.22,11.7-17.17,11.7-27.56C400.3,101.01,396.08,91.07,388.6,83.84L388.6,83.84z M166,307.84l-79.1-76.3l152.77-149.1  l78.4,76.48L166,307.84z M39.12,354.04l30.45-90.82l62.65,61.07L39.12,354.04z M364.1,113.77l-20.82,20.48l-78.4-76.48l20.3-19.77v0  c0.82-0.86,1.96-1.34,3.15-1.34s2.32,0.48,3.15,1.34l72.63,71.05c0.8,0.55,1.29,1.47,1.29,2.45c0,0.98-0.48,1.89-1.29,2.45  L364.1,113.77z" />
            </svg>
          </a>
        </div>

      </div>
    );
  }


  render() {
    console.log("Rendering with events:", this.state.events); // 5. Inside Rendering
    if (this.state.loading) {
      return <div class="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>;
    }
    return (
      <div style={{ marginTop: '60px', paddingLeft: "10%", paddingRight: "10%" }}>
            <h1 className='calendarh1'>Calendar View</h1>
            <Calendar
        localizer={localizer}
        events={ this.state.events}
        
        startAccessor="start"
        endAccessor="end"
        defaultView='week'
        style={{ height: 1800 }}
        onSelectEvent={event => this.handleSelectEvent(event)}
        components={{
          event: this.Event,
          toolbar: props => <CustomToolbar {...props} date={this.state.date}     onNavigate={this.onNavigate}
          onWeekChange={this.onWeekChange}  date={this.state.currentDate}/>

        }}
        onNavigate={this.onNavigate}
        date={this.state.currentDate}
      />

   
      </div>
    );
  }
}

export default BookingCalendar;
